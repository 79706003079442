import { useHttp } from "../../../utils/http";
import { useAsync } from "../../../utils/use-async";
import { useCallback, useEffect } from "react";
import { Category } from "../../../constants/interface/common";

export const useMainCategories = () => {
  const client = useHttp();
  const { run, ...result } = useAsync<Category[]>();

  const fetchCategories = useCallback(() => client("categories/main", {}), [client]);

  useEffect(() => {
    run(fetchCategories(), {
      retry: fetchCategories,
    });
  }, [run, fetchCategories]);

  return result;
};


export const useCategories = (categoryId: string) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Category[]>();

  const fetchCategories = useCallback(() => client("categories", {data: { mainCategoryId: categoryId }}),
    [client, categoryId]);

  useEffect(() => {
    run(fetchCategories(), {
      retry: fetchCategories,
    });
  }, [run, fetchCategories, categoryId]);

  return result;
};

export const useSubCategories = (categoryId: string) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Category[]>();

  const fetchSubCategories = useCallback(
    () => client("subcategories", { data: { categoryUUID: categoryId } }),
    [client, categoryId]
  );

  useEffect(() => {
    if (categoryId) {
      run(fetchSubCategories(), {
        retry: fetchSubCategories,
      });
    }
  }, [run, fetchSubCategories, categoryId]);

  return result;
};

export const useProductGroups = (subCategoryId: string) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Category[]>();

  const fetchProductGroups = useCallback(
    () =>
      client("product_groups", { data: { subcategoryUUID: subCategoryId } }),
    [client, subCategoryId]
  );

  useEffect(() => {
    if (subCategoryId) {
      run(fetchProductGroups(), {
        retry: fetchProductGroups,
      });
    }
  }, [run, fetchProductGroups, subCategoryId]);

  return result;
};

export const useExportMarkets = () => {
  const client = useHttp();
  const { run, ...result } = useAsync<Category[]>();

  const fetchExportMarkets = useCallback(() => client("export-markets", {}), [
    client,
  ]);

  useEffect(() => {
    run(fetchExportMarkets(), {
      retry: fetchExportMarkets,
    });
  }, [run, fetchExportMarkets]);

  return result;
};


export const useProductList = (subCatId?: string, sellerName?: string) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any[]>();

  const fetchProductGroups = useCallback(
    () =>
      client("products/product-list", {data: {subCatId, sellerName}}),
    [client, subCatId, sellerName]
  );

  useEffect(() => {
    if (sellerName && subCatId) {
      run(fetchProductGroups(), {
        retry: fetchProductGroups,
      });
    }
  }, [run, fetchProductGroups, subCatId, sellerName]);

  return result;
};

export const useProductListSeller = (subCatId?: string) => {

  const client = useHttp();
  const { run, ...result } = useAsync<any[]>();

  const fetchProductGroups = useCallback(
    () =>
      client("products/product-list-seller", {data: {subCatId}}),
    [client, subCatId]
  );

  useEffect(() => {
    if (subCatId) {
      run(fetchProductGroups(), {
        retry: fetchProductGroups,
      });
    }
  }, [run, fetchProductGroups, subCatId]);

  return result;
};

export const useSubCategory = (subCatId?: string) => {

  const client = useHttp();
  const { run, ...result } = useAsync<any>();

  const fetchProductGroups = useCallback(
    () =>
          client(`subcategories/${subCatId}`, {}),
    [client, subCatId]
  );

  useEffect(() => {
    if (subCatId) {
      run(fetchProductGroups(), {
        retry: fetchProductGroups,
      });
    }
  }, [run, fetchProductGroups, subCatId]);

  return result;
};
