import moment from "moment";

export const CPCItems = [
  {
    title: "Product Information",
    name: "product",
    fields: [
      {
        name: "productName",
        label: "Product Name",
        type: "string",
        fieldProps: {
          placeholder: "Enter product name",
        },
      },
      {
        name: "productNumber",
        label: "Product Number",
        type: "string",
        fieldProps: {
          placeholder: "Enter product number",
        },
      },
      {
        name: "descriptionOfProduct",
        label: "Description of Product",
        type: "textarea",
        fieldProps: {
          placeholder: "Enter description of product",
        },
      },
      {
        name: "trackingLabelCode",
        label: "Tracking Label/Code",
        type: "string",
        fieldProps: {
          placeholder: "Enter tracking label/code",
        },
      },
    ],
    initialValues: {
      productName: "",
      descriptionOfProduct: "",
      trackingLabelCode: "",
    },
    transformGenerateParams: (value: any) => {
      return {
        name: value?.productName ?? "",
        modelNo: "",
        trackingCode: value?.trackingLabelCode ?? "",
        descOfProduct: value?.descriptionOfProduct ?? "",
        photo: [],
      };
    },
    parsedDefaultValues: (product: any, company: any) => {
      return {
        productName: product?.productName ?? "",
        descriptionOfProduct: "",
        trackingLabelCode: "",
      };
    },
  },
  {
    title: "Importer / Domestic Manufacturer",
    name: "importedManfacturer",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "string",
        fieldProps: {
          placeholder: "Enter name",
        },
      },
      {
        name: "address",
        label: "Address",
        type: "address",
        fieldProps: {
          placeholder: "Enter address",
        },
      },
      {
        name: "phoneNumber",
        label: "Phone Number",
        type: "phone",
        fieldProps: {
          group: true,
          selectProps: {
            formProps: {
              name: ["phoneNumber", "area"],
            },
          },
          inputProps: {
            formProps: {
              name: ["phoneNumber", "number"],
            },
            type: "tel",
            placeholder: "Enter phone number",
          },
        },
        parser: (value: any) => {
          if (!value) return "";
          return `${value.area} ${value.number}`;
        },
      },
    ],
    initialValues: {
      name: "",
      address: "",
      phoneNumber: {
        area: "+86",
        number: "",
      },
    },
    transformGenerateParams: (value: any) => {
      return {
        name: value?.name ?? "",
        address: value?.address ?? "",
        phone: value?.phoneNumber?.number
          ? `${value?.phoneNumber?.area} ${value?.phoneNumber?.number}`
          : "",
      };
    },
    parsedDefaultValues: (product: any, company: any) => {
      return {
        name: company?.companyName,
        address: company?.addressLine1,
        phoneNumber: {
          area: company?.contactPhone?.split(" ")[0] ?? "+86",
          number: company?.contactPhone?.split(" ")[1] ?? "",
        },
      };
    },
  },
  {
    title: "Individual Maintaining Records of Test Result",
    name: "individualMainRecord",
    fields: [
      {
        name: "contactPerson",
        label: "Contact Person",
        type: "string",
        fieldProps: {
          placeholder: "Enter contact person",
        },
      },
      {
        name: "address",
        label: "Address",
        type: "string",
        fieldProps: {
          placeholder: "Enter address",
        },
      },
      {
        name: "email",
        label: "Email",
        type: "string",
        rules: [{ type: "email" }],
        fieldProps: {
          placeholder: "Enter email",
        },
      },
      {
        name: "phoneNumber",
        label: "Phone Number",
        type: "phone",
        fieldProps: {
          group: true,
          selectProps: {
            formProps: {
              name: ["phoneNumber", "area"],
            },
          },
          inputProps: {
            formProps: {
              name: ["phoneNumber", "number"],
            },
            type: "tel",
            placeholder: "Enter phone number",
          },
        },
        parser: (value: any) => {
          if (!value) return "";
          return `${value.area} ${value.number}`;
        },
      },
    ],
    initialValues: {
      contactPerson: "",
      address: "",
      email: "",
      phoneNumber: {
        area: "+86",
        number: "",
      },
    },
    transformGenerateParams: (value: any) => {
      return {
        contactPerson: value?.contactPerson ?? "",
        email: value?.email ?? "",
        address: value?.address ?? "",
        phone: value?.phoneNumber?.number
          ? `${value?.phoneNumber?.area} ${value?.phoneNumber?.number}`
          : "",
      };
    },
  },
  {
    title: "Manufacturing Information",
    name: "manufacturingInformation",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "string",
        fieldProps: {
          placeholder: "Enter name",
        },
      },
      {
        name: "address",
        label: "Address",
        type: "string",
        fieldProps: {
          placeholder: "Enter address",
        },
      },
      {
        name: "dateOfManuFacture",
        label: "Date of Manufacture",
        type: "date",
        fieldProps: {
          format: "DD-MM-YYYY",
          placeholder: "Select date of manufacture ",
        },
        parser: (value: any) => {
          if (!value) return "";
          return moment(value).format("DD/MM/YYYY");
        },
      },
      {
        name: "placeOfManufacture",
        label: "Place of Manufacture",
        type: "string",
        fieldProps: {
          placeholder: "Enter place of manufacture",
        },
      },
    ],
    initialValues: {
      name: "",
      address: "",
      dateOfManuFacture: null,
      placeOfManufacture: "",
    },
    transformGenerateParams: (value: any) => {
      return {
        name: value?.name ?? "",
        date: value?.dateOfManuFacture
          ? moment(value?.dateOfManuFacture).format("DD/MM/YYYY")
          : "",
        place: value?.placeOfManufacture ?? "",
        address: value?.address ?? "",
      };
    },
    parsedDefaultValues: (product: any, company: any) => {
      return {
        name: company?.companyName,
        address: company?.addressLine1,
        phoneNumber: {
          area: company?.contactPhone?.split(" ")[0] ?? "+86",
          number: company?.contactPhone?.split(" ")[1] ?? "",
        },
      };
    },
  },
  {
    title: "Third Party CPSC-Accepted Laboratory Information",
    name: "thirdParty",
    fields: [
      {
        name: "labName",
        label: "Lab Name",
        type: "string",
        fieldProps: {
          placeholder: "Enter lab name",
        },
      },
      {
        name: "labAddress",
        label: "Lab Address",
        type: "string",
        fieldProps: {
          placeholder: "Enter lab address",
        },
      },
      {
        name: "labPhoneNumber",
        label: "Lab Phone Number",
        type: "phone",
        fieldProps: {
          group: true,
          selectProps: {
            formProps: {
              name: ["labPhoneNumber", "area"],
            },
          },
          inputProps: {
            formProps: {
              name: ["labPhoneNumber", "number"],
            },
            type: "tel",
            placeholder: "Enter phone number",
          },
        },
        parser: (value: any) => {
          if (!value) return "";
          return `${value.area} ${value.number}`;
        },
      },
    ],
    initialValues: {
      labName: "",
      labAddress: "",
      labPhoneNumber: {
        area: "+86",
        number: "",
      },
    },
    transformGenerateParams: (value: any) => {
      return {
        name: value?.labName ?? "",
        address: value?.labAddress ?? "",
        phone: value?.labPhoneNumber?.number
          ? `${value?.labPhoneNumber?.area} ${value?.labPhoneNumber?.number}`
          : "",
      };
    },
  },
];

export const DOCItems = [
  {
    title: "Company Information",
    name: "company",
    fields: [
      {
        name: "companyName",
        label: "Company Name",
        type: "string",
        fieldProps: {
          placeholder: "Enter company name",
        },
      },
      {
        name: "address",
        label: "Address",
        type: "string",
        fieldProps: {
          placeholder: "Enter address",
        },
      },
      {
        name: "phoneNumber",
        label: "Phone Number",
        type: "phone",
        fieldProps: {
          group: true,
          selectProps: {
            formProps: {
              name: ["phoneNumber", "area"],
            },
          },
          inputProps: {
            formProps: {
              name: ["phoneNumber", "number"],
            },
            type: "tel",
            placeholder: "Enter phone number",
          },
        },
        parser: (value: any) => {
          if (!value) return "";
          return `${value.area} ${value.number}`;
        },
      },
      {
        name: "email",
        label: "Email Address",
        type: "string",
        rules: [{ type: "email" }],
        fieldProps: {
          placeholder: "Enter email",
        },
      },
    ],
    initialValues: {
      companyName: "",
      address: "",
      email: "",
      phoneNumber: {
        area: "+86",
        number: "",
      },
    },
    transformGenerateParams: (value: any) => {
      return {
        name: value?.companyName ?? "",
        address: value?.address ?? "",
        email: value?.email ?? "",
        phone: value?.phoneNumber?.number
          ? `${value?.phoneNumber?.area} ${value?.phoneNumber?.number}`
          : "",
      };
    },
    parsedDefaultValues: (product: any, company: any) => {
      return {
        companyName: company?.companyName,
        address: company?.addressLine1,
        email: company?.contactEmail,
        phoneNumber: {
          area: company?.contactPhone?.split(" ")[0] ?? "+86",
          number: company?.contactPhone?.split(" ")[1] ?? "",
        },
      };
    },
  },
  {
    title: "Product Information",
    name: "product",
    fields: [
      {
        name: "productName",
        label: "Product Name",
        type: "string",
        fieldProps: {
          placeholder: "Enter product name",
        },
      },
      {
        name: "productNumber",
        label: "Product Number",
        type: "string",
        fieldProps: {
          placeholder: "Enter product number",
        },
      },
      {
        name: "batchNumber",
        label: "Batch Number",
        type: "string",
        fieldProps: {
          placeholder: "Enter batch number",
        },
      },
      {
        name: "serialNumber",
        label: "Serial Number",
        type: "string",
        fieldProps: {
          placeholder: "Enter serial number",
        },
      },
    ],
    initialValues: {
      productName: "",
      productNumber: "",
      batchNumber: "",
      serialNumber: "",
    },
    transformGenerateParams: (value: any) => {
      return {
        name: value?.productName ?? "",
        modelNo: value?.productNumber ?? "",
        batchNo: value?.batchNumber ?? "",
        serialNo: value?.serialNumber ?? "",
        colorImage: [],
      };
    },
    parsedDefaultValues: (product: any, company: any) => {
      return {
        productName: product?.productName ?? "",
        productNumber: product?.productNumber ?? "",
        batchNumber: "",
        serialNumber: "",
      };
    },
  },
  {
    title: "Notified Body Information",
    name: "notifiedData",
    fields: [
      {
        name: "nameOfNotifiedBody",
        label: "Name of Notified Body",
        type: "string",
        fieldProps: {
          placeholder: "Enter name of notified body",
        },
      },
      {
        name: "notifiedBodyNumber",
        label: "Notified Body Number",
        type: "string",
        fieldProps: {
          placeholder: "Enter notified body number",
        },
      },
      {
        name: "certificateNumber",
        label: "Certificate Number",
        type: "string",
        fieldProps: {
          placeholder: "Enter certificate number",
        },
      },
      {
        name: "descriptionOfIntervention",
        label: "Description of Intervention",
        fieldProps: {
          placeholder: "Enter description of intervention",
        },
      },
    ],
    initialValues: {
      nameOfNotifiedBody: "",
      notifiedBodyNumber: "",
      certificateNumber: "",
      descriptionOfIntervention: "",
    },
    transformGenerateParams: (value: any) => {
      return {
        name: value?.nameOfNotifiedBody ?? "",
        number: value?.notifiedBodyNumber ?? "",
        certificateNo: value?.certificateNumber ?? "",
        descOfIntervention: value?.descOfIntervention ?? "",
      };
    },
  },
];
