import React from "react";
import SimpleModal from "../../Molecules/modal/simple-modal";
import CusForm from "../../Molecules/form";
import {FormInstance} from "antd";
import styled from "@emotion/styled";

interface ModalFormProps {
  title: string
  okText: string
  formName: string
  children: React.ReactNode,
  form: FormInstance
  visible: boolean
  loading: boolean
  onCancel?: () => void
  onValuesChange?: (value: any) => void
  onOk?: () => void

}

export function ModalForm({title, formName, okText, children, form, visible, onCancel, onOk, loading, onValuesChange}: ModalFormProps) {
  return (
    <Modal
      confirmLoading={loading}
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      okText={okText}
      onOk={onOk}
      title={title}
    >
      <CusForm
        form={form}
        onValuesChange={onValuesChange}
        name={formName}
        preserve={false}>
        {children}
      </CusForm>
    </Modal>
  )
}

const Modal = styled(SimpleModal)`
  .ant-modal-body {
    padding: 20px 20px 0px 20px;
  }
`;
