import React from "react";
import {Button, Form, Modal, Select, Space, Typography} from "antd";
import styled from "@emotion/styled";
import FormRender from "components/Molecules/form/FormRenderer";
import { ComplianceRejectionFields } from "./config";
import { default as StyledSelect } from "components/Atoms/select";
import { get } from "lodash";
import { FormInstance } from "antd/es/form/Form";
import Tag from "../../../../../../components/Atoms/tag";
import {StaticDataContext, useRejectTypes} from "../../../../../../context/static-data-context";

type IModalRejection = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: Record<any, string>, form: FormInstance) => void;
};

const Container = styled(Space)`
  width: 100%;
  > .ant-space-item {
    width: 100%;
  }
`;

const ModalRejection: React.FC<IModalRejection> = ({
  visible,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [fieldsChanged, setFieldsChanged] = React.useState([]);
  const rejectTypesOptions = useRejectTypes(true);


  const shouldDisabled = React.useMemo(() => {
    if (!fieldsChanged.length) return true;

    const values = form.getFieldsValue();
    const rejectReason = get(values, "rejectReason");
    const remark = get(values, "remark");

    if (rejectReason && rejectReason.length > 0 && remark && remark.length > 0) {
      return false;
    }
    // if (rejectReason?.value === "additionalTest") {
    //   if (!testItems) return true;
    //
    //   const haveEmpty = (testItems || []).some(({ testItem }) => !testItem);
    //   return haveEmpty;
    // }
    return true;
  }, [fieldsChanged]);

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      title="Reject The Compliance Check"
      footer={
        <Space>
          <Button
            onClick={async () => {
              await form.validateFields();
              onSubmit(form.getFieldsValue(), form);
            }}
            disabled={shouldDisabled}
            type="primary"
          >
            Confirm
          </Button>
          <Button onClick={onCancel} type="default">
            Cancel
          </Button>
        </Space>
      }
    >
      <Container size={24} direction="vertical">
        <Typography.Text>
          Confirm to reject the documents? After you have confirmed, you cannot
          undo the action.
        </Typography.Text>
        <Form
          preserve={false}
          layout="vertical"
          form={form}
          initialValues={{}}
          onFieldsChange={(changedFields, allFields) => {
            setFieldsChanged([...changedFields]);
          }}
        >
          <Form.Item name={"rejectReason"} label={"Reject Type"} required>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="create reason tags"
              // onChange={handleChange}
              options={rejectTypesOptions as []}
            />
          </Form.Item>
          <FormRender fields={ComplianceRejectionFields}></FormRender>
        </Form>
      </Container>
    </Modal>
  );
};

export default ModalRejection;
