import { Typography } from "antd";
import React from "react";
import SignInForm from "./sign-in";
import QMASLogo from "assets/logo/qmas_logo_small_words.png";
import styled from "@emotion/styled";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  gap: 32px;
  article.ant-typography {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.005em;
    text-align: center;
  }
  form ~ span {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  form {
    .ant-divider-horizontal {
      margin-top: 24px;
      margin-bottom: 0px;
    }
    button[type="submit"] {
      background: #0c91c4;
      border-color: #0c91c4;
    }
    button.ant-btn-link {
      color: #0c91c4;
    }
  }
`;

const QMASSignInForm = ({ handleSubmit, loading }: any) => {
  const onForgotPassword = () => {
    window.open(
      process.env.REACT_APP_NAVIGATE_QMAS_FORGOT_PASSWORD,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Container>
      <img src={QMASLogo} alt="qmas-logo"></img>
      <Typography>
        Log in to use your QMAS account with Compliance Check.
      </Typography>
      <SignInForm
        handleSubmit={handleSubmit}
        onForgotPassword={onForgotPassword}
        loading={loading}
      ></SignInForm>
      <span>
        New to QMAS?{" "}
        <a
          href={process.env.REACT_APP_NAVIGATE_QMAS_SIGN_UP}
          target="_blank"
          rel="noreferrer"
        >
          Sign Up
        </a>{" "}
        QMAS
      </span>
    </Container>
  );
};

export default QMASSignInForm;
