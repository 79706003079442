import React from "react";
import { Button, Card, Image } from "antd";
import styled from "@emotion/styled";

const { Meta } = Card;

const OverviewCard = ({ icon, title, total, width, onClick }: any) => (
  <CusCard
    style={{
      width: width || "238px",
      height: "103px",
      padding: 16,
      borderRadius: "15px",
      position: "relative",
    }}
    bodyStyle={{ padding: "0px" }}
  >
    <Meta
      avatar={
        icon && (
          <Image
            preview={false}
            style={{
              margin: "16px 10px 16px 0px",
              width: "36px",
              height: "100%",
            }}
            src={icon}
          />
        )
      }
      title={title}
      description={
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>{total}</div>
      }
    />
    <div style={{ position: "absolute", bottom: 8, right: 4 }}>
      {onClick && (
        <Button type={"link"} onClick={onClick}>
          View All
        </Button>
      )}
    </div>
  </CusCard>
);

const CusCard = styled(Card)`
  width: 238px;
  height: 103px;
  padding: 16px 10px 10px 26px;
  borderradius: 15px;
  position: relative;
`;

export default OverviewCard;
