import { useAsync } from "../../utils/use-async";
import { useHttp } from "../../utils/http";
import { useCallback, useEffect } from "react";
import { Page } from "../../utils/paging";
import { cleanObject } from "../../utils";
import {
  Overview,
  Statistics,
} from "../../constants/interface/merchant-dashboard";
import {
  CreateProduct,
  Product,
} from "../../constants/interface/product/product";
import { File } from "../../components/Organisms/uploads/upload-doc";
import { StatusType } from "pages/Reviews/api";
import { useFetchList } from "utils/use-fetch-list";

export const useMerchantsProducts = (
  param?: Partial<any>,
  pageObject?: Page
) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: any[]; total: number }>();

  const fetchMerchantsProducts = useCallback(
    () =>
      client("products", {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [param, client, pageObject]
  );

  useEffect(() => {
    run(fetchMerchantsProducts(), {
      retry: fetchMerchantsProducts,
    });
  }, [param, run, fetchMerchantsProducts]);

  return result;
};

export const useMerchantsProductTasks = (
  param?: Partial<any>,
  pageObject?: Page
) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: any[]; total: number }>();

  const fetchMerchantsProductTasks = useCallback(
    () =>
      client("products/tasks", {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [param, client, pageObject]
  );

  useEffect(() => {
    run(fetchMerchantsProductTasks(), {
      retry: fetchMerchantsProductTasks,
    });
  }, [param, run, fetchMerchantsProductTasks]);

  return result;
};

export const useMerchantsProductsOverview = () => {
  const client = useHttp();
  const { run, ...result } = useAsync<Overview>();

  const fetchMerchantsProductsOverview = useCallback(
    () => client("products/overview", {}),
    [client]
  );

  useEffect(() => {
    run(fetchMerchantsProductsOverview(), {
      retry: fetchMerchantsProductsOverview,
    });
  }, [run, fetchMerchantsProductsOverview]);

  return result;
};

export const useMerchantsProductsStatistics = () => {
  const client = useHttp();
  const { run, ...result } = useAsync<Statistics>();

  const fetchMerchantsProductsStatistics = useCallback(
    () => client("products/statistics", {}),
    [client]
  );

  useEffect(() => {
    run(fetchMerchantsProductsStatistics(), {
      retry: fetchMerchantsProductsStatistics,
    });
  }, [run, fetchMerchantsProductsStatistics]);

  return result;
};

export const useAddProduct = () => {
  const { run, ...asyncResult } = useAsync<Product>();
  const client = useHttp();
  const mutate = (params: Partial<CreateProduct>) => {
    return run(
      client(`products`, {
        data: cleanObject(params || {}),
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditProduct = (id: string) => {
  const { run, ...asyncResult } = useAsync<Product>();
  const client = useHttp();
  const mutate = (params: Partial<CreateProduct>) => {
    return run(
      client(`products/${id}`, {
        data: cleanObject(params || {}),
        method: "PATCH",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useGetFileUrl = () => {
  const { run, ...asyncResult } = useAsync<File>();
  const client = useHttp();
  const mutate = (fileId: string) => {
    return run(client(`files/${fileId}`, {}));
  };
  return {
    mutate,
    ...asyncResult,
  };
};

// export const useUpdateMerchantProductEstimateCompletionDate = () => {
//   const { run, ...asyncResult } = useAsync<File>();
//   const client = useHttp();
//   const mutate = (estimatedCompletionDate: string, productId: string) => {
//     return run(
//       client(`products/${productId}/estimate_completion_date`, {
//         data: cleanObject({ estimatedCompletionDate } || {}),
//         method: "PUT",
//       })
//     );
//   };
//   return {
//     mutate,
//     ...asyncResult,
//   };
// };

// export const useRenewTask = () => {
//   const { run, ...asyncResult } = useAsync<File>();
//   const client = useHttp();
//   const mutate = (serviceRequired: string, productId: string) => {
//     return run(
//       client(`products/${productId}/renew`, {
//         data: cleanObject({ serviceRequired } || {}),
//         method: "POST",
//       })
//     );
//   };
//   return {
//     mutate,
//     ...asyncResult,
//   };
// };

export const useListReview = ({
  status = "",
  progress = "",
  keywords
}: {
  status: StatusType | Array<StatusType>;
  progress: StatusType | Array<StatusType>;
  keywords?: string
}) => {
  const [data, onChange, ...rest] = useFetchList({
    endpoint: `products`,
    queryKey: `merchant/${status || "all"}`,
    defaultFiltered: {
      status,
      progress,
    },
    keywords,
    options: { enabled: false, keepPreviousData: true },
  });

  useEffect(() => {
    data.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, onChange, ...rest];
};
export const useListExpiringReview = (
  progress: StatusType | Array<StatusType> = ""
) => {
  const [data, onChange, ...rest] = useFetchList({
    endpoint: `products`,
    queryKey: `merchant/${progress || "all"}`,
    defaultFiltered: {
      progress,
    },
    options: { enabled: false, keepPreviousData: true },
  });

  useEffect(() => {
    data.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, onChange, ...rest];
};
export const useListJob = (status: StatusType | Array<StatusType> = "") => {
  const [data, onChange, ...rest] = useFetchList({
    endpoint: `products/tasks`,
    queryKey: `merchant/${status || "all"}`,
    defaultFiltered: {
      status,
    },
    options: { enabled: false, keepPreviousData: true },
  });

  useEffect(() => {
    data.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, onChange, ...rest];
};
