import React from "react";
import { Layout } from "../components/Layout";
import { StyledTitle } from "../components/Title";
import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Space,
  Form,
  Input,
  Typography,
  Col,
  Row,
  FormInstance,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import { FormWithStepper } from "components/Organisms/formWithStepper";
import PhoneInput from "components/Atoms/input/PhoneInput";
import SignUpSuccess from "components/Atoms/result/signup-success";
import { WithTranslation, withTranslation } from "react-i18next";
// import { usePasswordValidator } from "hooks";
import { ValidatorOptions } from "pages/Authorization/constant";
import SiderBG from "assets/backgrounds/authorization-sider-sign-up-bg.png";
import ECSPR from "assets/logo/e-cpsr-logo.svg";
import ICWPoweredBy from "assets/logo/icw-powered-by-logo.svg";
import "./SignUp.less";
import InputPassword from "components/Atoms/input/InputPassword";
import { useAuth } from "context/auth-context";

const Container = styled.div`
  display: flex;
  justify-content: center;
  algin-items: center;
  flex-direction: column;
  padding-top: 80px;
  form {
    align-self: center;
    min-width: 410px;
  }
`;

const Sider = styled.div`
  padding: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: #ebedef;
      font-size: 40px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: 0.01em;
      text-align: center;
    }
    div.ant-typography {
      max-width: 315px;
      color: #ebedef;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: center;
    }
    button {
      margin-top: 60px;
      width: 140px;
    }
  }
`;
const StyledFooter = styled(Space)`
  justify-content: flex-end;
`;

const Step1 = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  button {
    margin-top: 16px;
  }
`;

const WrapperBackButton = styled(Space)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

const SignUp = ({ t, i18n }: Partial<WithTranslation>) => {
  const { registerWithMerchants } = useAuth();
  const [registerSuccess, setRegisterSuccess] = React.useState(false);
  const [isRegistering, setIsRegistering] = React.useState(false);
  const formRef = React.useRef<{ instanceForm: FormInstance }>();
  const [validatedValues, setValidatedValues] = React.useState<any>({});
  const [current, setCurrent] = React.useState(0);
  const navigate = useNavigate();
  const goToSignIn = React.useCallback(() => {
    navigate("/authorization/sign-in");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const translate = (key: string) => {
    return t && t(key);
  };

  const handleRegister = async (data: Record<string, any>) => {
    try {
      setIsRegistering(true);
      await registerWithMerchants(data);
      setRegisterSuccess(true);
      setCurrent(2);
    } catch (error) {
      notification.error({
        message: (error as any)?.message,
      });
    } finally {
      setIsRegistering(false);
    }
  };
  const handleResend = async () => {
    //TODO
  };
  const onNext = async () => {
    try {
      const values = await formRef?.current?.instanceForm.validateFields();
      const nextValues = { ...validatedValues, ...values };
      setValidatedValues(nextValues);
      if (current >= 1 && !registerSuccess) {
        //Sign Up.
        const { contactPhone, ...restForm } = nextValues;
        handleRegister({
          ...restForm,
          contactPhone: `${contactPhone.area} ${contactPhone.number}`,
        });
        return;
      }
      setCurrent(current + 1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const renderSider = React.useCallback(() => {
    return (
      <Sider>
        <Space align="start" size={30}>
          <img src={ECSPR} alt="e-cspr"></img>
        </Space>
        <div className="content">
          <Space direction="vertical" align="center" size={[10, 10]}>
            <Typography.Title>Welcome Back!</Typography.Title>
            <Typography.Paragraph>
              {translate("common:SignUpIntro")}
            </Typography.Paragraph>
          </Space>
          <Button onClick={goToSignIn} ghost size="large">
            Login
          </Button>
        </div>
        <StyledFooter align="end">
          <img src={ICWPoweredBy} alt="e-cspr"></img>
        </StyledFooter>
      </Sider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToSignIn]);

  const initialValues = {
    email: "",
    password: "",
    companyName: "",
    contactName: "",
    addressLine1: "",
    addressLine2: "",
    contactPhone: {
      area: "+86",
      number: "",
    },
    area: "",
    contactEmail: "",
  };
  return (
    <Layout
      revert
      className={"container"}
      sider={{ siderBg: SiderBG, renderSider: renderSider }}
    >
      <Container>
        <StyledTitle className={"title"}>Create Account</StyledTitle>
        <FormWithStepper
          ref={formRef}
          stepsProps={{
            current,
          }}
          formProps={{
            name: "sign-up",
            layout: "vertical",
            initialValues: {
              readAgreement: true,
              ...initialValues,
            },
          }}
          steps={[
            {
              title: "Account",
              content: (
                <Step1>
                  <Form.Item
                    required={false}
                    name="email"
                    label={translate("register:Form.field.email.label")}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: translate("register:Form.field.email.help"),
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      size="large"
                      placeholder={translate(
                        "register:Form.field.email.placeholder"
                      )}
                    ></Input>
                  </Form.Item>
                  <InputPassword.WithFormItem
                    formItemProps={{
                      name: "password",
                      label: translate("register:Form.field.password.label"),
                    }}
                    inputProps={{
                      placeholder: translate(
                        "register:Form.field.password.placeholder"
                      ),
                    }}
                    validationOptions={ValidatorOptions}
                  ></InputPassword.WithFormItem>

                  <Button
                    onClick={onNext}
                    size="large"
                    block
                    htmlType="submit"
                    type="primary"
                  >
                    {translate("Next")}
                  </Button>
                </Step1>
              ),
            },
            {
              title: "Company Info",
              content: (
                <>
                  <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Company Name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter merchant name"></Input>
                  </Form.Item>
                  <Form.Item
                    label="Contact Person"
                    name="contactName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Contact Person",
                      },
                    ]}
                  >
                    <Input placeholder="Enter contact person"></Input>
                  </Form.Item>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Contact Person’s Phone Number"
                        name="contactPhone"
                        rules={[
                          {
                            validator: (rule, values) => {
                              const { number } = values;
                              if (!number.length) {
                                return Promise.reject(
                                  new Error("Please input valid phone number")
                                );
                              }
                              return Promise.resolve();
                            },
                            required: true,
                          },
                        ]}
                      >
                        <PhoneInput
                          group
                          selectProps={{
                            formProps: {
                              name: ["contactPhone", "area"],
                            },
                          }}
                          inputProps={{
                            formProps: {
                              name: ["contactPhone", "number"],
                            },
                            type: "tel",
                            placeholder: "Enter phone number",
                          }}
                        ></PhoneInput>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Contact Person’s Email (optional)"
                        name="contactEmail"
                        rules={[
                          {
                            type: "email",
                            message: "Please input valid email",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder="Enter contact email"
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Address Line 1 (English)"
                    name="addressLine1"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Address Line 1 (English)",
                      },
                    ]}
                  >
                    <Input placeholder="Enter address (english)"></Input>
                  </Form.Item>
                  <Form.Item
                    label="Address Line 2 (Local Language)"
                    name="addressLine2"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your Address Line 2 (Local Language)",
                      },
                    ]}
                  >
                    <Input placeholder="Enter address (local language)"></Input>
                  </Form.Item>
                  <Form.Item
                    label="Area"
                    name="area"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Area",
                      },
                    ]}
                  >
                    <Input placeholder="Enter area"></Input>
                  </Form.Item>
                  <div>
                    <Form.Item
                      name="readAgreement"
                      valuePropName="checked"
                      rules={[
                        {
                          type: "boolean",
                          required: true,
                        },
                      ]}
                    >
                      <Checkbox>
                        I agree to the{" "}
                        <a
                          href="https://www.icw.io/terms-conditions/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms & Conditions
                        </a>{" "}
                        ,{" "}
                        <a
                          href="https://www.icw.io/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>{" "}
                        , and{" "}
                        <a
                          href="https://www.icw.io/cookie-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Cookie Policy.
                        </a>
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <Button
                    loading={isRegistering}
                    onClick={onNext}
                    size="large"
                    block
                    htmlType="submit"
                    type="primary"
                  >
                    {translate("Sign Up")}
                  </Button>
                  <WrapperBackButton align="center" direction="horizontal">
                    <Button
                      loading={isRegistering}
                      onClick={() => {
                        setCurrent(current - 1);
                      }}
                      size="large"
                      type="link"
                    >
                      Back
                    </Button>
                  </WrapperBackButton>
                </>
              ),
            },
            {
              title: "Verify Email",
              content: (
                <SignUpSuccess
                  email={validatedValues?.email ?? ""}
                  onClickSignIn={goToSignIn}
                  onClickResend={handleResend}
                />
              ),
            },
          ]}
        ></FormWithStepper>
      </Container>
    </Layout>
  );
};

export default withTranslation(["common", "register"])(SignUp);
