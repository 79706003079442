/* eslint-disable react-hooks/exhaustive-deps */
import FullScreenModal from "../../../components/Molecules/modal/full-screen-modal";
import {
  Button,
  Descriptions as AntdDescriptions,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Result,
  Space,
  Typography,
} from "antd";
import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {useFetchPaymentIntent} from "hooks";
import {Layout} from "pages/Authorization/components/Layout";
import styled from "@emotion/styled";
import SiderBG from "assets/backgrounds/authorization-sider-sign-up-bg.png";
import ECSPR from "assets/logo/e-cpsr-logo.svg";
import ICWPoweredBy from "assets/logo/icw-powered-by-logo.svg";
import {PaymentSteps} from "../components/PaymentSteps";
import {loadStripe} from "@stripe/stripe-js";
import {PaymentForm} from "../components/PaymentForm";
import {usePromoteCode} from "hooks/usePromoteCode";

const Container = styled.div`
    display: flex;
    justify-content: center;
    algin-items: center;
    flex-direction: column;
    padding-top: 80px;

    form {
        align-self: center;
        min-width: 410px;
    }

    gap: 24px;
`;
const Sider = styled.div`
    padding: 30px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            color: #ebedef;
            font-size: 40px;
            font-weight: 700;
            line-height: 47px;
            letter-spacing: 0.01em;
            text-align: center;
        }

        div.ant-typography {
            max-width: 315px;
            color: #ebedef;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-align: center;
        }

        button {
            margin-top: 60px;
            width: 140px;
        }
    }
`;

const Descriptions = styled(AntdDescriptions)`
    width: 80%;
    margin: auto;

    th {
        > span {
            font-weight: bold;
        }
    }
`;
const StyledFooter = styled(Space)`
    justify-content: flex-end;
`;

const WrapperPromoteCode = styled(Space)`
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    div:first-child {
        width: 100%;

        .ant-form-item {
            margin-bottom: 0;
        }
    }
`;

function PaymentModal(props: any) {
  const {
    paymentVisible,
    setPaymentVisible,
    handlePayment,
    handlePaymentPromoteCode,
    onPaymentSuccess,
    paymentIntent,
    paymentDetail,
    setPaymentDetail,
    paymentIntentLoading,
    task
  } = props;

  const [switchPaymentLoading, setSwitchPaymentLoading] = useState(false);
  useEffect(() => {
    setSwitchPaymentLoading(false)
  }, [paymentIntent.client_secret])

  const onChange = (e: RadioChangeEvent) => {
    setSwitchPaymentLoading(true)
    if (e.target.value === 'card') {
      setPaymentDetail({
        ...paymentDetail,
        currency: 'usd',
        amount: task.isReUploadPayment? 1800 : 3500,
        paymentMethod: e.target.value
      });
    } else {
      setPaymentDetail({
        ...paymentDetail,
        currency: 'cny',
        amount: task.isReUploadPayment? 13088 : 25449,
        paymentMethod: e.target.value
      });
    }
  };

  // const [, setIsSuccess] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);
  // const handleStripePayment = React.useCallback((status, paymentIntent) => {
  //   if (status === "succeeded") {
  //     handlePayment({
  //       amount: paymentIntent.amount,
  //       transactionNumber: paymentIntent.id,
  //       paymentStatus: "successful", //paymentIntent.status,
  //       paymentMethod: paymentIntent.paymentMethod,
  //       currency: paymentIntent.currency,
  //       remark: paymentIntent.description,
  //     });
  //     setIsSuccess(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [promoteCode, setPromoteCode] = React.useState("");
  const {isValid, isLoading, error, verify} = usePromoteCode(promoteCode);

  // const {handleSubmit, isLoading: paymentLoading} = useStripePayment(
  //   handleStripePayment
  // );
  const renderSider = React.useCallback(() => {
    return (
      <Sider>
        <Space align="start" size={30}>
          <img src={ECSPR} alt="e-cspr"></img>
        </Space>
        <StyledFooter align="end">
          <img src={ICWPoweredBy} alt="e-cspr"></img>
        </StyledFooter>
      </Sider>
    );
  }, []);

  const renderStep = (step) => {
    if (step === 0) {
      const paymentDetails = paymentIntent?.metadata;
      if (!paymentDetails) return <></>;

      return (
        <Descriptions
          title="Payment Details"
          bordered
          extra={
            <Button
              loading={switchPaymentLoading}
              type="primary"
              onClick={async () => {
                if (isValid) {
                  //TODO: Send payment to BE
                  const response = await handlePaymentPromoteCode({
                    code: promoteCode,
                  });
                  if (response.success) {
                    setCurrentStep(2);
                  }

                  return;
                }
                setCurrentStep(1);
              }}
            >
              {isValid ? "Pay" : "Next"}
            </Button>
          }
        >

          <AntdDescriptions.Item label="Payment Method" span={3}>
            <Radio.Group onChange={onChange} value={paymentDetail.paymentMethod}>
              <Radio value={'card'}>Card</Radio>
              {/*<Radio value={'wechat_pay'}>微信支付</Radio>*/}
              <Radio value={'alipay'}>支付宝 / Alipay</Radio>
            </Radio.Group>
          </AntdDescriptions.Item>

          <AntdDescriptions.Item label="Amount" span={3}>
            {`${
              paymentDetails?.amount ? paymentDetails?.amount : 0
            } ${paymentDetails?.currency.toUpperCase()}`}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label="E-mail" span={3}>
            {paymentDetails?.email}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label="Compliance Check Item" span={3}>
            {paymentDetails?.itemNumber}
          </AntdDescriptions.Item>
          {/*<AntdDescriptions.Item label="Service Required" span={3}>*/}
          {/*  {paymentDetails?.serviceRequired}*/}
          {/*</AntdDescriptions.Item>*/}
          <AntdDescriptions.Item label="Promote Code" span={3}>
            <WrapperPromoteCode align="center">
              {isValid ? (
                <Typography.Text strong>{promoteCode}</Typography.Text>
              ) : (
                <Form.Item
                  extra={
                    <Typography.Text type="danger">{error}</Typography.Text>
                  }
                >
                  <Input
                    status={error ? "error" : ""}
                    onChange={(event) => {
                      setPromoteCode(event.target.value);
                    }}
                    placeholder="Input your promote code"
                  ></Input>
                </Form.Item>
              )}

              <Space>
                {!isValid && (
                  <Button
                    loading={isLoading}
                    type="primary"
                    ghost
                    disabled={!promoteCode.length}
                    onClick={() => {
                      verify();
                    }}
                  >
                    Apply
                  </Button>
                )}

                {isValid && (
                  <Button danger ghost>
                    Remove
                  </Button>
                )}
              </Space>
            </WrapperPromoteCode>
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label="Total">
            <Typography.Title>{`${
              paymentDetails?.amount ? paymentDetails?.amount : 0
            } ${paymentDetails?.currency.toUpperCase()}`}</Typography.Title>
          </AntdDescriptions.Item>
        </Descriptions>
      );
    }
    if (step === 1) {
      return (
        paymentIntent.client_secret && (
          <Elements
            options={{
              clientSecret: paymentIntent?.client_secret,
              appearance: {
                theme: "stripe",
              },
            }}
            stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY)}
          >
            <PaymentForm
              clientSecret={paymentIntent?.client_secret}
              handlePayment={handlePayment}
              // handlePayment={handleSubmit}
              // loading={paymentLoading}
            ></PaymentForm>
          </Elements>
        )
      );
    }
    return (
      <Result
        status="success"
        title="Successfully Purchased"
        extra={
          <Button
            onClick={() => {
              onPaymentSuccess();
              setPaymentVisible(false);
              setCurrentStep(0);
            }}
          >
            Continue
          </Button>
        }
      ></Result>
    );
  };

  return (
    <FullScreenModal
      visible={paymentVisible}
      footer={null}
      onCancel={() => {
        setPaymentVisible(false);
        setCurrentStep(0);
      }}
      bodyStyle={{
        height: "100%",
        padding: 0,
      }}
    >
      <Layout revert sider={{siderBg: SiderBG, renderSider: renderSider}}>
        <Container>
          <PaymentSteps
            currentStep={currentStep}
            onChange={(nextStep) => {
              if (isValid && nextStep !== 2) return;
              if (isValid && nextStep === 1) return;
              setCurrentStep(nextStep);
            }}
          ></PaymentSteps>
          {renderStep(currentStep)}
        </Container>
      </Layout>
    </FullScreenModal>
  );
}

export const StripePaymentModal = (props) => {

  const [testState, setTestState] = useState('')
  const {taskId, paymentDetail} = props;
  const {
    refetch: createPaymentIntent,
    data: paymentIntent,
    isLoading: paymentIntentLoading,
    isFetched,
  } = useFetchPaymentIntent(`tasks/${taskId as any}/paymentIntent`, paymentDetail);

  React.useEffect(() => {
    if (!paymentIntentLoading && !isFetched) {
      //Step 2
      createPaymentIntent();
    }
  }, [taskId]);

  React.useEffect(() => {
    if (!paymentIntentLoading) {
      //Step 2
      createPaymentIntent();
    }
  }, [paymentDetail.paymentMethod]);

  React.useEffect(() => {
    if (paymentIntent?.client_secret) {
      setTestState(paymentIntent.client_secret)
    }
  }, [paymentIntent?.client_secret])

  if (!paymentIntent) return null;
  return (

    <PaymentModal paymentIntent={paymentIntent} {...props} paymentIntentLoading={paymentIntentLoading} ></PaymentModal>
  );
};
