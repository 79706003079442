/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {TaskIndexCard} from "../../../components/Molecules/task-card/task-index-card";
import {Col, Divider, Menu, MenuProps, Row, Space, Typography} from "antd";
import {TaskDetailCard} from "../../../components/Molecules/task-card/task-detail-card";
import CusPageHeader from "../../../components/Molecules/header";
import Page from "../../../components/Molecules/page";
import {Content} from "antd/es/layout/layout";
import {useNavigate, useParams} from "react-router-dom";
import {useProductDetail, useProductTasks} from "./api";
import {useDebounce} from "../../../utils";
import styled from "@emotion/styled";
import {StaticDataContext} from "context/static-data-context";
import {MainLayout} from "components/Organisms/layouts";
import RejectReasonSection from "pages/Reviews/components/RejectReason";

const items: MenuProps["items"] = [
  {
    label: "Task",
    key: "task",
  },
];

export const ProjectPage = () => {
  const [, setExpanded] = React.useState(false);
  const [stateDescription, setStateDescription] = React.useState({
    expand: false,
    counter: 0,
  });
  let navigate = useNavigate();
  const {ageGrade, serviceRequired} = React.useContext(StaticDataContext);
  const {id} = useParams();
  const [param] = useState({id});
  const [activeTask, setActiveTask] = useState<any>();

  const {data: productDetails, retry: productReload} = useProductDetail(
    useDebounce(param, 200)
  );

  const {data: productTask, retry: tasksReload} = useProductTasks(
    useDebounce(param, 200)
  );

  useEffect(() => {
    if (productTask && productTask.length > 0) {
      setActiveTask(productTask[0]);
    }
  }, [productTask]);

  const handleReloadData = () => {
    productReload();
    tasksReload();
  };

  const renderContent = (column = 6) => (
    <>
      <Row wrap>
        <Col flex="none" style={{wordWrap: "break-word"}}>
          <HeadTitle1>Product Number:</HeadTitle1>
          {productDetails?.productNumber}
          <VerticalLine type={`vertical`}/>
        </Col>
        <Col flex="none">
          <HeadTitle1>Company: </HeadTitle1>
          {productDetails?.productSeller || productDetails?.merchant}
          <VerticalLine type={`vertical`}/>
        </Col>
        <Col flex="none">
          <HeadTitle1>Export Market: </HeadTitle1>
          {productDetails?.exportMarket}
          <VerticalLine type={`vertical`}/>
        </Col>
        {/*<Col flex="none">*/}
        {/*  <HeadTitle1>Age Grade: </HeadTitle1>*/}
        {/*  {ageGrade.find(({ value }) => value === productDetails?.ageGrade)*/}
        {/*    ?.label ?? "N/A"}*/}
        {/*  <VerticalLine type={`vertical`} />*/}
        {/*</Col>*/}

        {
          productDetails?.testedAgeGrade &&
          <Col flex="none">
            <HeadTitle1>Tested Age Grade: </HeadTitle1>
            {productDetails?.testedAgeGrade ?? "N/A"}
            <VerticalLine type={`vertical`}/>
          </Col>
        }

        {
          productDetails?.labelledAgeGrade &&
          <Col flex="none">
            <HeadTitle1>Labelled Age Grade: </HeadTitle1>
            {productDetails?.labelledAgeGrade ?? "N/A"}
            <VerticalLine type={`vertical`}/>
          </Col>
        }

        {
          productDetails?.nrtlCertNumber &&
          <Col flex="none">
            <HeadTitle1>NRTL Cert Number: </HeadTitle1>
            {productDetails?.nrtlCertNumber ?? "N/A"}
            <VerticalLine type={`vertical`}/>
          </Col>
        }

        {/*<Col flex="none">*/}
        {/*  <HeadTitle1>Service Required: </HeadTitle1>*/}
        {/*  {serviceRequired*/}
        {/*    .find(({ value }) => value === productDetails?.serviceRequired)*/}
        {/*    ?.label.split(",")[0] ?? "N/A"}*/}
        {/*</Col>*/}
      </Row>
      <Row wrap style={{marginTop: 5}}>
        <Col flex="none">
          <HeadTitle1>Suppliers: </HeadTitle1>
          {Array.isArray(productDetails?.supplierTags) &&
          !!productDetails?.supplierTags.length
            ? productDetails?.supplierTags.join(", ")
            : "N/A"}
        </Col>
      </Row>
      <Row wrap style={{marginTop: 5}}>
        <Col flex="none">
          <HeadTitle1>Product Category: </HeadTitle1>
          {productDetails?.mainCategory}
          <VerticalLine type={`vertical`}/>
        </Col>
        <Col flex="none">
          <HeadTitle1>Product Type Group: </HeadTitle1>
          {productDetails?.category}
          <VerticalLine type={`vertical`}/>
        </Col>
        <Col flex="none">
          <HeadTitle1>Product Type: </HeadTitle1>
          {productDetails?.subcategory}
        </Col>
      </Row>
      <Row wrap style={{marginTop: 5}}>
        <Col flex="none">
          <HeadTitle1>UPC: </HeadTitle1>
          {productDetails?.upc}
          <VerticalLine type={`vertical`}/>
        </Col>
        <Col flex="none">
          <HeadTitle1>Country of Origin: </HeadTitle1>
          {productDetails?.countryOfOrigin}
        </Col>
      </Row>
      <Row wrap style={{marginTop: 5}}>
        <Col flex="none">
          <HeadTitle1>Product Keywords:</HeadTitle1>
          {productDetails?.productKeywords ?? 'N/A'}
          {/*<VerticalLine type={`vertical`} />*/}
        </Col>
      </Row>
      <Row wrap style={{marginTop: 5}}>
        <Col span={12}>
          <Space align="start" key={`${stateDescription.counter}`}>
            <HeadTitle1>Description: </HeadTitle1>
            <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: "more",
                onExpand: (event) => {
                  setStateDescription({
                    expand: true,
                    counter: !stateDescription.expand
                      ? stateDescription.counter + 0
                      : stateDescription.counter + 1,
                  });
                  setExpanded(true);
                },
              }}
            >
              {productDetails?.productDescription}
              {stateDescription.expand && (
                <a
                  className="ant-typography-expand"
                  aria-label="Collapse"
                  onClick={() => {
                    setStateDescription({
                      expand: false,
                      counter: !stateDescription.expand
                        ? stateDescription.counter + 0
                        : stateDescription.counter + 1,
                    });
                  }}
                >
                  less
                </a>
              )}
            </Typography.Paragraph>
          </Space>
        </Col>
      </Row>
    </>
  );

  return (
    <MainLayout>
      <CusPageHeader
        title={productDetails?.productName}
        onBack={() => navigate(-1)}
      >
        <Content>{renderContent()}</Content>
      </CusPageHeader>
      <Menu selectedKeys={["task"]} mode="horizontal" items={items}/>
      <Page>
        <Row gutter={16}>
          <Col flex={"480px"}>
            {productTask?.map((task) => (
              <TaskIndexCard key={task.id} task={task}/>
            ))}
          </Col>
          <StyledRight>
            {activeTask && (
              <TaskDetailCard
                rejectReasonSection={<RejectReasonSection/>}
                task={activeTask}
                reload={handleReloadData}
              />
            )}
          </StyledRight>
        </Row>
      </Page>
    </MainLayout>
  );
};

const HeadTitle1 = styled.span`
    color: #777777;
    margin-right: 5px;
`;

const VerticalLine = styled(Divider)`
    border: 1px solid #e4e4e4;
    margin: 0px 10px 0px 10px !important;
`;

const StyledRight = styled(Col)`
    flex-grow: 1;
    flex-basis: 60%;
`;

export default ProjectPage;
