import React from "react";
import { useAuth } from "context/auth-context";
import styled from "@emotion/styled";
import { ButtonNoPadding, Row } from "components/lib";
import { Button, Dropdown, Layout, Menu } from "antd";
import { resetRoute } from "utils";
import classname from "classnames";
// prop drilling
// const items: MenuProps["items"] = [
// {
//   label: 'HOME',
//   key: 'HOME',
// },
// {
//   label: 'ALL PRODUCTS',
//   key: 'ALL PRODUCTS',
// },
// ];

export const MainLayout = ({ children, className }: any) => {
  // const { user } = useAuth();

  return (
    <Layout className={classname("layout", className)}>
      <PageHeader />
      {children}
    </Layout>
  );
};

const PageHeader = () => {
  return (
    <CusHeader between={true}>
      <HeaderLeft gap={true}>
        <ButtonNoPadding type={"link"} onClick={resetRoute}>
          <Logo />
        </ButtonNoPadding>
        {/* <CusMenu mode="horizontal" defaultSelectedKeys={["1"]} items={items} /> */}
      </HeaderLeft>
      <HeaderRight>
        <User />
      </HeaderRight>
    </CusHeader>
  );
};

const User = () => {
  const { logout, user } = useAuth();
  return (
    <div style={{ display: 'flex' }}>
      <Button
        type={"link"}
        onClick={(e) => {
          window.open('https://icw-io.notion.site/ICW-x-Walmart-Help-Center-e-CPSR-a9867abf7aff408a950e78da6ef4544b?pvs=4', '__blank')
        }}
        style={{ color: "#FFF" }}
      >
        Help Center
      </Button>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="contact-us">
              <Button type={"link"}>
                <a href="mailto:info@icw.com?subject=e-CPSR - Enquiry">
                  Contact Us
                </a>
              </Button>
            </Menu.Item>
            <Menu.Item key={"logout"}>
              <Button onClick={logout} type={"link"}>
                Logout
              </Button>
            </Menu.Item>
          </Menu>
        }
      >
        <Button
          type={"link"}
          onClick={(e) => e.preventDefault()}
          style={{ color: "#FFF" }}
        >
          Hi, {user?.email}
        </Button>
      </Dropdown>
    </div>
  );
};

const Logo = styled.div`
  width: 75px;
  height: 100%;
  background-image: url("/e-CPSR-logo-white.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const CusHeader = styled(Row)`
  padding: 0px 50px;
  height: 49px;
  background: linear-gradient(90.06deg, #2f82ff 0%, #2154a2 100%);
  z-index: 1;
`;
const HeaderLeft = styled(Row)`
  width: 100%;
`;
const HeaderRight = styled.div``;
