import React from "react";
import { Collapse } from "antd";

import styled from "@emotion/styled";
import FormRender from "components/Molecules/form/FormRenderer";
import { Action, Resource } from "configs/rules";

const { Panel } = Collapse;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 100%;
  margin-bottom: 24px;
  background: #ffffff;
  border: 1px solid rgba(170, 170, 170, 0.3);
  border-radius: 16px;
  position: relative;

  h2,
  .title {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
  .ant-divider {
    margin-top: 0;
  }
`;

export interface File {
  fileName: string;
  url?: string;
  size: number;
  mimetype: string;
  uuid: string;
}

interface UploadDocProps {
  section: {
    title: string;
    name: string;
    footNote: string;
    required: boolean;
    fields: Array<any>;
  };
  resource?: string;
  action?: string;
  canActivate?: (resource: string, action: string, field: string) => boolean;
}

export function Section({ section, canActivate }: UploadDocProps) {
  const fields = React.useMemo(() => {
    return section.fields.map((field) => {
      const { name } = field;

      if (canActivate && name) {
        const isCreate = canActivate(
          `${Resource.JOBS}.form`,
          Action.CREATE,
          name.join ? name.join(".") : name
        );
        const isEdit = canActivate(
          `${Resource.JOBS}.form`,
          Action.EDIT,
          name.join ? name.join(".") : name
        );

        return {
          ...field,
          canActivate,
          resource: `${Resource.JOBS}.form`,
          action: Action.CREATE,
          readonly: !(isCreate || isEdit) || field.readonly,
        };
      }
      return {
        ...field,
        canActivate,
        resource: `${Resource.JOBS}.form`,
        action: Action.CREATE,
      };
    });
  }, [section.fields]);
  return (
    <section id={section.name}>
      <Container>
        <Collapse
          ghost
          expandIconPosition={`start`}
          style={{ width: "100%", padding: 0 }}
        >
          <Panel
            forceRender
            header={
              <StyledHeader danger={section.required}>
                {section.title}
                {section.required && (
                  <RequireInfo>This section is required.</RequireInfo>
                )}
                {/* {props.hints && (
              <RequireInfo>This section is required.</RequireInfo>
            )}
            {props.preferable && (
              <RequireInfo>This section is preferable.</RequireInfo>
            )}
            {props.reviewerRemark && (
              <ReviewRemark>
                Remark from reviewer - {props.reviewerRemark}
              </ReviewRemark>
            )} */}
              </StyledHeader>
            }
            key="1"
          >
            <FormRender fields={fields}></FormRender>
          </Panel>
        </Collapse>
      </Container>
    </section>
  );
}
const Header = ({ danger, children, ...props }: any) => {
  return <h2 {...props}>{children}</h2>;
};

const StyledHeader = styled(Header)`
  color: ${(props) => (props.danger ? "#ED4646" : " #000000")};
  > div:last-child {
    color: ${(props) => (props.danger ? "#ED4646" : " #000000")};
    h2: {
      margin-bottom: 0;
    }
  }
`;
const RequireInfo = styled.div`
  color: #ff5555 !important;
  font-size: 12px;
`;
