import React, {useEffect} from "react";
import {Col, Form, Image, Input, Modal, Row, Select, Upload} from "antd";
import {default as StyledSelect} from "components/Atoms/select";
import {CategorySelect} from "../../components/Molecules/select/category-select";
import {SubCategorySelect} from "../../components/Molecules/select/subcategory-select";
import {ProductGroupSelect} from "../../components/Molecules/select/product-group-select";
import {ExportMarketSelect} from "../../components/Molecules/select/export-market-select";
import {StaticDataContext, useStaticSupplierTags,} from "context/static-data-context";
import {FormWithStepper} from "components/Organisms/formWithStepper";
import {FormInstance} from "antd/es/form/Form";
import {CloudUploadOutlined} from "@ant-design/icons";
import {useUploadFile} from "components/Organisms/uploads/api";
import Tag from "components/Atoms/tag";
import styled from "@emotion/styled";
import {withTranslation} from "react-i18next";
import {getData} from "country-list";
import {useProductList, useProductListSeller, useSubCategory} from "../../components/Molecules/select/api";
import {MainCategorySelect} from "../../components/Molecules/select/main-category-select";

const Container = styled(Col)`
    height: 100%;
`;

const TooltipContent = styled.ul`
    list-style: disc;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
`;
export const CreateProductModal = withTranslation(["result"])((props: any) => {
  const {
    createProductSuccess,
    handleFormValuesChange,
    isCreating,
    categoryId,
    mainCategoryId,
    createProduct,
    createProductModalVisible,
    handleCloseModal,
    subCategoryId,
    t,
  } = props;

  const {
    ageGrade: ageGradeOptions,
    serviceRequired: ServiceRequiredOptions,
  } = React.useContext(StaticDataContext);
  // const supplierTagsOptions = useStaticSupplierTags(true);
  const [createValues, setValues] = React.useState({});
  const [productNumberOption, setProductNumberOption] = React.useState([]);
  const [productSellerOption, setProductSellerOption] = React.useState([]);
  const [productSeller, setProductSeller] = React.useState("");
  const [productNameOption, setProductNameOption] = React.useState([]);
  const [hasUpc, setHasUpc] = React.useState(false);
  const [subCatId, setSubCatId] = React.useState()
  const [taskType, setTaskType] = React.useState()
  const [selectedProductNumber, setSelectProductNumber] = React.useState(0);

  const {mutate: upload} = useUploadFile();
  const createProductionRef = React.useRef<{ instanceForm: FormInstance }>();

  const {data: productList, isSuccess, retry} = useProductList(createProductionRef.current?.instanceForm.getFieldValue('subcategoryId'), createProductionRef.current?.instanceForm.getFieldValue('productSeller'));
  const productListSeller = useProductListSeller(createProductionRef.current?.instanceForm.getFieldValue('subcategoryId') || null);
  const subCategoryDetail = useSubCategory(createProductionRef.current?.instanceForm.getFieldValue('subcategoryId') || null);

  useEffect(() => {
    if (createProductModalVisible) {
      retry()
    }
  }, [createProductModalVisible]);

  useEffect(() => {
    if (productListSeller.isSuccess && productListSeller.data.length >= 0) {
      const tempProductListSeller = []
      productListSeller.data.forEach(seller => {
        tempProductListSeller.push({
          label: seller.sellerDisplayName,
          value: seller.sellerDisplayName,
        })
      })

      if (tempProductListSeller.length === 1) {
        createProductionRef.current?.instanceForm.setFieldValue('productSeller', tempProductListSeller[0].value)
      }

      setProductSellerOption(tempProductListSeller);
    }

  }, [productListSeller.isSuccess]);

  useEffect(() => {
    if (subCategoryDetail.isSuccess && subCategoryDetail.data) {
      setTaskType(subCategoryDetail.data.allowTaskType)
      createProductionRef.current?.instanceForm.setFieldValue('taskType', subCategoryDetail?.data?.allowTaskType)
    }
  }, [subCategoryDetail.isSuccess]);



  useEffect(() => {
    if (isSuccess && productList.length >= 0) {
      const tempNumberArr = [];
      const tempNameArr = [];
      productList.forEach(item => {
        tempNumberArr.push({
          label: item.itemNumber + ` (` + item.productName + ')',
          value: item.itemNumber,
        })

        tempNameArr.push({
          label: item.productName,
          value: item.productName,
        })
      })

      setProductNameOption(tempNameArr)

      setProductNumberOption(tempNumberArr)
    }

  }, [isSuccess]);


  const cusUpload = async (options: any) => {
    const {onSuccess, onError, file} = options;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res: File = await upload(formData);
      onSuccess(res);
    } catch (err) {
      // const error = new Error("Some error");
      onError({err});
    }
  };

  const normFile = (e: any) => {
    if (e.file.status !== "done") {
      return e && e.fileList;
    }

    const temp = e.fileList.map((item: any) => {
      if (item.response) {
        return {
          uuid: item.response.uuid,
          docType: props.name,
          ...item,
        };
      }
      return item;
    });

    return temp;
  };

  const uploadProps = {
    name: "files",
    multiple: true,
    customRequest: cusUpload,
    accept: "image/*",
  };

  const [current, setCurrent] = React.useState(0);
  const getButtonByStep = () => {
    if (current < 2) return "Next";
    return "Create";
  };

  const onSubmitStep1 = (values: Record<string, any>) => {

    let finalUpc = '';

    if (values.upc && values.upc.length > 0) {
      finalUpc = values.upc.toString();
      if (values.additionalUpc) {
        finalUpc += `,${values.additionalUpc}`;
      }
    } else {
      finalUpc += `${values.additionalUpc}`;
    }

    setValues({
      ...createValues,
      ...values,
      upc: finalUpc
      // supplierTags: values.supplierTags.map(({value}: any) => value),
    });
    setCurrent(current + 1);
  };
  const onSubmitStep2 = (values: Record<string, any>) => {
    setValues({
      ...createValues,
      productImages: values.productImages.map(({response}: any) => ({
        ...response,
        type: "product_image",
      })),
    });
    if (createProductSuccess) {
      setCurrent(current + 1);
    }
  };

  const resetModal = () => {
    setCurrent(0);
    setValues({});
    createProductionRef.current?.instanceForm.resetFields();
  };

  React.useEffect(() => {
    if (createProductSuccess) {
      setCurrent(current + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProductSuccess]);

  const handleProductNumberOnChange = (value: string) => {
    const tempArr = []
    const tempUpc = []
    productList.forEach(item => {
      if (value.includes(item.itemNumber)) {
        tempArr.push(item.productName)
        if (item.upc) {
          tempUpc.push(item.upc)
        }
      }
    })


    if (tempUpc.length > 0) {
      setHasUpc(true)
    } else {
      setHasUpc(false)
    }

    setSelectProductNumber(tempArr.length)

    createProductionRef.current?.instanceForm.setFieldValue("productName", Array.from(new Set(tempArr)));
    createProductionRef.current?.instanceForm.setFieldValue("upc", Array.from(new Set(tempUpc)));
  }

  const handleProductSellerOnChange = (value: string) => {
    setProductSeller(value)
  }

  useEffect(() => {
    createProductionRef.current?.instanceForm.setFieldValue('subcategoryId', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('categoryId', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productNumber', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productName', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productSeller', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('upc', undefined)
    setSubCatId(undefined)
  }, [createProductionRef.current?.instanceForm.getFieldValue('mainCategoryId')]);

  useEffect(() => {
    createProductionRef.current?.instanceForm.setFieldValue('subcategoryId', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productNumber', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productName', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productSeller', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('upc', undefined)
    setSubCatId(undefined)
  }, [createProductionRef.current?.instanceForm.getFieldValue('categoryId')]);

  useEffect(() => {
    setSubCatId(createProductionRef.current?.instanceForm.getFieldValue('subcategoryId'))
    createProductionRef.current?.instanceForm.setFieldValue('productNumber', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productName', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productSeller', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('upc', undefined)
    // createProductionRef.current?.instanceForm.setFieldValue('taskType', undefined)
  }, [createProductionRef.current?.instanceForm.getFieldValue('subcategoryId')]);

  useEffect(() => {
    createProductionRef.current?.instanceForm.setFieldValue('productNumber', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('productName', undefined)
    createProductionRef.current?.instanceForm.setFieldValue('upc', undefined)

  }, [createProductionRef.current?.instanceForm.getFieldValue('productSeller')]);

  return (
    <Modal
      title={`Create a Product`}
      keyboard={false}
      okText={createProductSuccess ? `Go to product` : getButtonByStep()}
      confirmLoading={isCreating}
      maskClosable={false}
      destroyOnClose
      onOk={async () => {
        try {
          const values = await createProductionRef.current?.instanceForm.validateFields();
          if (current < 2) {
            onSubmitStep1(values);
            return;
          }
          if (current < 3) {
            onSubmitStep2(values);
          }
          if (!createProductSuccess) {
            createProductionRef.current?.instanceForm.submit();
            return;
          }
          createProduct({...createValues});
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(new Error("Form has not valid"));
        }
      }}
      visible={createProductModalVisible}
      onCancel={() => {
        handleCloseModal();
        setHasUpc(false)
        resetModal();
      }}
      width={"900px"}
    >
      <FormWithStepper
        ref={createProductionRef}
        stepsProps={{
          current,
          onChange: (step) => {
            if (step >= 2) {
              setCurrent(createProductSuccess ? step : current);
            } else {
              setCurrent(step);
            }
          },
        }}
        steps={[
          {
            title: "Info",
            content: (
              <div>
                <div style={{marginBottom: 15, color: "#4E5A71"}}>
                  Provide necessary information to create a product.
                </div>

                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={7}>
                    <Form.Item
                      name={"mainCategoryId"}
                      label={"Product Category"}
                      rules={[
                        {
                          required: true,
                          message: "Product Category cannot be empty",
                        },
                      ]}
                    >
                      <MainCategorySelect
                        placeholder={"Enter Product Category"}/>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name={"categoryId"}
                      label={"Product Type Group"}
                      rules={[
                        {
                          required: true,
                          message: "Product Type Group cannot be empty",
                        },
                      ]}
                    >
                      <CategorySelect
                        categoryId={mainCategoryId}
                        placeholder={"Enter Product Type Group"}/>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name={"subcategoryId"}
                      label={"Product Type"}
                      rules={[
                        {
                          required: true,
                          message: "Product type cannot be empty",
                        },
                      ]}
                    >
                      <SubCategorySelect
                        categoryId={categoryId}
                        placeholder={"Enter Product type"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col span={24}>
                    <Form.Item
                      name={"taskType"}
                      label={"Task Type"}
                    >
                      <Select
                        disabled={true}
                        placeholder={"Enter Task type"}
                      >
                        <Select.Option value="cpcGccReview">CPC / GCC</Select.Option>
                        <Select.Option value="documentReview">Document Review</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ),
          },
          {
            title: "Product Information",
            content: (
              <div>
                {
                  taskType === "cpcGccReview" ?
                    <>
                      <div style={{color: "#4E5A71"}}>
                        *US CPSC set forth requirements for CPC/GCC. Please review the details via below link. iCW
                        reserves
                        the right to reject any non-compliant CPC/GCC submissions, which may result in re-submission and
                        incur
                        additional review cost.
                      </div>
                      <div>
                        <a target="_blank"
                           href="https://www.cpsc.gov/Business--Manufacturing/Testing-Certification/Childrens-Product-Certificate">https://www.cpsc.gov/Business--Manufacturing/Testing-Certification/Childrens-Product-Certificate</a>
                      </div>
                      <div>
                        <a target="_blank"
                           href="https://www.cpsc.gov/Business--Manufacturing/Testing-Certification/General-Certificate-of-Conformity">https://www.cpsc.gov/Business--Manufacturing/Testing-Certification/General-Certificate-of-Conformity</a>
                      </div>
                      <div style={{marginBottom: 15, color: "#4E5A71"}}>
                        *Please be noted that US CPSC is working on an eFiling initiative where importers of regulated
                        consumer products may electronically file certificate of compliance (CPC/GCC). Under the current
                        eFiling procedures, each product will need its own separation submission. Thus, it is
                        recommended to
                        have 1 CPC/GCC for 1 product or otherwise, iCW may not able to support the data migration to
                        CPSC
                        eFiling system in future.
                      </div>
                    </> :
                    <>
                      <div style={{color: "#4E5A71"}}>
                        Required documents:
                      </div>
                      <div>
                        1. NRTL certificate (e.g.: UL, ETL, CSA or equivalent)
                      </div>
                      <div>
                        2. Product photo
                      </div>
                      <div style={{marginBottom: 15}}>
                        3. Photo of product label showing the listing number and model number
                      </div>
                    </>
                }

                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name={"productSeller"}
                      label={"Product Seller"}
                      rules={[
                        {
                          required: true,
                          message: "Product Seller cannot be empty",
                        },
                      ]}
                    >
                      <Select
                        // mode="one"
                        allowClear
                        disabled={!createProductionRef.current?.instanceForm.getFieldValue('subcategoryId')}
                        style={{width: '100%'}}
                        placeholder="Please select"
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        // filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        // defaultValue={['a10', 'c12']}
                        onChange={handleProductSellerOnChange}
                        options={productSellerOption}
                      />

                      {/*<Input placeholder={"Enter Product Number"} />*/}
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name={"productNumber"}
                      label={`Product Number (${selectedProductNumber} / ${productNumberOption.length})`}
                      rules={[
                        {
                          required: true,
                          message: "Product Number cannot be empty",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        disabled={!createProductionRef.current?.instanceForm.getFieldValue('productSeller')}
                        style={{width: '100%'}}
                        placeholder="Please select"
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        // filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        // defaultValue={['a10', 'c12']}
                        onChange={handleProductNumberOnChange}
                        options={productNumberOption}
                      />

                      {/*<Input placeholder={"Enter Product Number"} />*/}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"productName"}
                      label={"Product Name"}
                      rules={[
                        {
                          required: true,
                          message: "Product Name cannot be empty",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{width: '100%'}}
                        placeholder="Please select"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        // defaultValue={['a10', 'c12']}
                        // onChange={handleChange}
                        disabled
                        options={productNameOption}
                      />
                      {/*<Input placeholder={"Enter Product Name"} disabled />*/}
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={12}>
                    <Form.Item
                      name={"productGroupId"}
                      label={"Product Group (if any)"}
                    >
                      <ProductGroupSelect
                        subCategoryId={subCategoryId}
                        placeholder={"Enter Product Group"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"exportMarketId"}
                      label={"Export Market"}
                      rules={[
                        {
                          required: true,
                          message: "Export Market cannot be empty",
                        },
                      ]}
                    >
                      <ExportMarketSelect placeholder={"Enter Export Market"}/>
                    </Form.Item>
                  </Col>
                </Row>

                {taskType === 'cpcGccReview' ?
                  <Row justify={"space-between"} gutter={[8, 8]}>
                    <Col span={12}>
                      <Form.Item
                        name={"testedAgeGrade"}
                        label={"Tested Age Grade"}
                        rules={[
                          {
                            required: true,
                            message: "Tested Age Grade cannot be empty",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter Tested Age Grade Name"}/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={"labelledAgeGrade"}
                        label={"Labelled Age Grade"}
                        rules={[
                          {
                            required: true,
                            message: "Labelled Age Grade cannot be empty",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter Labelled Age Grade Name"}/>
                      </Form.Item>
                    </Col>
                  </Row> :
                  <Row justify={"space-between"} gutter={[8, 8]}>
                    <Col span={12}>
                      <Form.Item
                        name={"nrtlCertNumber"}
                        label={"NRTL cert number"}
                        rules={[
                          {
                            required: true,
                            message: "NRTL cert number cannot be empty",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter NRTL cert number"}/>
                      </Form.Item>
                    </Col>
                  </Row>

                }

                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={12}>
                    <Form.Item name={"upc"}
                               label={"UPC"}
                               style={{
                                 marginBottom: "1px"
                               }}
                    >
                      <Input disabled/>
                    </Form.Item>

                    <Form.Item name={"additionalUpc"}
                      // label={"UPC"}
                               rules={[
                                 {
                                   required: !hasUpc,
                                   message: "UPC cannot be empty",
                                 },
                               ]}
                    >
                      <Input placeholder={"Enter Additional UPC"}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      required
                      name={"countryOfOrigin"}
                      label={"Country of Origin"}
                      rules={[
                        {
                          required: true,
                          message: "Country of Origin cannot be empty",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Please select country of origin"
                        options={getData()
                          .sort((a: any, b: any) =>
                            a?.name.localeCompare(b?.name)
                          )
                          .map(({name}) => ({
                            value: name,
                            label: name,
                          }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name={"productDescription"}
                      label={"Product Description"}
                      rules={[
                        {
                          required: true,
                          message: "Product description can not be empty",
                        },
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{minRows: 4}}
                        placeholder="Enter product description"
                      ></Input.TextArea>
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name={"productKeywords"}
                      label={"Product Keywords"}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Product keywords can not be empty",
                      //   },
                      // ]}
                    >
                      <Input.TextArea
                        autoSize={{minRows: 4}}
                        placeholder="Enter product description"
                      ></Input.TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ),
          },
          {
            title: "Product Image",
            content: (
              <Row justify={"space-between"} gutter={[8, 8]}>
                <Container span={24}>
                  <Form.Item
                    name={"productImages"}
                    label={"Product Picture"}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        required: true,
                        message: "Product Picture can not be empty",
                      },
                    ]}
                  >
                    <Upload.Dragger listType="picture-card" {...uploadProps}>
                      <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined/>
                      </p>
                      <p className="ant-upload-text">
                        Drop your files here, or Browse
                      </p>
                      <p className="ant-upload-hint">Support: JPG, JPEG, PNG</p>
                    </Upload.Dragger>
                  </Form.Item>
                </Container>
              </Row>
            ),
          },
          {
            title: "Done",
            content: (
              <div style={{textAlign: "center"}}>
                <div> {t("result:createProductTitle")}</div>
                <div style={{marginBottom: 15, color: "#4E5A71"}}>
                  {t("result:createProductSuccessMessage")}
                </div>
                <Image
                  preview={false}
                  style={{
                    margin: "20px auto",
                    width: "250px",
                    height: "100%",
                  }}
                  src={`/Product is created.png`}
                />
              </div>
            ),
          },
        ]}
        formProps={{
          name: "createProductionForm",
          onValuesChange: (changedValue) => {
            handleFormValuesChange(changedValue);
          },
          onFinish: () => {
            createProduct({...createValues});
          },
          layout: "vertical",
          initialValues: {
            serviceRequired: ServiceRequiredOptions[0].value,
            // supplierTags: [],
          },
        }}
      ></FormWithStepper>
    </Modal>
  );
});
