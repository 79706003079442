import {EnumFieldType, IField,} from "components/Molecules/form/FormRenderer/type";
import {get} from "lodash";
import { default as StyledSelect } from "components/Atoms/select";
import {REJECT_REASON} from "../../constants";
import Tag from "../../../../../../components/Atoms/tag";
import React from "react";
import {Form} from "antd";
import {useStaticSupplierTags} from "../../../../../../context/static-data-context";

export const ComplianceRejectionFields: Array<IField> = [
  // {
  //   type: EnumFieldType.SELECT,
  //   label: "Reject Reason",
  //   name: "rejectReason",
  //   placeholder: "Select Reject Reason",
  //   options: REJECT_REASON,
  // },
  // {
  //   type: EnumFieldType.LIST,
  //   label: "Request Test Items",
  //   name: "requestTestItems",
  //   rules: [
  //     {
  //       type: "array",
  //       required: true,
  //     },
  //   ],
  //   showWhen: (field, value, formValue) => {
  //     const rejectReason = get(formValue, "rejectReason");
  //     return rejectReason?.value === "additionalTest";
  //   },
  //   renderItem(record, index, operation, field, form) {
  //     /**
  //      * TODO: Finding the way to config instead of renderItem.
  //      * Move This one To Field and make renderItem is option if changing it to optional
  //      *
  //      */
  //
  //     return (
  //       <Form.Item
  //         rules={[
  //           {
  //             required: true,
  //             message: "This field was required",
  //           },
  //         ]}
  //         style={{ width: "100%", marginBottom: 0 }}
  //         name={[index, "testItem"]}
  //       >
  //         <List.Item
  //           extra={
  //             <Button
  //               danger
  //               onClick={() => {
  //                 operation.remove(index);
  //               }}
  //               type="text"
  //               icon={<DeleteOutlined />}
  //             ></Button>
  //           }
  //         >
  //           <Input></Input>
  //         </List.Item>
  //       </Form.Item>
  //     );
  //   },
  //   renderAddButton: (operation) => {
  //     return (
  //       <Button
  //         onClick={() => {
  //           operation.add({ testItem: "" });
  //         }}
  //         type="link"
  //         icon={<PlusCircleFilled />}
  //       >
  //         Add test Item
  //       </Button>
  //     );
  //   },
  // },
  // {
  //   type: EnumFieldType.TEXTAREA,
  //   label: "Remarks(Optional)",
  //   name: "remark",
  //   placeholder: "Need to revise",
  //   showWhen: (field, value, formValue) => {
  //     const rejectReason = get(formValue, "rejectReason");
  //     return rejectReason?.value === "revise";
  //   },
  //   autoSize: {
  //     minRows: 5,
  //     maxRows: 5,
  //   },
  // },
  // {
  //   type: EnumFieldType.DERIVED_TEXT,
  //   label: "Remarks(Optional)",
  //   name: "test",
  //   placeholder: "Enter here"
  //   // showWhen: (field, value, formValue) => {
  //   //   const rejectReason = get(formValue, "rejectReason");
  //   //   return rejectReason?.value === "others";
  //   // },
  //   // autoSize: {
  //   //   minRows: 5,
  //   //   maxRows: 5,
  //   // },
  // },
  {
    type: EnumFieldType.TEXTAREA,
    label: "Reject Reason Remarks",
    name: "remark",
    required: true,
    placeholder: "Enter here",
    // showWhen: (field, value, formValue) => {
    //   const rejectReason = get(formValue, "rejectReason");
    //   return rejectReason?.value === "others";
    // },
    autoSize: {
      minRows: 5,
      maxRows: 5,
    },
  },
  // {
  //   type: EnumFieldType.UPLOAD,
  //   label: "Additional Test Quotation",
  //   name: "testQuotations",
  //   rules: [
  //     {
  //       type: "array",
  //       required: true,
  //       validator: (_, value) => {
  //         if (Array.isArray(value) && value.length) return Promise.resolve();
  //         return Promise.reject("This field was required");
  //       },
  //     },
  //   ],
  //   showWhen: (field, value, formValue) => {
  //     const rejectReason = get(formValue, "rejectReason");
  //     return rejectReason?.value === "additionalTest";
  //   },
  //   multiple: false,
  //   listType: "picture",
  //   uploadButton: "Upload",
  // },
  {
    type: EnumFieldType.UPLOAD,
    label: "Additional Document (Optional)",
    name: "additionalDocuments",
    // showWhen: (field, value, formValue) => {
    //   const rejectReason = get(formValue, "rejectReason");
    //   return !!rejectReason?.value;
    // },
    multiple: true,
    listType: "picture",
    uploadButton: "Upload",
  },
];
