import { Carousel, Result, Space, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import styled from "@emotion/styled";
import Slide1 from "assets/backgrounds/introductions/slide1.png";
import Slide2 from "assets/backgrounds/introductions/slide2.png";
import Slide3 from "assets/backgrounds/introductions/slide3.png";
import Slide4 from "assets/backgrounds/introductions/slide4.png";

const IntroductionImages = [
  {
    key: "intro-1",
    img: Slide1,
    title: "Product creation",
    description: `You can start creating your own product for compliance review using "Create product" button on the top right.`,
  },
  {
    key: "intro-2",
    img: Slide2,
    title: "Online payment and document upload",
    description:
      "To proceed with the compliance review, you will need to make an online payment, and then upload product documents for reviewer to check.",
  },
  {
    key: "intro-3",
    img: Slide3,
    title: "Review request submission and result returned",
    description:
      "After submitted your product compliance review request, you will get compliance check result and feedback. E-sign may also be required for conformity certificate if necessary.",
  },
  {
    key: "intro-4",
    img: Slide4,
    title: "Compliance check completed and conformity certificate generation",
    description:
      "You will finally get a conformity certificate for your product.",
  },
];
const StyledModal = styled(Modal)`
  button[aria-label="Close"] {
    svg {
      font-size: 20px;
    }
  }
  .ant-modal-footer {
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  .ant-result {
    min-height: 120px;
    padding-top: 0;
    padding-bottom: 0;
    .ant-result-icon {
      margin-bottom: 0;
    }
    .ant-result-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0.015em;
      text-align: center;
    }
    .ant-result-subtitle {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.002em;
      text-align: center;
    }
  }
  ul.slick-dots {
    li {
      background: #e5efff;
      border-radius: 4%;
      width: 4px;
      height: 4px;
    }
    li.slick-active {
      border-radius: 4%;
      width: 12px;
      height: 4px;
      button {
        background: #005eff !important;
      }
    }
  }
`;

const ContentSlider = styled.div`
  margin-top: 20px;
  width: 370px;
  height: 100%; // Adjust your height
  color: #fff;
  line-height: 160px;
  text-align: center;
  img {
    margin: auto;
    width: 100%;
  }
`;

const ResultDescriptions = styled(Result)`
  .ant-result-icon {
    display: none;
  }
  margin-bottom: 68px;
`;
export const IntroductionModal = ({ visible, onCancel }) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const sliderRef = React.useRef();

  const onBack = () => {
    if (sliderRef.current) {
      (sliderRef.current as any).prev();
    }
  };
  const onNext = () => {
    if (sliderRef.current) {
      (sliderRef.current as any).next();
    }
  };
  return (
    <StyledModal
      closeIcon={<></>}
      title="Welcome to e-CPSR"
      visible={visible}
      onCancel={onCancel}
      footer={
        <div>
          {activeSlide < IntroductionImages.length - 1 ? (
            <Space>
              {activeSlide > 0 && (
                <Button onClick={onBack} type="link">
                  Back
                </Button>
              )}
              <Button onClick={onNext} type="primary">
                Next
              </Button>
            </Space>
          ) : (
            <Space>
              <Button onClick={onCancel} type="primary">
                Try Now
              </Button>
            </Space>
          )}

          {activeSlide < IntroductionImages.length - 1 ? (
            <Button
              type="text"
              onClick={() => {
                onCancel();
              }}
            >
              Skip
            </Button>
          ) : null}
        </div>
      }
      onOk={() => {
        onCancel();
      }}
      cancelButtonProps={{ hidden: true }}
    >
      <Carousel
        ref={sliderRef}
        draggable
        beforeChange={(currentSlide, toSlide) => {
          setActiveSlide(toSlide);
        }}
        dotPosition="bottom"
      >
        {IntroductionImages.map(({ img }, idx) => {
          return (
            <ContentSlider style={{ background: img }} key={idx}>
              <img src={img} alt={`introduction-${idx}`}></img>
            </ContentSlider>
          );
        })}
      </Carousel>
      <ResultDescriptions
        title={IntroductionImages[activeSlide]?.title}
        subTitle={IntroductionImages[activeSlide]?.description}
      ></ResultDescriptions>
    </StyledModal>
  );
};
