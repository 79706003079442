import { useProductDetail } from "pages/Merchant/ProjectPage/api";
import {
  useUpdateTaskCompliance,
  useTaskCompliance,
} from "pages/Merchant/UploadDocument/api";
import React from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "utils";
import { StaticDataContext } from "./static-data-context";
import isEmpty from "lodash/isEmpty";
import {
  UPLOAD_DOCUMENTS_PRODUCT_MAPPINGS,
  UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS,
} from "configs/Mappings";
import { parser, reverse } from "configs/parser";
import { useAuth } from "./auth-context";
import { COMPLIANCE_CHECK_MAPPINGS } from "configs/Mappings/complianceCheckParser";
import { useUploadFile } from "components/Organisms/uploads/api";
import { useHttp } from "utils/http";
import { omit } from "lodash";
import { UploadFile } from "antd";

type IReject = {
  rejectReason: string;
  remark?: string;
  testItems?: Array<string>;
  additionDocuments?: UploadFile[] | any[];
  testQuotations?: UploadFile[] | any[];
};

type IComplianceContext = {
  isFetchingProduct: boolean;
  isFetchingCompliance: boolean;
  isFetched: boolean;
  isUpdateLoading?: boolean;
  compliance: any;
  product: any;
  refetchProduct?: () => void;
  refetchCompliance?: () => void;
  saveChange: (values: any, isDraft: boolean) => void;
  submitCompliance: (
    values: any,
    extra: {
      status: "completed" | "rejected";
      expiryDate?: string;
      reject?: IReject;
    }
  ) => Promise<any>;
  taskId: string;
  id: string;
};

const ComplianceContext = React.createContext<IComplianceContext>(null);

const ComplianceProvider = ({ children }) => {
  const { user } = useAuth();
  const { mutate: upload, isLoading: uploading } = useUploadFile();
  const http = useHttp();
  const { ageGrade, serviceRequired, rejectTypes } = React.useContext(StaticDataContext);
  const navigateParams = useParams();
  const { mutate, isLoading: isUpdateLoading } = useUpdateTaskCompliance();

  const [parsedProduct, setParedProduct] = React.useState(null);
  const [parsedCompliance, setParsedCompliance] = React.useState(null);
  const [params] = React.useState({
    taskId: navigateParams.taskId,
    id: navigateParams.id,
  });
  const [isFetched, setIsFetched] = React.useState(false);

  const {
    isLoading: isFetchingProduct,
    data: productDetail,
    retry: refetchProduct,
  } = useProductDetail(useDebounce(params, 250));

  const {
    isLoading: isFetchingCompliance,
    data: complianceDetail,
    retry: refetchComplianceDetail,
  } = useTaskCompliance(useDebounce(params, 250));

  const fetchFile = async (fileUUID: string) => {
    return await http(`files/${fileUUID}`, {});
  };

  const parseCompliance = async () => {
    const extra = {
      ageGrade,
      serviceRequired,
      fetchDocument: fetchFile,
    };
    const mappings =
      user?.userRole === "reviewer_user"
        ? COMPLIANCE_CHECK_MAPPINGS
        : UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS;

    const parsedCompliance = await parser(complianceDetail, mappings, extra);

    setParsedCompliance({ ...parsedCompliance });
  };

  const parseProduct = async () => {
    const extra = {
      ageGrade,
      serviceRequired,
    };

    const parsedProductDetail = await parser(
      productDetail,
      UPLOAD_DOCUMENTS_PRODUCT_MAPPINGS,
      extra
    );
    setParedProduct({ ...parsedProductDetail });
  };

  const initialized = async () => {
    if (!isFetched && !isEmpty(parsedProduct) && !isEmpty(parsedCompliance)) {
      setTimeout(() => {
        setIsFetched(true);
      }, 250);
    }
  };

  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res: File = await upload(formData);
      return res;
    } catch (err) {
      const error = new Error("Some error");
      throw error;
    }
  };
  const uploadSectionType = (type) => async (files) => {
    const tasks = files.map(async (file) => {
      if (file.originFileObj) {
        const uploaded: {
          uuid: string;
          fileName: string;
          size: number;
          mimetype: string;
        } = (await uploadFile(file.originFileObj)) as any;

        return {
          type,
          uuid: uploaded.uuid,
          fileName: uploaded.fileName,
          mimetype: uploaded.mimetype,
          size: uploaded.size,
        };
      }
      return Promise.resolve({ ...file, type });
    });
    return Promise.all(tasks);
  };

  const transformExtraData = async (extraData) => {
    if (extraData.status !== "rejected")
      return {
        ...extraData,
      };
    const {
      reject,
    }: {
      reject: IReject;
    } = extraData;
    let additionalFiles = [];
    let testQuotations = [];
    if (Array.isArray(reject?.additionDocuments)) {
      additionalFiles = await uploadSectionType("reject_reason")(
        reject?.additionDocuments || []
      );
    }
    if (Array.isArray(reject?.testQuotations)) {
      testQuotations = await uploadSectionType("test_quotation")(
        reject?.testQuotations || []
      );
    }
    return {
      ...extraData,
      reject: {
        ...omit(reject, ["additionDocuments", "testQuotations"]),
        additionalFiles: additionalFiles.concat(testQuotations),
      },
    };
  };

  const uploadAllFilesBySection = async (complianceData) => {
    const uploadedTestPlan = await uploadSectionType("test_plan")(
      complianceData.testPlan || []
    );
    const uploadedTestReport = await uploadSectionType("test_report")(
      complianceData.testReport || []
    );

    const uploadedReviewerTestReport = await uploadSectionType("test_report")(
      complianceData.reviewerTestReportDocuments || []
    );

    const uploadedConformity = await uploadSectionType(
      "conformity_certificate"
    )(complianceData.conformityCertificate || []);

    const uploadedReviewerConformity = await uploadSectionType(
      "conformity_certificate"
    )(complianceData.reviewerConformityDocuments || []);

    const uploadedProductImage = await uploadSectionType("product_image")(
      complianceData.productImages || []
    );

    const uploadedReviewerProductImage = await uploadSectionType(
      "product_image"
    )(complianceData.reviewerProductImageDocuments || []);

    const uploadedProductSpecificationLabellingArtworkPackagingArtwork = await uploadSectionType("product_specification_labelling_artwork_packaging_artwork")(
      complianceData.productSpecificationLabellingArtworkPackagingArtwork || []
    );

    const uploadedReviewerProductSpecificationLabellingArtworkPackagingArtwork = await uploadSectionType(
      "product_specification_labelling_artwork_packaging_artwork"
    )(complianceData.reviewerProductSpecificationLabellingArtworkPackagingArtwork || []);

    return {
      uploadedTestPlan,
      uploadedTestReport,
      uploadedReviewerTestReport,
      uploadedConformity,
      uploadedReviewerConformity,
      uploadedProductImage,
      uploadedReviewerProductImage,
      uploadedProductSpecificationLabellingArtworkPackagingArtwork,
      uploadedReviewerProductSpecificationLabellingArtworkPackagingArtwork
    };
  };

  const handleSaveChange = async (values: any, isDraft: boolean) => {
    const extra = {
      ageGrade,
      serviceRequired,
    };
    const mappings =
      user?.userRole === "reviewer_user"
        ? COMPLIANCE_CHECK_MAPPINGS
        : UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS;

    const complianceData = await reverse(values, mappings, extra);

    const {
      uploadedTestPlan,
      uploadedTestReport,
      uploadedReviewerTestReport,
      uploadedConformity,
      uploadedReviewerConformity,
      uploadedProductImage,
      uploadedReviewerProductImage,
      uploadedProductSpecificationLabellingArtworkPackagingArtwork,
      uploadedReviewerProductSpecificationLabellingArtworkPackagingArtwork,
    } = await uploadAllFilesBySection(complianceData);

    await mutate({
      params: {
        ...omit(complianceData, [
          "testPlan",
          "testReport",
          "reviewerConformityDocuments",
          "reviewerTestReportDocuments",
        ]),
        files: []
          .concat(uploadedTestPlan)
          .concat(uploadedTestReport)
          .concat(uploadedConformity)
          .concat(uploadedReviewerConformity)
          .concat(uploadedReviewerTestReport)
          .concat(uploadedProductImage)
          .concat(uploadedReviewerProductImage)
          .concat(uploadedProductSpecificationLabellingArtworkPackagingArtwork)
          .concat(uploadedReviewerProductSpecificationLabellingArtworkPackagingArtwork),
        status: productDetail.status || "waiting_for_review",
        isDrafted: isDraft,
        is_esign_flow: false,
        testLines: {
          ...complianceDetail.testLines,
          ...complianceData.testLines,
        },
      },
      taskId: navigateParams.taskId || "",
    });
  };

  const submitCompliance = async (
    values: any,
    extra: {
      status: "completed" | "rejected";
      expiryDate?: string;
      reject?: {
        rejectReason: string;
        remark?: string;
        requestTestItem?: Array<string>;
        additionDocuments?: Array<UploadFile>;
      };
    }
  ) => {
    const complianceData = await reverse(values, COMPLIANCE_CHECK_MAPPINGS, {
      ageGrade,
      serviceRequired,
    });

    const {
      uploadedTestPlan,
      uploadedTestReport,
      uploadedReviewerTestReport,
      uploadedConformity,
      uploadedReviewerConformity,
      uploadedProductImage,
      uploadedReviewerProductImage,
      uploadedProductSpecificationLabellingArtworkPackagingArtwork,
      uploadedReviewerProductSpecificationLabellingArtworkPackagingArtwork,
    } = await uploadAllFilesBySection(complianceData);

    const extraData = await transformExtraData(extra);

    await mutate({
      params: {
        ...omit(complianceData, [
          "testPlan",
          "testReport",
          "reviewerConformityDocuments",
          "reviewerTestReportDocuments",
        ]),
        files: []
          .concat(uploadedTestPlan)
          .concat(uploadedTestReport)
          .concat(uploadedConformity)
          .concat(uploadedReviewerConformity)
          .concat(uploadedReviewerTestReport)
          .concat(uploadedProductImage)
          .concat(uploadedReviewerProductImage)
          .concat(uploadedProductSpecificationLabellingArtworkPackagingArtwork)
          .concat(uploadedReviewerProductSpecificationLabellingArtworkPackagingArtwork),
        isDrafted: false,
        is_esign_flow: false,
        testLines: {
          ...complianceDetail.testLines,
          ...complianceData.testLines,
        },
        ...extraData,
      },
      taskId: navigateParams.taskId || "",
    });
  };

  React.useEffect(() => {
    parseProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail]);

  React.useEffect(() => {
    parseCompliance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceDetail]);

  React.useEffect(() => {
    if (!isFetched) {
      initialized();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedProduct, parsedCompliance]);

  return (
    <ComplianceContext.Provider
      value={{
        isFetchingProduct,
        isFetchingCompliance,
        isFetched,
        isUpdateLoading: uploading || isUpdateLoading,
        compliance: parsedCompliance,
        product: parsedProduct,
        refetchProduct: refetchProduct,
        refetchCompliance: refetchComplianceDetail,
        saveChange: handleSaveChange,
        submitCompliance,
        ...params,
      }}
    >
      {children}
    </ComplianceContext.Provider>
  );
};

export const useComplianceContext = () => {
  const context = React.useContext(ComplianceContext);
  return context;
};

export default ComplianceProvider;
