import Countries from "./countries.min.json";
import Flags from "./countries.emoji.min.json";
import get from "lodash/get";

export const getCountriesWithFlags = () => {
  return Object.keys(Countries).reduce((acc, country) => {
    const data = get(Countries, [country]);
    const flag = get(Flags, [country]);
    acc.push({ ...data, flag });
    return acc;
  }, []);
};
