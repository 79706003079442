import React from "react";
import NormalTable from "components/Organisms/tables/normal-table";
import { getReviewColumnDef } from "../../constants";
import { useGetColumnSearchProps } from "components/Organisms/tables/table-hook";
import { useNavigate } from "react-router-dom";
import {Input} from "antd";

const { Search } = Input;
export const ReviewTable = ({
  dataSource,
  filters = {},
  defaultFilteredValue = {},
  onSearch,
  ...tableProps
}: any) => {
  const navigate = useNavigate();
  const [getColumnSearchProps] = useGetColumnSearchProps();
  const columns = getReviewColumnDef(
    navigate,
    getColumnSearchProps,
    defaultFilteredValue,
    filters
  );

  // const handleOnSearch = (keywords) => {
  //   onSearch(keywords)
  //   // tableProps.onChange();
  // }

  return (
    <>
      {
        onSearch &&
        <Search placeholder="Item number / description / supplier / task number" allowClear onSearch={onSearch} style={{ width: 400, margin: "0px 0px 10px 0px" }} />

      }
      <NormalTable
        {...tableProps}
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.id}
      ></NormalTable>
    </>

  );
};
