import React from "react";
import { Layout } from "../components/Layout";
import { StyledTitle } from "../components/Title";
import styled from "@emotion/styled";
import {
  Button,
  Result,
  Space,
  Form,
  Input,
  Typography,
  Divider,
  notification,
  FormProps,
} from "antd";
import { useNavigate } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import SiderBG from "assets/backgrounds/authorization-sider-sign-up-bg.png";
import ECSPR from "assets/logo/e-cpsr-logo.svg";
import ICWPoweredBy from "assets/logo/icw-powered-by-logo.svg";
import "./ForgotPassword.less";
import { useAuth } from "context/auth-context";
import { FORGOT_PASSWORD } from "configs/features";
import { css } from "@emotion/react";

const Container = styled.div<any>`
  display: flex;
  justify-content: center;
  algin-items: center;
  flex-direction: column;
  form {
    align-self: center;
    min-width: 410px;
    max-width: 400px;
  }

  height: 100vh;
  ${(prop: any) =>
    prop.bgImage
      ? css`
          background: url(${prop.bgImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : ""}
`;

const Sider = styled.div`
  padding: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: #ebedef;
      font-size: 40px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: 0.01em;
      text-align: center;
    }
    div.ant-typography {
      max-width: 315px;
      color: #ebedef;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: center;
    }
    button {
      margin-top: 60px;
      width: 140px;
    }
  }
`;
const StyledFooter = styled(Space)`
  justify-content: flex-end;
`;

const StyledDivider = styled(Divider)`
  &.ant-divider-with-text {
    border-top-color: transparent;
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 400;
  }
`;
const StyledResult = styled(Result)`
  button {
    max-width: 410px;
  }
  .ant-result-subtitle {
    button {
      padding: 0;
      h3 {
        color: inherit;
      }
    }
  }
`;

const StyledForm = styled(Form, {
  shouldForwardProp: (prop) => !["extraStyle"].includes(prop),
})<
  FormProps & {
    extraStyle?: React.CSSProperties;
  }
>`
  > .title {
    margin-top: 0;
    margin-bottom: 0;
  }
  > .ant-divider {
    display: inline !important;
    margin: 12px 0;
  }
  ${(props: any) => props.extraStyle}
  // > div {
  //   &.ant-form-item:nth-of-type(2) {
  //     margin-bottom: 16px;
  //   }
  // }
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const Config = FORGOT_PASSWORD.walmart;

const ForgotPassword = (props: Partial<WithTranslation>) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const { forgotPassword } = useAuth();
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const navigate = useNavigate();
  const goToSignIn = React.useCallback(() => {
    navigate("/authorization/sign-in");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const translate = (key: string) => {
  //   return t && t(key);
  // };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      await forgotPassword(values.email);
      setSubmitSuccess(true);
    } catch (error) {
      notification.error({ message: (error as any).message });
    } finally {
      setIsLoading(false);
    }
  };

  const renderSider = React.useCallback(() => {
    return (
      <Sider>
        <Space align="start" size={30}>
          <img src={ECSPR} alt="e-cspr"></img>
        </Space>
        <div className="content">
          <Space direction="vertical" align="center" size={[10, 10]}>
            <Typography.Title>Welcome Back!</Typography.Title>
            <Typography.Paragraph>
              If you’re one of us, login with your email or LinkedIn account.
            </Typography.Paragraph>
          </Space>
          <Button onClick={goToSignIn} ghost size="large">
            Login
          </Button>
        </div>
        <StyledFooter align="end">
          <img src={ICWPoweredBy} alt="e-cspr"></img>
        </StyledFooter>
      </Sider>
    );
  }, [goToSignIn]);

  return (
    <Layout
      revert
      className={"container"}
      sider={
        Config.layout.sider
          ? { siderBg: SiderBG, renderSider: renderSider }
          : null
      }
    >
      <Container bgImage={Config.backgroundImage}>
        {/* <StyledTitle className={"title"}>Reset Password</StyledTitle> */}
        {submitSuccess ? (
          <>
            <StyledResult
              status="success"
              subTitle={
                <span>
                  <h3>
                    Didn’t receive your email?{" "}
                    <Button type="link">
                      <h3>Resend Now</h3>
                    </Button>
                  </h3>
                </span>
              }
              extra={[
                <Button
                  size="large"
                  onClick={goToSignIn}
                  block
                  type="primary"
                  key="console"
                >
                  Sign In
                </Button>,
              ]}
            />
          </>
        ) : (
          <StyledForm
            extraStyle={Config.form.style as any}
            form={form}
            name="forgot-password"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{ email: "" }}
          >
            <StyledTitle className={"title"}>Reset Password</StyledTitle>
            <StyledDivider>Enter your account email</StyledDivider>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The email is not valid",
                },
              ]}
            >
              <Input
                type="email"
                size="large"
                placeholder="Enter email"
              ></Input>
            </Form.Item>
            <Button
              loading={isLoading}
              size="large"
              block
              htmlType="submit"
              type="primary"
            >
              Reset my password
            </Button>
          </StyledForm>
        )}
      </Container>
    </Layout>
  );
};

export default withTranslation(["common", "register"])(ForgotPassword);
