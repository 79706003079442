import React from "react";
import { Select, SelectProps } from "antd";

interface CusSelectProps extends SelectProps {
  data: any;
}

export const IdSelect = (props: CusSelectProps) => {
  const { data } = props;
  return (
    <Select
      showSearch
      optionFilterProp="children"
      {...props}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA: any, optionB: any) => {
        const childrenA = (optionA.children || '').toString().toLowerCase();
        const childrenB = (optionB.children || '').toString().toLowerCase();
      
        return childrenA.localeCompare(childrenB);
      }}
    >
      {data?.map((option: any) => (
        <Select.Option key={option.id} value={option.id}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  );
};
