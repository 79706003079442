import { IdSelect } from "./id-select";
import { useSubCategories } from "./api";
import React from "react";
import { SelectProps } from "antd";
import { useDebounce } from "../../../utils";

interface SubcategorySelectProps extends SelectProps {
  categoryId: string;
}

export const SubCategorySelect = React.forwardRef(
  ({ categoryId, ...props }: SubcategorySelectProps, ref) => {
    const { data: subCategories, retry } = useSubCategories(
      useDebounce(categoryId, 100)
    );

    React.useEffect(() => {
      retry();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    return (
      <IdSelect disabled={!categoryId} data={subCategories || []} {...props} />
    );
  }
);
