import React from "react";
import { Button, Space } from "antd";
import QMASLogo from "assets/logo/qmas-logo.svg";
import styled from "@emotion/styled";

const StyledButtonLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.002em;
  text-align: left;
`;

const Container = styled.div`
  padding-top: 17px;
  padding-bottom: 17px;
  width: 410px;
  border: 1px solid #005eff;
  min-height: 76px;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 4px;
`;
const LoginWithQMAS = ({ onClick, label }: any) => {
  return (
    <Container>
      <Space direction="vertical" align="center" size={4}>
        <img src={QMASLogo} alt="qmas-logo"></img>
        <Button onClick={onClick} type="link">
          <StyledButtonLabel>{label}</StyledButtonLabel>
        </Button>
      </Space>
    </Container>
  );
};

export default LoginWithQMAS;
