import React from "react";
import styled from "@emotion/styled";
import { Button, Col, Row, Space, Divider, notification } from "antd";
import { get } from "lodash";
import { useEditProduct } from "pages/Merchant/api";
import { EditProductModal } from "pages/Merchant/components/edit-product-modal";
import { useFetchSubCategoriesAndProductGroup } from "../../../../pages/Merchant/UploadDocument/api";
import { useComplianceContext } from "context/Compliance";
import { Action, Resource } from "configs/rules";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  margin-bottom: 24px;
  background: #ffffff;
  border: 1px solid rgba(170, 170, 170, 0.3);
  border-radius: 16px;
  position: relative;
  h2,
  .title {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;

const InfoCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Wrapper = styled.div`
  color: #777777;
`;

const VerticalLine = styled(Divider)`
  border: 1px solid #e4e4e4;
  margin: 0px 10px 0px 10px !important;
`;

const Description = styled(Space)`
  > div:nth-of-type(2) {
    color: #232b3a;
    overflow-wrap: anywhere;
  }
`;
const rows = [
  {
    wrap: true,
    cols: [
      {
        title: "Product Name",
        dataIndex: "productName",
      },

      {
        title: "Tested Age Grade",
        dataIndex: "testedAgeGrade",
      },

      {
        title: "Labelled Age Grade",
        dataIndex: "labelledAgeGrade",
      },

      {
        title: "NRTL Cert Number",
        dataIndex: "nrtlCertNumber",
      },
      // {
      //   title: "Age Grade",
      //   dataIndex: "ageGrade.label",
      //   defaultValue: "N/A",
      // },
    ],
  },
  {
    wrap: true,
    cols: [
      {
        title: "Company",
        dataIndex: "merchant"
      },
      {
        title: "Suppliers",
        dataIndex: "supplierTags",
        render: (value) => {
          return Array.isArray(value) ? value.join(", ") : "N/A";
        },
      },
    ],
  },
  {
    wrap: true,
    cols: [
      {
        title: "Export Market",
        dataIndex: "exportMarket",
      },
      {
        title: "Product Category",
        dataIndex: "mainCategory",
      },
      {
        title: "Product Type Group",
        dataIndex: "category",
      },
      {
        title: "Product Type",
        dataIndex: "subcategory",
      },
      // {
      //   title: "Service Required",
      //   dataIndex: "serviceRequired",
      // },
      // {
      //   title: "ECD",
      //   dataIndex: "estimatedCompletionDate",
      //   defaultValue: "N/A",
      // },
    ],
  },
  {
    wrap: true,
    cols: [
      {
        title: "UPC",
        dataIndex: "upc",
        defaultValue: "N/A",
      },
      {
        title: "Country of Origin",
        dataIndex: "countryOfOrigin",
        defaultValue: "N/A",
      },
    ],
  },
  {
    wrap: true,
    cols: [
      {
        title: "Product Keywords",
        dataIndex: "productKeywords",
      },
      {
        title: "Product Description",
        dataIndex: "productDescription",
      },
    ],
  },
];

const ProductDescription = ({
  value,
  canActivate,
  ...props
}: {
  value?: any;
  canActivate?: (resource: string, action: string) => boolean;
}) => {
  const { refetchProduct } = useComplianceContext();
  const [editModal, setEditModal] = React.useState({
    open: false,
    initialValues: {
      ...value,
    },
  });

  const [
    productCategories,
    subCategories,
    productGroups,
    exportMarkets,
  ] = useFetchSubCategoriesAndProductGroup(
    value?.category ?? "",
    value?.subcategory ?? ""
  );

  const { mutate: editProductMutation, isLoading: isUpdating } = useEditProduct(
    value.id as string
  );

  const onEditTestLine = () => {
    setEditModal({
      open: true,
      initialValues: {
        ...value,
      },
    });
  };

  const canEdit = canActivate
    ? canActivate(`${Resource.JOBS}.form.product`, Action.CREATE)
    : true;

  const editProduct = React.useMemo(() => {
    return {
      ...value,
      categoryId: (productCategories || []).find(
        ({ name }) => name === value?.category
      )?.id,
      subcategoryId: (subCategories || []).find(
        ({ name }) => name === value?.subcategory
      )?.id,
      productGroupId: (productGroups || []).find(
        ({ name }) => name === value?.productGroup
      )?.id,
      supplierTags: value?.supplierTags?.map((value: any) => ({
        value,
        label: value,
      })),

      exportMarketId: (exportMarkets || []).find(
        ({ name }) => name === value?.exportMarket
      )?.id,
    };
  }, [value, subCategories, productGroups]);

  const handleEditProduct = async (values: Record<string, any>) => {
    try {
      debugger;
      await editProductMutation({
        ...values,
        ageGrade: values?.ageGrade?.value || values?.ageGrade || null,
        supplierTags: (values.supplierTags || []).map(
          ({ value }: any) => value
        ),
      } as any);

      refetchProduct();
      setEditModal({ ...editModal, open: false });
    } catch (error) {
      notification.error((error as any).message);
    }
  };

  return (
    <section>
      <Container>
        <InfoCardHeader>
          <Space>
            <h2>Product Number:</h2>
            <h2  style={{overflowWrap: "anywhere"}}>{value?.productNumber}</h2>
          </Space>
          {/*{canEdit ? (*/}
          {/*  <Button*/}
          {/*    hidden={value?.status === "completed"}*/}
          {/*    onClick={onEditTestLine}*/}
          {/*    type="link"*/}
          {/*  >*/}
          {/*    Edit*/}
          {/*  </Button>*/}
          {/*) : null}*/}
        </InfoCardHeader>
        <Wrapper>
          {rows.map((row, rowIndex) => {
            const { cols, ...rest } = row as any;
            return (
              <Row key={rowIndex} {...(rest as any)}>
                {cols.map((col: any, colIndex) => {
                  return (
                    <Col key={`${col.dataIndex}_${colIndex}`}>
                      <Description>
                        <span>{col.title}:</span>
                        <span>
                          {col?.render
                            ? col.render(get(value, `${col.dataIndex}`))
                            : get(value, `${col.dataIndex}`) ||
                              col.defaultValue}
                        </span>
                      </Description>
                      {colIndex !== cols.length - 1 && (
                        <VerticalLine type={`vertical`} />
                      )}
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Wrapper>
      </Container>
      {/*<EditProductModal*/}
      {/*  open={editModal.open}*/}
      {/*  initialValues={{ ...editProduct }}*/}
      {/*  handleCloseModal={() => {*/}
      {/*    setEditModal({ ...editModal, open: false });*/}
      {/*  }}*/}
      {/*  isUpdating={isUpdating}*/}
      {/*  updateProduct={handleEditProduct}*/}
      {/*></EditProductModal>*/}
    </section>
  );
};

export default React.memo(ProductDescription);
