import React from "react";
import { css } from "@emotion/react";
import FullBGWalmart from "assets/walmart/bg/bg_full_blur.svg";
import WalMartLogo from "assets/walmart/logo/logo.png";
import ICWLogoColoured from "assets/logo/icw_logo_coloured.svg";
import {
  IField,
  EnumFieldType,
} from "components/Molecules/form/FormRenderer/type";
import { get, isEmpty, isObject, uniqueId } from "lodash";

export const SIGN_IN_CONFIGS = {
  walmart: {
    branchLogo: WalMartLogo,
    sologan: "eCommerce Merchandise Compliance Check Platform",
    backgroundImage: FullBGWalmart,
    layout: {
      sider: false,
      showECSPRLogo: false,
      showWelcome: false,
      showLoginWithQMAS: false,
      footer: {
        logo: ICWLogoColoured,
      },
    },
    form: {
      style: css`
        display: flex;
        flex-direction: column;
        gap: 26px;
        width: 400px;
        height: 565px;
        background: #ffff;
        padding: 50px 30px;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        .ant-divider {
          display: none;
        }
      `,
    },
  },
};

export const FORGOT_PASSWORD = {
  walmart: {
    branchLogo: WalMartLogo,
    sologan: "eCommerce Merchandise Compliance Check Platform",
    backgroundImage: FullBGWalmart,
    layout: {
      sider: false,
      showECSPRLogo: false,
      showWelcome: false,
      showLoginWithQMAS: false,
      footer: {
        logo: ICWLogoColoured,
      },
    },
    form: {
      style: css`
        display: flex;
        flex-direction: column;
        gap: 26px;
        width: 400px;
        // height: 565px;
        background: #ffff;
        padding: 50px 30px;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        .ant-divider {
          display: none;
        }
      `,
    },
  },
};
const creteRemarkField = (rootName) => {
  return {
    type: EnumFieldType.TEXTAREA,
    label: "Remarks",
    name: Array.isArray(rootName)
      ? rootName.concat(["remarks"])
      : [rootName, "remarks"],
    autoSize: {
      minRows: 5,
      maxRows: 5,
    },
    col: {
      span: 12,
    },
  };
};

const createRemarkReadOnlyField = (rootName) => {
  return {
    type: EnumFieldType.DERIVED_TEXT,
    label: <h3 style={{ marginBottom: 0 }}>Remarks</h3>,
    name: Array.isArray(rootName)
      ? rootName.concat(["remarks"])
      : [rootName, "remarks"],
    formatter: (field: IField, value) => {
      if (value) return value;
      return "N/A";
    },
  };
};

export const UPLOAD_DOCUMENTS_CONFIGS = {
  walmart: {
    sections: [
      {
        title: "",
        name: "product",
        sectionType: "product_description",
      },
      // {
      //   title: "Test Plan",
      //   name: null,
      //   sectionType: "test_plan",
      // },
      {
        title: "1. Product Images",
        name: "productImage",
        footNote: "",
        required: false,
        fields: [
          {
            fieldId: uniqueId("productImage"),
            ...creteRemarkField("productImage"),
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["productImage", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
            shouldHiddenRemove: (_, __, formValues) => {
              // console.log(__)
              if (["missing_documents"].includes(formValues?.product?.rejectedStatus )) {
                return true;
              }
              return false;
            },
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.SECTION,
            name: ["productImage", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["productImage", "reviewer"]),
                label: <h3>Reviewer Remarks</h3>,
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                type: EnumFieldType.TEXTAREA,
                readonly: true,
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["productImage", "reviewer", "isReuploadProductImage"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                readonly: true,
              },
            ],
          },
        ],
      },
      // {
      //   title: "2. Product and Packaging",
      //   name: "packaging",
      //   footNote: null,
      //   required: false,
      //   fields: [
      //     { fieldId: uniqueId("packaging"), ...creteRemarkField("packaging") },
      //   ],
      // },
      {
        title: "2. Test report / Assessment report",
        name: "testReport",
        footNote: "",
        required: false,
        fields: [
          {
            fieldId: uniqueId("testReport"),
            ...creteRemarkField("testReport"),
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["testReport", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
            shouldHiddenRemove: (_, __, formValues) => {
              // console.log(__)
              if (["missing_documents"].includes(formValues?.product?.rejectedStatus )) {
                return true;
              }
              return false;
            },
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.SECTION,
            name: ["testReport", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["testReport", "reviewer"]),
                label: <h3>Reviewer Remarks</h3>,
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                type: EnumFieldType.DERIVED_TEXT,
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["testReport", "reviewer", "isReuploadTestReport"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                readonly: true,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
        ],
      },
      // {
      //   title:
      //     "4. Product functions & features (e.g. name, model number, feature, special function, etc)",
      //   name: "brief",
      //   footNote: "",
      //   required: false,
      //   fields: [{ fieldId: uniqueId("brief"), ...creteRemarkField("brief") }],
      // },
      // {
      //   title:
      //     "5. List of components and materials used (Bill of materials / Bill of substances)",
      //   name: "materialsUsed",
      //   footNote: "",
      //   required: false,
      //   fields: [
      //     {
      //       fieldId: uniqueId("materialsUsed"),
      //       ...creteRemarkField("materialsUsed"),
      //     },
      //   ],
      // },
      // {
      //   title: "6. Circuit diagram",
      //   name: "circuitDiagram",
      //   footNote: "",
      //   required: false,
      //   fields: [
      //     {
      //       fieldId: uniqueId("circuitDiagram"),
      //       ...creteRemarkField("circuitDiagram"),
      //     },
      //   ],
      // },
      // {
      //   title: "7. Instruction manual",
      //   name: "instructionManual",
      //   footNote: "",
      //   required: false,
      //   fields: [
      //     {
      //       fieldId: uniqueId("instructionManual"),
      //       ...creteRemarkField("instructionManual"),
      //     },
      //   ],
      // },
      // {
      //   title: "8. Safety data sheet(s) for chemicals used",
      //   name: "safetyDataSheet",
      //   footNote: "",
      //   required: false,
      //   fields: [
      //     {
      //       fieldId: uniqueId("safetyDataSheet"),
      //       ...creteRemarkField("safetyDataSheet"),
      //     },
      //   ],
      // },
      // {
      //   title: "9. Ingredient list or formulation",
      //   name: "ingredientList",
      //   footNote: "",
      //   required: false,
      //   fields: [
      //     {
      //       fieldId: uniqueId("ingredientList"),
      //       ...creteRemarkField("ingredientList"),
      //     },
      //   ],
      // },
      // {
      //   title: "10. Manufacturer Information and Production Date Code",
      //   name: "declarationLetter",
      //   footNote: "",
      //   required: false,
      //   fields: [
      //     {
      //       fieldId: uniqueId("declarationLetter"),
      //       ...creteRemarkField("declarationLetter"),
      //     },
      //   ],
      // },
      {
        title: "3. General Product Certificate (GCC) / Children’s Product Certificate (CPC)",
        name: "conformityCertificate",
        footNote: "",
        required: false,
        fields: [
          // {
          //   fieldId: uniqueId("conformityCertificate"),
          //   name: ["conformityCertificate", "conformityCertificateSelection"],
          //   type: EnumFieldType.CHECKBOX,
          //   options: [
          //     {
          //       value: "conformity_certificate_cannot_provide",
          //       label: "No certificate can be provided",
          //     },
          //   ],
          //   shouldDisabled: (_, __, formValues) => {
          //     if (
          //       [
          //         "rejected_request_additional_test",
          //         "rejected_quote_accepted",
          //       ].includes(formValues?.product?.rejectedStatus)
          //       || formValues?.product?.isReUploadPayment
          //     )
          //       return true;
          //     return !["missing_documents", "rejected"].includes(
          //       formValues?.product?.status
          //     );
          //   },
          // },
          {
            fieldId: uniqueId("conformityCertificate"),
            ...creteRemarkField("conformityCertificate"),
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["conformityCertificate", "documents"],
            multiple: false,
            limit: 1,
            // cannotRemove: formValues?.conformityCertificate?.documents.length > 1,
            // readonly: formValues?.conformityCertificate?.documents.length > 1,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            rules: [
              (formInstance) => {
                return {
                  message: "Please upload the required document",
                  validator: (rule, value) => {
                    const conformityCertificateSelection =
                      formInstance.getFieldValue([
                        "conformityCertificate",
                        "conformityCertificateSelection",
                      ]) || [];

                    if (
                      conformityCertificateSelection.includes(
                        "conformity_certificate_cannot_provide"
                      )
                    )
                      return Promise.resolve();
                    return isEmpty(value)
                      ? Promise.reject()
                      : Promise.resolve();
                  },
                };
              },
              //
            ],
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
                // || formValues?.conformityCertificate?.documents?.length >= 1
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
            shouldHiddenRemove: (_, __, formValues) => {
              // console.log(__)
              if (["missing_documents"].includes(formValues?.product?.rejectedStatus )) {
                return true;
              }
              return false;
            },
            dependencies: [
              ["conformityCertificate", "conformityCertificateSelection"],
            ],
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.SECTION,
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("conformityCertificate"),
                type: EnumFieldType.CUSTOMIZED,
                dynamic: true,
                factoryType: "REPORT_DATA",
                name: ["conformityCertificate", "reviewer", "reportData"],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
              {
                fieldId: uniqueId("conformityCertificate"),
                ...creteRemarkField(["conformityCertificate", "reviewer"]),
                name: ["conformityCertificate", "reviewer", "remarks"],
                label: <h3>Reviewer Remarks</h3>,
                type: EnumFieldType.DERIVED_TEXT,
              },
            ],
          },
        ],
      },
      {
        title: "",
        name: "product",
        sectionType: "reject_reason",
        fields: [],
      },
    ],
  },
};

export const DOCUMENT_REVIEW_UPLOAD_DOCUMENTS_CONFIGS = {
  walmart: {
    sections: [
      {
        title: "",
        name: "product",
        sectionType: "product_description",
      },
      // {
      //   title: "Test Plan",
      //   name: null,
      //   sectionType: "test_plan",
      // },
      {
        title: "1. Product Images",
        name: "productImage",
        footNote: "",
        required: false,
        fields: [
          {
            fieldId: uniqueId("productImage"),
            ...creteRemarkField("productImage"),
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["productImage", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
            shouldHiddenRemove: (_, __, formValues) => {
              // console.log(__)
              if (["missing_documents"].includes(formValues?.product?.rejectedStatus )) {
                return true;
              }
              return false;
            },
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.SECTION,
            name: ["productImage", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["productImage", "reviewer"]),
                label: <h3>Reviewer Remarks</h3>,
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                type: EnumFieldType.TEXTAREA,
                readonly: true,
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["productImage", "reviewer", "isReuploadProductImage"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                readonly: true,
              },
            ],
          },
        ],
      },
      // {
      //   title: "2. Product and Packaging",
      //   name: "packaging",
      //   footNote: null,
      //   required: false,
      //   fields: [
      //     { fieldId: uniqueId("packaging"), ...creteRemarkField("packaging") },
      //   ],
      // },
      {
        title: "2. Product specification / Labelling artwork / Packaging artwork",
        name: "productSpecificationLabellingArtworkPackagingArtwork",
        footNote: "",
        required: false,
        fields: [
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            ...creteRemarkField("productSpecificationLabellingArtworkPackagingArtwork"),
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
          },
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["productSpecificationLabellingArtworkPackagingArtwork", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            rules: [
              (formInstance) => {
                return {
                  message: "Please upload the required document",
                  validator: (rule, value) => {
                    const conformityCertificateSelection =
                      formInstance.getFieldValue([
                        "productSpecificationLabellingArtworkPackagingArtwork",
                        "productSpecificationLabellingArtworkPackagingArtworkSelection",
                      ]) || [];

                    if (
                      conformityCertificateSelection.includes(
                        "conformity_certificate_cannot_provide"
                      )
                    )
                      return Promise.resolve();
                    return isEmpty(value)
                      ? Promise.reject()
                      : Promise.resolve();
                  },
                };
              },
              //
            ],
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
            shouldHiddenRemove: (_, __, formValues) => {
              // console.log(__)
              if (["missing_documents"].includes(formValues?.product?.rejectedStatus )) {
                return true;
              }
              return false;
            },
          },
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            type: EnumFieldType.SECTION,
            name: ["productSpecificationLabellingArtworkPackagingArtwork", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["productSpecificationLabellingArtworkPackagingArtwork", "reviewer"]),
                label: <h3>Reviewer Remarks</h3>,
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                type: EnumFieldType.DERIVED_TEXT,
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["productSpecificationLabellingArtworkPackagingArtwork", "reviewer", "isReuploadProductSpecificationLabellingArtworkPackagingArtwork"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                readonly: true,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
        ],
      },
      {
        title: "3. Test report / Assessment report / Certificate",
        name: "testReport",
        footNote: "",
        required: false,
        fields: [
          {
            fieldId: uniqueId("testReport"),
            ...creteRemarkField("testReport"),
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["testReport", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            rules: [
              (formInstance) => {
                return {
                  message: "Please upload the required document",
                  validator: (rule, value) => {
                    const conformityCertificateSelection =
                      formInstance.getFieldValue([
                        "testReport",
                        "testReportSelection",
                      ]) || [];

                    if (
                      conformityCertificateSelection.includes(
                        "conformity_certificate_cannot_provide"
                      )
                    )
                      return Promise.resolve();
                    return isEmpty(value)
                      ? Promise.reject()
                      : Promise.resolve();
                  },
                };
              },
              //
            ],
            shouldDisabled: (_, __, formValues) => {
              if (
                [
                  "rejected_request_additional_test",
                  "rejected_quote_accepted",
                ].includes(formValues?.product?.rejectedStatus)
                || formValues?.product?.isReUploadPayment
              )
                return true;
              return !["missing_documents", "rejected"].includes(
                formValues?.product?.status
              );
            },
            shouldHiddenRemove: (_, __, formValues) => {
              // console.log(__)
              if (["missing_documents"].includes(formValues?.product?.rejectedStatus )) {
                return true;
              }
              return false;
            },
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.SECTION,
            name: ["testReport", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["testReport", "reviewer"]),
                label: <h3>Reviewer Remarks</h3>,
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                type: EnumFieldType.DERIVED_TEXT,
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["testReport", "reviewer", "isReuploadTestReport"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                readonly: true,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
        ],
      },
      {
        title: "",
        name: "product",
        sectionType: "reject_reason",
        fields: [],
      },
    ],
  },
};

export const COMPLIANCE_CHECK = {
  walmart: {
    sections: [
      {
        title: "",
        name: "product",
        sectionType: "product_description",
      },
      // {
      //   title: "Test Plan",
      //   name: "testPlan",
      //   sectionType: "reviewer_test_plan",
      //   fields: [
      //     {
      //       fieldId: uniqueId("testPlan"),
      //       type: EnumFieldType.UPLOAD,
      //       shouldDisabled: (_, __, formValues) => {
      //         return ["completed"].includes(formValues?.product?.status);
      //       },
      //       name: ["testPlan", "documents"],
      //       multiple: true,
      //       listType: "picture",
      //       uploadButton: "Upload document",
      //       col: {
      //         span: 12,
      //       },
      //     },
      //   ],
      // },
      {
        title: "1. Product Images",
        name: "productImage",
        footNote: "",
        required: false,
        fields: [
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.UPLOAD,
            name: ["productImage", "documents"],
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            readonly: true,
            col: {
              span: 12,
            },
          },
          {
            fieldId: uniqueId("productImage"),
            ...createRemarkReadOnlyField("productImage"),
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.SECTION,
            name: ["productImage", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["productImage", "reviewer"]),
                label: "Reviewer Remarks",
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["productImage", "reviewer", "isReuploadProductImage"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["productImage", "reviewer", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            shouldDisabled: (_, __, formValues) => {
              return ["completed"].includes(formValues?.product?.status);
            },
            col: {
              span: 12,
            },
          },
        ],
      },
      {
        title: "2. Test report / Assessment report",
        name: "testReport",
        fields: [
          {
            fieldId: uniqueId("testPlan"),
            type: EnumFieldType.UPLOAD,
            name: ["testReport", "documents"],
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            readonly: true,
            col: {
              span: 12,
            },
          },
          {
            fieldId: uniqueId("testReport"),
            ...createRemarkReadOnlyField("testReport"),
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.SECTION,
            name: ["testReport", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["testReport", "reviewer"]),
                label: "Reviewer Remarks",
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["testReport", "reviewer", "isReuploadTestReport"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["testReport", "reviewer", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            shouldDisabled: (_, __, formValues) => {
              return ["completed"].includes(formValues?.product?.status);
            },
          },
        ],
      },
      {
        title:
          "3. General Product Certificate (GCC) / Children’s Product Certificate (CPC)",
        name: "conformityCertificate",
        fields: [
          {
            fieldId: uniqueId("conformityCertificate"),
            name: ["conformityCertificate", "conformityCertificateSelection"],
            type: EnumFieldType.DERIVED_TEXT,
            formatter: (_, value: any, __: any) => {
              // if (value === "conformity_certificate_cannot_provide") {
              //   return "No certificate can be provided";
              // }
              return "";
            },
            showWhen: (_, value: any, __) => {
              return value === "conformity_certificate_cannot_provide";
            },
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["conformityCertificate", "documents"],
            readonly: true,
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            ...createRemarkReadOnlyField("conformityCertificate"),
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.SECTION,
            title: <h1>Review section</h1>,
            fields: [
              // {
              //   fieldId: uniqueId("conformityCertificate"),
              //   type: EnumFieldType.RADIO,
              //   label: (
              //     <h3 style={{ marginBottom: 0 }}>Conformity Certificate</h3>
              //   ),

              //   name: [
              //     "conformityCertificate",
              //     "reviewer",
              //     "conformityCertificateSelection",
              //   ],
              //   options: [
              //     {
              //       value: "conformity_certificate_not_required",
              //       label: "Conformity Certificate Not Required",
              //       showWhen: () => false,
              //     },
              //     {
              //       value: "conformity_certificate_required",
              //       label: "Conformity Certificate Required",
              //     },
              //     {
              //       value: "merchant_uploaded",
              //       label: "Merchant Uploaded",
              //       showWhen: () => false,
              //     },
              //   ],
              // },
              // {
              //   fieldId: uniqueId("conformityCertificate"),
              //   type: EnumFieldType.SELECT,
              //   name: [
              //     "conformityCertificate",
              //     "reviewer",
              //     "conformityCertificateType",
              //   ],

              //   showWhen: (field, value, formValues) => {
              //     const conformitySelection = get(
              //       formValues,
              //       "conformityCertificate.reviewer.conformityCertificateSelection"
              //     );
              //     return (
              //       conformitySelection === "conformity_certificate_required"
              //     );
              //   },
              //   col: {
              //     span: 6,
              //   },
              //   options: [
              //     {
              //       value: "DOC",
              //       label: "DOC",
              //       showWhen: () => false,
              //     },
              //     {
              //       value: "CPC",
              //       label: "CPC",
              //       showWhen: () => false,
              //     },
              //     {
              //       value: "report-data",
              //       label: "Report Data",
              //     },
              //   ],
              // },
              {
                fieldId: uniqueId("conformityCertificate"),
                type: EnumFieldType.CUSTOMIZED,
                dynamic: true,
                factoryType: "REPORT_DATA",
                name: ["conformityCertificate", "reviewer", "reportData"],
                // rules: [
                //   {
                //     validator: (rule, value, formValues) => {
                //       if (!value.length)
                //         return Promise.reject(
                //           "Report Data should have at least 1 record."
                //         );
                //       return Promise.resolve();
                //     },
                //   },
                // ],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
                // showWhen: (field, value, formValues) => {
                //   const conformitySelection = get(
                //     formValues,
                //     "conformityCertificate.reviewer.conformityCertificateSelection"
                //   );
                //   const conformityType = get(
                //     formValues,
                //     "conformityCertificate.reviewer.conformityCertificateType"
                //   );
                //   const type = isObject(conformityType)
                //     ? (conformityType as any)?.value
                //     : conformityType;

                //   return (
                //     conformitySelection === "conformity_certificate_required" &&
                //     type === "report-data"
                //   );
                // },
              },
              {
                fieldId: uniqueId("conformityCertificate"),
                ...creteRemarkField(["conformityCertificate", "reviewer"]),
                name: ["conformityCertificate", "reviewer", "remarks"],
                label: "Reviewer Remarks",
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
              {
                fieldId: uniqueId("conformityCertificate"),
                type: EnumFieldType.UPLOAD,
                label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
                name: ["conformityCertificate", "reviewer", "documents"],
                multiple: true,
                listType: "picture",
                uploadButton: "Upload document",
                col: {
                  span: 12,
                },
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
        ],
      },
      {
        title: "",
        name: "rejectReasons",
        sectionType: "reject_reason",
        fields: [],
      },
    ],
  },
};

export const DOCUMENT_REVIEW_COMPLIANCE_CHECK = {
  walmart: {
    sections: [
      {
        title: "",
        name: "product",
        sectionType: "product_description",
      },
      // {
      //   title: "Test Plan",
      //   name: "testPlan",
      //   sectionType: "reviewer_test_plan",
      //   fields: [
      //     {
      //       fieldId: uniqueId("testPlan"),
      //       type: EnumFieldType.UPLOAD,
      //       shouldDisabled: (_, __, formValues) => {
      //         return ["completed"].includes(formValues?.product?.status);
      //       },
      //       name: ["testPlan", "documents"],
      //       multiple: true,
      //       listType: "picture",
      //       uploadButton: "Upload document",
      //       col: {
      //         span: 12,
      //       },
      //     },
      //   ],
      // },
      {
        title: "1. Product Images",
        name: "productImage",
        footNote: "",
        required: false,
        fields: [
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.UPLOAD,
            name: ["productImage", "documents"],
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            readonly: true,
            col: {
              span: 12,
            },
          },
          {
            fieldId: uniqueId("productImage"),
            ...createRemarkReadOnlyField("productImage"),
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("productImage"),
            type: EnumFieldType.SECTION,
            name: ["productImage", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["productImage", "reviewer"]),
                label: "Reviewer Remarks",
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["productImage", "reviewer", "isReuploadProductImage"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["productImage", "reviewer", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            shouldDisabled: (_, __, formValues) => {
              return ["completed"].includes(formValues?.product?.status);
            },
            col: {
              span: 12,
            },
          },
        ],
      },
      {
        title:
          "2. Product specification / Labelling artwork / Packaging artwork",
        name: "productSpecificationLabellingArtworkPackagingArtwork",
        fields: [
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            type: EnumFieldType.UPLOAD,
            name: ["productSpecificationLabellingArtworkPackagingArtwork", "documents"],
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            readonly: true,
            col: {
              span: 12,
            },
          },
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            ...createRemarkReadOnlyField("productSpecificationLabellingArtworkPackagingArtwork"),
          },
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("productSpecificationLabellingArtworkPackagingArtwork"),
            type: EnumFieldType.SECTION,
            name: ["productSpecificationLabellingArtworkPackagingArtwork", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["productSpecificationLabellingArtworkPackagingArtwork", "reviewer"]),
                label: "Reviewer Remarks",
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["productSpecificationLabellingArtworkPackagingArtwork", "reviewer", "isReuploadProductSpecificationLabellingArtworkPackagingArtwork"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["productSpecificationLabellingArtworkPackagingArtwork", "reviewer", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            shouldDisabled: (_, __, formValues) => {
              return ["completed"].includes(formValues?.product?.status);
            },
          },
        ],
      },
      {
        title: "3. Test report / Assessment report / Certificate",
        name: "testReport",
        fields: [
          {
            fieldId: uniqueId("testPlan"),
            type: EnumFieldType.UPLOAD,
            name: ["testReport", "documents"],
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            readonly: true,
            col: {
              span: 12,
            },
          },
          {
            fieldId: uniqueId("testReport"),
            ...createRemarkReadOnlyField("testReport"),
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.DIVIDER,
          },
          {
            fieldId: uniqueId("testReport"),
            type: EnumFieldType.SECTION,
            name: ["testReport", "reviewer"],
            title: <h1>Review section</h1>,
            fields: [
              {
                fieldId: uniqueId("reviewer_remarks"),
                ...creteRemarkField(["testReport", "reviewer"]),
                label: "Reviewer Remarks",
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
              {
                fieldId: uniqueId("reviewer_remarks_isRequired"),
                name: ["testReport", "reviewer", "isReuploadTestReport"],
                label: <h3>Reviewing Result</h3>,
                type: EnumFieldType.CHECKBOX,
                options: [{ value: "required", label: "Require re-upload " }],
                shouldDisabled: (_, __, formValues) => {
                  return ["completed"].includes(formValues?.product?.status);
                },
              },
            ],
          },
          {
            fieldId: uniqueId("conformityCertificate"),
            type: EnumFieldType.UPLOAD,
            label: <h3 style={{ marginBottom: 0 }}>Documents</h3>,
            name: ["testReport", "reviewer", "documents"],
            multiple: true,
            listType: "picture",
            uploadButton: "Upload document",
            col: {
              span: 12,
            },
            shouldDisabled: (_, __, formValues) => {
              return ["completed"].includes(formValues?.product?.status);
            },
          },
        ],
      },
      {
        title: "",
        name: "rejectReasons",
        sectionType: "reject_reason",
        fields: [],
      },
    ],
  },
};
