import React from "react";
import {
  ModalProps,
  Space,
  Button,
  Row,
  Col,
  Avatar,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import SimpleModal from "../../Molecules/modal/simple-modal";
import styled from "@emotion/styled";
import {
  DeleteOutlined,
  FileImageOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import DocIcon from "../../Atoms/images/file-icon/doc_icon.png";
import PdfIcon from "../../Atoms/images/file-icon/pdf_icon.png";
import XlsIcon from "../../Atoms/images/file-icon/xls_icon.png";
import { useUploadFile } from "components/Organisms/uploads/api";
import { UploadFile } from "antd/es/upload/interface";

const Title = styled.h2`
  margin-bottom: 0;
`;
const Content = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.002em;
  text-align: left;
`;

const ModalUploadTestPlan = (
  props: ModalProps &
    Partial<{
      uploadedFiles: any[];
      showInsufficientInfo: boolean;
      onSave: (files: UploadFile[]) => void;
      onDownload: (file: UploadFile) => void;
      onClickInsufficientInfo: () => void;
    }>
) => {
  const {
    onSave,
    onClickInsufficientInfo,
    showInsufficientInfo,
    uploadedFiles: defaultUploadedFiles,
    ...modalProps
  } = props;
  const { mutate: upload } = useUploadFile();
  const [uploadedFiles, setUploadedFiles] = React.useState<UploadFile[]>(
    defaultUploadedFiles as any
  );
  React.useEffect(() => {
    setUploadedFiles(defaultUploadedFiles || []);
  }, [defaultUploadedFiles, props.visible]);
  const cusUpload = async (options: any) => {
    const { onSuccess, onError, file } = options;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res: File = await upload(formData);
      onSuccess(res);
    } catch (err) {
      onError({ err });
    }
  };

  const docProps = {
    customRequest: cusUpload,
    multiple: true,
    name: "files",
    itemRender: (
      originNode: React.ReactElement,
      file: UploadFile,
      fileList: object[],
      actions: any
    ) => {
      const patternFileExtension = /\.([0-9a-z]+)(?:[#]|$)/i;
      const extension =
        (file.fileName
          ? file.fileName.match(patternFileExtension)
          : file.name.match(patternFileExtension)) || [];

      let icon = null;
      switch (extension[1]) {
        case "doc":
        case "docx":
          icon = DocIcon;
          break;
        case "pdf":
          icon = PdfIcon;
          break;
        case "xls":
        case "xlsx":
          icon = XlsIcon;
          break;
        default:
          icon = (
            <FileImageOutlined style={{ color: "#F98001", fontSize: 22 }} />
          );
      }

      return (
        <Row align="middle">
          <Col flex={"none"}>
            <Avatar
              shape="square"
              size="small"
              style={{ margin: "16px 10px 5px 0px" }}
              src={icon}
            />
          </Col>
          <Col flex={`none`}>
            <div
              style={{ marginTop: 12, cursor: "pointer" }}
              onClick={() => {
                props.onDownload(file);
              }}
            >
              {file.name || file.fileName}
            </div>
          </Col>
          <Col flex={`auto`}>
            <Button
              style={{ marginTop: 10 }}
              type={"link"}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => actions.remove()}
            />
          </Col>
        </Row>
      );
    },
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setUploadedFiles(newFileList);
  };
  return (
    <SimpleModal
      title={<Title>Test Plan</Title>}
      okText="Stay"
      cancelText="Leave"
      footer={
        <Space>
          <Button
            disabled={!uploadedFiles.length}
            size="large"
            type="primary"
            onClick={() => {
              onSave && onSave(uploadedFiles);
              //Reset UploadedFile.
            }}
          >
            Save
          </Button>
          {showInsufficientInfo && (
            <Button
              onClick={() => {
                onClickInsufficientInfo && onClickInsufficientInfo();
              }}
              size="large"
            >
              Insufficient info
            </Button>
          )}
        </Space>
      }
      {...modalProps}
    >
      <Space direction="vertical" size={16}>
        <Content>Please upload test plan below</Content>
        <Upload
          fileList={uploadedFiles}
          onChange={onChange}
          {...docProps}
          listType="picture"
        >
          <Button type={`link`} icon={<UploadOutlined />}>
            Upload document
          </Button>
        </Upload>
      </Space>
    </SimpleModal>
  );
};

export default ModalUploadTestPlan;
