import { Result, Space, Button } from "antd";
import React from "react";
import EmailSending from "assets/icons/email-sending.svg";
import styled from "@emotion/styled";

const StyledResult = styled(Result)`
  .ant-result-subtitle {
    width: 290px;
    margin: auto;
    margin-top: 16px;
  }
  .ant-result-extra {
    width: 290px;
    margin: auto;
    margin-top: 56px;
  }
  .ant-btn-link {
    padding: 0;
  }
`;
const SignUpSuccess = ({
  onClickSignIn,
  onClickResend,
  email = "",
}: {
  onClickSignIn: () => void;
  onClickResend: () => void;
  email: string;
}) => {
  return (
    <StyledResult
      icon={<img src={EmailSending} alt="icon-success"></img>}
      title="Verity Email"
      subTitle={
        <span>
          Thank you for registering! An activation link has been sent to you at{" "}
          {email && <Button type="link">{email}</Button>} , please click it to
          complete the registration process. If you didn’t receive our email,
          please click “RESEND”.
        </span>
      }
      extra={
        <Space style={{ width: "340px" }} direction="vertical">
          <Button onClick={onClickSignIn} block type="primary" size="large">
            Sign In
          </Button>
          <Button onClick={onClickResend} type="link">
            Resend
          </Button>
        </Space>
      }
    ></StyledResult>
  );
};

export default SignUpSuccess;
