import styled from "@emotion/styled";
import { Form, FormInstance } from "antd";
import SimpleModal from "components/Molecules/modal/simple-modal";
import TestLineTable from "components/Organisms/reviewer-compliance-check/TestLineTable";
import { RbacContext } from "components/RBAC/context";
import CreateTestLine from "components/Templates/create-test-line";
import { Action, Resource } from "configs/rules";
import { StaticDataContext } from "context/static-data-context";
import moment from "moment";
import React from "react";

const Container = styled.div`
  .ant-form-item-explain-error {
    margin-bottom: 20px;
  }
`;

const ReportData = ({ value, operation, field, ...rest }) => {
  const {
    testItems: testItemOptions,
    citations: citationOptions,
    legislations: legislationOptions,
  } = React.useContext(StaticDataContext);
  const [modalForm] = Form.useForm();
  const form = Form.useFormInstance();
  const canActive = React.useContext(RbacContext);
  const canCreate = canActive(
    `${Resource.JOBS}.form`,
    Action.CREATE,
    "conformityCertificate.reviewer.reportData"
  );
  const canEdit = canActive(
    `${Resource.JOBS}.form`,
    Action.EDIT,
    "conformityCertificate.reviewer.reportData"
  );
  const addTestLineFormRef = React.useRef<{ form: FormInstance }>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);

  const disabled = React.useMemo(() => {
    if (field.shouldDisabled)
      return field.shouldDisabled(field, value, form.getFieldsValue());
    return false;
  }, [field, value]);

  return (
    <Container>
      <h1>Report Data</h1>
      <TestLineTable
        canCreate={canCreate}
        canEdit={canEdit}
        onAddTestLine={() => {
          setIsOpen(true);
          setTimeout(() => {
            modalForm.setFieldsValue(null);
            modalForm?.resetFields();
          });
        }}
        onEditTestLine={(record) => {
          setRowData({ ...record });
          setIsOpen(true);
          setTimeout(() => {
            modalForm.setFieldsValue({
              ...record,
              reportIssueDate: record.reportIssueDate
                ? moment(record.reportIssueDate)
                : null,
            });
          });
        }}
        onRemoveTestLine={(recordId) => {
          const values = form.getFieldValue(field.name);
          const index = values.findIndex(({ id }) => id === recordId);
          operation.remove(index);
        }}
        disabled={disabled}
        value={[...(form.getFieldValue(field.name) || [])]}
        conformityType={"report-data"}
      ></TestLineTable>
      <SimpleModal
        visible={isOpen}
        title={rowData?.id ? "Edit Test Line" : "Add Test Line"}
        onCancel={() => {
          setIsOpen(false);
          setRowData(null);
          modalForm?.setFieldsValue({});
        }}
        onOk={() => {
          modalForm.submit();
        }}
      >
        <CreateTestLine
          form={modalForm}
          ref={addTestLineFormRef}
          testingItemOptions={testItemOptions}
          citationOptions={citationOptions}
          legislationOptions={legislationOptions}
          formType={"report-data"}
          onFinish={(values) => {
            if (rowData?.id) {
              // operation.
              const index = value.findIndex(({ id }) => id === rowData.id);
              form.setFieldValue([...field.name, index], { ...values });
            } else {
              operation.add(values);
            }
            setIsOpen(false);
            setRowData(null);
          }}
          initialValues={{ ...rowData }}
        ></CreateTestLine>
      </SimpleModal>
      <Form.ErrorList
        errors={rest.meta.errors.map((error) => {
          return <span style={{ marginBottom: 16 }}>{error}</span>;
        })}
      />
    </Container>
  );
};

export default ReportData;
