import React, {forwardRef, useEffect, useImperativeHandle, useState,} from "react";
import {Product} from "constants/interface/product/product";
import {productStatus} from "components/Atoms/status/common-status";
import NormalTable from "components/Organisms/tables/normal-table";
import {useGetColumnSearchProps} from "components/Organisms/tables/table-hook";
import {Button, Col, Form, Input, Row, Select} from "antd";
import {useNavigate} from "react-router-dom";
import {
  useListReview,
  // useRenewTask
} from "./../api";
// import { useUrlQueryPaging } from "../../../utils/paging";
import {useMerchantsProductsSearchParams} from "./../search-params";
import moment from "moment";
import {ModalForm} from "../../../components/Organisms/forms/modal-form";
import styled from "@emotion/styled";
import {StaticDataContext} from "context/static-data-context";
import {useFiltersOptions} from "HOCs/withFiltersOptions";
import {TOOLTIP} from "constants/tooltips";
import {InfoCircleOutlined} from "@ant-design/icons";

const {Search} = Input;

const TooltipContent = styled.ul`
    list-style: disc;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
`;
const ProductTable = forwardRef((props: any, ref: React.Ref<any>) => {
  const filters = useFiltersOptions();
  let navigate = useNavigate();
  const {serviceRequired: ServiceRequiredOptions} = React.useContext(
    StaticDataContext
  );

  const [getColumnSearchProps] = useGetColumnSearchProps();
  const [isExpired, setIsExpired] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [taskNumber, setTaskNumber] = useState("");

  const [param] = useMerchantsProductsSearchParams();
  // const [pageObject] = useUrlQueryPaging();

  // const {mutate, isLoading} = useRenewTask();
  const [keywords, setKeywords] = useState<string>()


  const [form] = Form.useForm();

  useEffect(() => {
    if (param?.progress === "expiring") {
      setIsExpired(true);
      return () => {
        setIsExpired(false);
      };
    }
  }, [param]);

  const defaultProgress = React.useMemo(() => {
    if (param?.progress === "expiring") return [];
    const progress = param?.progress;
    if (!progress) return "";
    return progress.split(",");
  }, [[param]]);

  const [allTask, onChangeAllTask] = useListReview({
    status: props.defaultStatus || defaultProgress || null,
    progress: param?.progress === "expiring" ? "expiring" : "",
    keywords
  } as any);

  useImperativeHandle(ref, () => ({
    reload() {
      allTask.refetch();
    },
  }));
  const showRenew = async (record: any) => {
    await setTaskNumber(record?.taskNumber ?? record?.productNumber);
    await form.setFieldsValue({productId: record.id});
    await setIsModalVisible(true);
  };

  const handleRenew = async () => {
    const productId = form.getFieldValue("productId");
    const serviceRequired = form.getFieldValue("serviceRequired");
    // await mutate(serviceRequired, productId);
    allTask.refetch();
    await setTaskNumber("");
    await setIsModalVisible(false);
  };

  const handleCancelRenew = async () => {
    await setTaskNumber("");
    await setIsModalVisible(false);
  };

  const columns: any = [
    {
      title: "Task Number",
      dataIndex: "taskNumber",
      key: "taskNumber",
      width: 100,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 300,
      // ellipsis: true,
      ...getColumnSearchProps("productName"),
      render: (_: string, record: Product) => {
        return (
          <>
            <div>
              {/*<Button*/}
              {/*  type={`link`}*/}
              {/*  style={{padding: 0}}*/}
              {/*  onClick={() =>*/}
              {/*    window.open(`/project-page/${record.id}`, '_blank')*/}
              {/*  }*/}
              {/*>*/}
              {/*  {record.productName}*/}
              {/*</Button>*/}

              <a
                type={`link`}
                style={{padding: 0, width: 'inherit'}}
                className="shotDisplayText"
                onClick={() =>
                  window.open(`/project-page/${record.id}`, '_blank')
                }
              >
                {record.productName}
              </a>
            </div>
            <span style={{fontSize: "10px", color: "#8991A0"}} className="shotDisplayText">
              {record.productNumber}
            </span>
          </>
        );
      },
    },
    {
      title: "Export Market",
      dataIndex: "exportMarket",
      key: "exportMarket",
      width: 150,
      filters: filters.exportMarkets || [],
      filterSearch: true,
    },
    {
      title: "Product Category",
      dataIndex: "mainCategory",
      key: "mainCategory",
      width: 200,
      // filters: filters.productCategories || [],
      filterSearch: false,
    },
    {
      title: "Product Type Group",
      dataIndex: "category",
      key: "category",
      width: 200,
      filters: filters.productCategories || [],
      filterSearch: true,
    },
    {
      title: "Product Type",
      dataIndex: "subcategory",
      key: "subcategory",
      width: 200,
      filters: filters.subCategories || [],
      filterSearch: true,
      filtered: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 220,
      filters: props.hiddenFilterStatus ? null : filters["status"],
      defaultFilteredValue:
        props.defaultStatus || param?.progress ? defaultProgress : [],
      filterSearch: false,
      render: (text: string, record) => {
        return productStatus(text, record?.rejectedStatus);
      },
    },
    {
      title: "Create Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 130,
      sorter: (a: { createdAt: string }, b: { createdAt: string }) =>
        moment(a.createdAt, "DD-MM-YYYY").unix() -
        moment(b.createdAt, "DD-MM-YYYY").unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Update Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 130,
      sorter: (a: { updatedAt: string }, b: { updatedAt: string }) =>
        moment(a.updatedAt, "DD-MM-YYYY").unix() -
        moment(b.updatedAt, "DD-MM-YYYY").unix(),
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "ECD",
    //   dataIndex: "estimatedCompletionDate",
    //   key: "estimatedCompletionDate",
    //   width: 130,
    //   sorter: (
    //     a: { estimatedCompletionDate: string },
    //     b: { estimatedCompletionDate: string }
    //   ) =>
    //     moment(a.estimatedCompletionDate, "DD-MM-YYYY").unix() -
    //     moment(b.estimatedCompletionDate, "DD-MM-YYYY").unix(),
    //   sortDirections: ["descend", "ascend"],
    // },

    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: 130,
      sorter: (a: { expiryDate: string }, b: { expiryDate: string }) =>
        moment(a.expiryDate, "DD-MM-YYYY").unix() -
        moment(b.expiryDate, "DD-MM-YYYY").unix(),

      sortDirections: ["descend", "ascend"],
    },
    // isExpired && {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: 130,
    //   render: () => (
    //     <Button type={`link`}>Renew</Button>
    //   )
    // }
  ];

  if (isExpired) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 130,
      render: (text: string, record: any) => (
        <Button type={`link`} onClick={() => showRenew(record)}>
          Renew
        </Button>
      ),
    });
  }

  const onChange = (
    pagination: { current: number; pageSize: number },
    filtered: Record<string, any>,
    sorted: Record<string, any>,
    extra: { action: string; currentDataSource: Array<any> }
  ) => {
    onChangeAllTask(
      pagination,
      isExpired
        ? {
          status: props.defaultStatus || defaultProgress || [],
          progress: param?.progress === "expiring" ? "expiring" : "",
          ...filtered,
        }
        : {status: props.defaultStatus || defaultProgress || [], ...filtered},
      sorted,
      extra
    );
  };

  const onSearch = (value: string) => {
    setKeywords(value);
  }


  return (
    <>
      <Search placeholder="Item number / description / task number " allowClear onSearch={onSearch}
              style={{width: 400, margin: "0px 0px 10px 0px"}}/>
      <NormalTable
        scroll={{x: true}}
        columns={columns}
        loading={allTask.isLoading}
        dataSource={allTask?.data?.list}
        onChange={onChange as any}
        pagination={{
          total: (allTask as any)?.data?.total ?? 0,
        }}
      />
      {/*<ModalForm*/}
      {/*  title={`Compliance Status Renewal`}*/}
      {/*  formName={`compliance Status Renewal`}*/}
      {/*  okText={`Renew`}*/}
      {/*  form={form}*/}
      {/*  loading={isLoading}*/}
      {/*  onOk={async () => {*/}
      {/*    await form.validateFields();*/}
      {/*    handleRenew();*/}
      {/*  }}*/}
      {/*  visible={isModalVisible}*/}
      {/*  onCancel={handleCancelRenew}*/}
      {/*>*/}
      {/*  <div style={{color: "#4E5A71"}}>*/}
      {/*    We will create a new compliance check application based on{" "}*/}
      {/*    <span>task: {taskNumber}</span>*/}
      {/*  </div>*/}
      {/*  <Row justify={"space-between"} gutter={[8, 8]}>*/}
      {/*    <Col span={24}>*/}
      {/*      <HiddenField name={`productId`}>*/}
      {/*        <Input hidden/>*/}
      {/*      </HiddenField>*/}
      {/*      <Form.Item*/}
      {/*        name={"serviceRequired"}*/}
      {/*        label={"Service Required"}*/}
      {/*        tooltip={{*/}
      {/*          title: (*/}
      {/*            <TooltipContent>*/}
      {/*              {TOOLTIP.serviceRequired.map((value, index) => {*/}
      {/*                return <li key={`service-required-${index}`}>{value}</li>;*/}
      {/*              })}*/}
      {/*            </TooltipContent>*/}
      {/*          ),*/}
      {/*          icon: <InfoCircleOutlined/>,*/}
      {/*        }}*/}
      {/*        rules={[*/}
      {/*          {*/}
      {/*            required: true,*/}
      {/*            message: "Estimate Completion Date cannot be empty",*/}
      {/*          },*/}
      {/*        ]}*/}
      {/*      >*/}
      {/*        <Select*/}
      {/*          options={ServiceRequiredOptions}*/}
      {/*          placeholder="Select service required"*/}
      {/*        ></Select>*/}
      {/*      </Form.Item>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</ModalForm>*/}
    </>
  );
});

export default ProductTable;

const HiddenField = styled(Form.Item)`
    margin-bottom: 0;
`;
