export const ValidatorOptions = [
  {
    validation: "min",
    label: "8 digits",
  },
  {
    validation: "uppercase",
    label: "1 uppercase letter",
  },
  {
    validation: "lowercase",
    label: "1 lowercase letter",
  },
  {
    validation: "symbols",
    label: "1 number or symbol",
  },
];
