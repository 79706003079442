import {useUrlQueryParam} from "../../utils/url";
import {useMemo} from "react";

export const useMerchantsProductsSearchParams = () => {
  const [param, setParam] = useUrlQueryParam([
    "productName",
    "exportMarket",
    "category",
    "subcategory",
    "status",
    "progress",
  ]);

  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};
