import React from "react";
import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormProps,
  Image,
  Input,
  message,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const FooterDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin-top: 40px;
    margin-bottom: 35px;
    border-top-color: transparent;
  }
`;
const ForgotPassword = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  button {
    padding-right: 0px;
    font-size: 14px;
  }
`;

const StyledLogo = styled(Image)`
  text-align: center;
`;

const StyledSologan = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
`;

const StyledForm = styled(Form, {
  shouldForwardProp: (prop) => !["extraStyle"].includes(prop),
})<
  FormProps & {
    extraStyle?: React.CSSProperties;
  }
>`
  ${(props: any) => props.extraStyle}
  // > div {
  //   &.ant-form-item:nth-of-type(2) {
  //     margin-bottom: 16px;
  //   }
  // }
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledTermCondition = styled(Form.Item)`
  span:not(.ant-checkbox) {
    font-size: 10px;
  }
`;

const SignIn = ({
  handleSubmit,
  onForgotPassword,
  loading,
  config,
  logo,
  sologan,
}: any) => {
  const [form] = Form.useForm();

  const onSubmit = async (values: { email: string; password: string }) => {
    try {
      handleSubmit(values);
    } catch (e) {
      message.error((e as any).message || "Invalid Account or Password");
    }
  };

  return (
    <StyledForm
      extraStyle={config.style}
      onFinish={(values: any) => onSubmit(values)}
      name="sign-in"
      form={form}
      layout="vertical"
      initialValues={{ agreement: true }}
    >
      {logo && (
        <div style={{ textAlign: "center" }}>
          <StyledLogo preview={false} src={logo}></StyledLogo>
        </div>
      )}
      {sologan && (
        <StyledSologan style={{ textAlign: "center" }}>{sologan}</StyledSologan>
      )}
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            type: "email",
            message: "The email is not valid",
          },
        ]}
      >
        <Input type="email" size="large"></Input>
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: "Please input your password" }]}
      >
        <Input.Password
          size="large"
          type="password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        ></Input.Password>
      </Form.Item>

      <FooterDivider></FooterDivider>
      <StyledTermCondition name="agreement" valuePropName="checked">
        <Checkbox>
          I agree to the{" "}
          <a
            href="https://www.icw.io/terms-conditions/"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          ,{" "}
          <a
            href="https://www.icw.io/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          , and{" "}
          <a
            href="https://www.icw.io/cookie-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Cookie Policy.
          </a>
        </Checkbox>
      </StyledTermCondition>
      <Button
        loading={loading}
        size="large"
        block
        htmlType="submit"
        type="primary"
      >
        Login
      </Button>
      <ForgotPassword>
        <Button type="link" onClick={onForgotPassword}>
          Forgot Password?
        </Button>
      </ForgotPassword>
    </StyledForm>
  );
};

export default React.memo(SignIn);
