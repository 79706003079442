import React from "react";
import { Product } from "constants/interface/product/product";
import moment from "moment";
import {
  productStatus,
  testPlanStatus,
} from "components/Atoms/status/common-status";
import { Button } from "antd";
import { NavigateFunction } from "react-router-dom";
import WaitingForReviewIcon from "components/Atoms/images/file-find.png";
import OverviewCard from "components/Molecules/overview-card";
import completedImage from "components/Atoms/images/Completed.png";
import rejectImage from "components/Atoms/images/Rejected.png";
import { replace } from "lodash";

export const PRODUCT_STATUS = [
  {
    text: "Approved",
    value: "completed",
  },
  {
    text: "Waiting for Review",
    value: "waiting_for_review",
  },
  {
    text: "Pending Payment",
    value: "pending_payment",
  },
  {
    text: "Pending E-sign",
    value: "pending_e_signature",
  },
  {
    text: "Rejected - Request Additional Test",
    value: "rejected_request_additional_test",
  },
  {
    text: "Rejected - Request Quote Accepted",
    value: "rejected_quote_accepted",
  },
  {
    text: "Rejected - Request revise",
    value: "rejected_request_revise",
  },
  {
    text: "Rejected - Others",
    value: "rejected_others",
  },
  {
    text: "Missing Documents",
    value: "missing_documents",
  },
  {
    text: "Conformity Certificate Expired",
    value: "conformity_certificate_expired",
  },
];
export const TEST_PLAN_STATUS = [
  {
    text: "Pending",
    value: "pending",
  },
  {
    text: "Auto Assigned",
    value: "auto_assigned",
  },
  {
    text: "Manual Assigned",
    value: "manual_assigned",
  },
];

export enum USER_ROLES {
  REVIEWER = "reviewer_user",
  MERCHANT = "merchant_user",
}

export enum QUOTATION_STATUS {
  ACCEPT = "ACCEPTED",
  REVISE = "REVISED",
}

export const getReviewColumnDef = (
  navigate: any,
  getColumnSearchProps: any,
  defaultFilteredValue: any,
  filters: { [key: string]: Array<{ value: string; label: string }> } = {}
) => {
  return [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: "550px",
      ...getColumnSearchProps("productName"),
      render: (_: string, record: Product) => {
        return (
          <div>
            <div>
              <a
                type={`link`}
                style={{ padding: 0 , width: 'inherit'}}
                className="shotDisplayText"
                onClick={() =>
                  window.open(`/review/${(record as any).productUUID}`, '_blank')
                }
              >
                {record.productName}
              </a>
            </div>
            <span style={{ fontSize: "10px", color: "#8991A0" }} className="shotDisplayText">
              {record.productNumber}
            </span>
          </div>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "merchantName",
      key: "merchantName",
      width: 220,
      ...getColumnSearchProps("merchantName", "Company Name"),
    },
    {
      title: "Export Market",
      dataIndex: "exportMarket",
      key: "exportMarket",
      width: 150,
      filters: filters.exportMarkets || [],
      filterSearch: true,
    },
    {
      title: "Product Category",
      dataIndex: "productMainCategory",
      key: "productMainCategory",
      width: 200,
      // filters: filters.productCategories || [],
      filterSearch: false,
    },
    {
      title: "Product Type Group",
      dataIndex: "productCategory",
      key: "productCategory",
      width: 200,
      filters: filters.productCategories || [],
      filterSearch: true,
    },
    {
      title: "Product Type",
      dataIndex: "productSubcategory",
      key: "productSubcategory",
      width: 200,
      filters: filters.subCategories || [],
      filterSearch: true,
      filtered: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 220,
      filters: filters["status"],
      defaultFilteredValue: defaultFilteredValue.status,
      filterSearch: false,
      render: (text: string, record) => {
        return productStatus(text, record.rejectedStatus);
      },
    },
    // {
    //   title: "Test Plan Status",
    //   dataIndex: "testPlanStatus",
    //   key: "testPlanStatus",
    //   width: 220,
    //   filters: filters["testPlanStatus"],
    //   defaultFilteredValue: defaultFilteredValue.testPlanStatus,
    //   filterSearch: false,
    //   render: (text: string) => {
    //     return testPlanStatus(text);
    //   },
    // },
    // {
    //   title: "ECD",
    //   dataIndex: "estimatedCompletionDate",
    //   key: "estimatedCompletionDate",
    //   width: 130,
    //   sorter: (
    //     a: { estimatedCompletionDate: string },
    //     b: { estimatedCompletionDate: string }
    //   ) =>
    //     moment(a.estimatedCompletionDate, "DD-MM-YYYY").unix() -
    //     moment(b.estimatedCompletionDate, "DD-MM-YYYY").unix(),
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Task Number",
      dataIndex: "taskNumber",
      key: "taskNumber",
      width: 130,
      sorter: (a: { createdAt: string }, b: { createdAt: string }) =>
        moment(a.createdAt, "DD-MM-YYYY").unix() -
        moment(b.createdAt, "DD-MM-YYYY").unix(),
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Review Deadline",
    //   dataIndex: "expiryDate",
    //   key: "expiryDate",
    //   width: 130,
    //   sorter: (a: { expiryDate: string }, b: { expiryDate: string }) =>
    //     moment(a.expiryDate, "DD-MM-YYYY").unix() -
    //     moment(b.expiryDate, "DD-MM-YYYY").unix(),

    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "",
      dataIndex: "status",
      key: "action_status",
      width: 130,
      render: (_: string, record: Product) => {
        return (
          <div>
            <Button
              type={record.status === "waiting_for_review" ? "primary" : `link`}
              onClick={() => window.open(`/review/${(record as any).productUUID}`, '_blank')}
            >
              {record.status === "waiting_for_review" ? "Review" : "View"}
            </Button>
          </div>
        );
      },
    },
    {
      title: "Update Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 130,
      sorter: (a: { updatedAt: string }, b: { updatedAt: string }) =>
        moment(a.updatedAt, "DD-MM-YYYY").unix() -
        moment(b.updatedAt, "DD-MM-YYYY").unix(),
      sortDirections: ["descend", "ascend"],
    },
  ];
};

export const getReviewStats = (
  navigate: NavigateFunction,
  { completed = 0, rejected = 0, waiting_for_review = 0 }
) => [
  {
    icon: WaitingForReviewIcon,
    title: "Waiting for Review",
    component: OverviewCard,
    total: waiting_for_review,
    onClick: () => navigate("/reviews/waiting-for-review"),
  },
  {
    icon: completedImage,
    title: "Approved",
    component: OverviewCard,
    total: completed,
    onClick: () => navigate("/reviews/completed"),
  },
  {
    icon: rejectImage,
    title: "Rejected",
    component: OverviewCard,
    total: rejected,
    onClick: () => navigate("/reviews/rejected"),
  },
];

export const getComplianceManagementStats = (
  navigate: NavigateFunction,
  { totalProduct = 0, documentReuploadRate = 0, rejectRate = 0 }
) => [
  {
    title: "Total Tasks",
    width: 280,
    component: OverviewCard,
    total: replace(totalProduct + "", "NaN", "0"),
    onClick: () => navigate("/reviews/all"),
  },
  {
    title: "Document Re-upload Rate (Last Week)",
    width: 280,
    component: OverviewCard,
    total: replace(documentReuploadRate + "", "NaN", "0%"),
  },
  {
    title: "Reject Rate",
    width: 280,
    component: OverviewCard,
    total: replace(rejectRate + "", "NaN", "0"),
  },
];

export const rejectionReasonStats = () => [];

export const STATUS: Record<string, string> = {
  "waiting-for-review": "Waiting for review",
  rejected: "Rejected",
  completed: "Approved",
  all:"Review All"
};

const DemoData = [
  {
    productCategory: "Toys & Hobbies",
    subCategory: "Action & Toy Figures",
    productGroup: "Action Figure",
    fileName: "1. Action Figure.pdf",
    fileUrl:
      "https://icw-data.oss-cn-hongkong.aliyuncs.com/Test%20Plan/Action%20Figure.pdf",
  },
  {
    productCategory: "Toys & Hobbies",
    subCategory: "Action & Toy Figures",
    productGroup: "Action Figures",
    fileName: "1. Action Figure.pdf",
    fileUrl:
      "https://icw-data.oss-cn-hongkong.aliyuncs.com/Test%20Plan/Action%20Figure.pdf",
  },
  {
    productCategory: "Toys & Hobbies",
    subCategory: "Arts, Crafts & DIY Toys",
    productGroup: "Candle Making Kits",
    fileName: "7. Candle Making Kits.pdf",
    fileUrl:
      "https://icw-data.oss-cn-hongkong.aliyuncs.com/Test%20Plan/Candle%20Making%20Kits.pdf",
  },
  {
    productCategory: "Toys & Hobbies",
    subCategory: "Baby & Toddler Toys",
    productGroup: "Harnesses & Leashes",
    fileName: "19. Harnesses & Leashes (1.1).pdf",
    fileUrl:
      "https://icw-data.oss-cn-hongkong.aliyuncs.com/Test Plan/Harnesses %26 Leashes (1.1).pdf",
  },
  {
    productCategory: "Toys & Hobbies",
    subCategory: "Classic Toys",
    productGroup: "Light-up Toys",
    fileName: "26. Light up toy.pdf",
    fileUrl:
      "https://icw-data.oss-cn-hongkong.aliyuncs.com/Test%20Plan/Light%20up%20toy.pdf",
  },
  {
    productCategory: "Toys & Hobbies",
    subCategory: "Electronic Toys",
    productGroup: "App Controlled Toys",
    fileName: "40. App Controlled Toys (1.1).pdf",
    fileUrl:
      "https://icw-data.oss-cn-hongkong.aliyuncs.com/Test Plan/App Controlled Toys (1.1).pdf",
  },
];

export const getDemoFile = (productDetail: any) => {
  if (productDetail) {
    const mock = [...DemoData].filter((mockData) => {
      //Have different space.
      return (
        mockData.productCategory === productDetail.category &&
        mockData.subCategory === productDetail.subcategory &&
        String(mockData.productGroup.replace(/\s+/g, "")) ===
          String(productDetail.productGroup.replace(/\s+/g, ""))
      );
    });
    if (mock.length) {
      return [
        {
          uuid: -1,
          url: `${mock[0].fileUrl}`,
          name: mock[0].fileName,
          docType: "test_plan",
          status: "done",
        },
      ];
    }
  }
  return [];
};
