import React from "react";
import {Button} from "antd";
import {PaymentElement} from "@stripe/react-stripe-js";
import {useStripePayment} from "hooks";

export const PaymentForm = ({clientSecret, handlePayment}) => {
  const [, setIsSuccess] = React.useState(false);
  const handleStripePayment = React.useCallback((status, paymentIntent) => {
    if (status === "succeeded") {
      handlePayment({
        amount: paymentIntent.amount,
        transactionNumber: paymentIntent.id,
        paymentStatus: "successful", //paymentIntent.status,
        paymentMethod: paymentIntent.paymentMethod,
        currency: paymentIntent.currency,
        remark: paymentIntent.description,
      });
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {handleSubmit, isLoading: paymentLoading} = useStripePayment(
    handleStripePayment, clientSecret
  );
  return (

    <form
      onSubmit={(e: any) => {
        handleSubmit(e);
      }}
    >
      <PaymentElement id="payment-element"></PaymentElement>
      <Button
        htmlType="submit"
        type="primary"
        size={`large`}
        loading={paymentLoading}
        style={{
          margin: "20px 0px",
          padding: "0px 50px",
          width: "100%",
        }}
      >
        Pay Now
      </Button>
    </form>

  );
};
