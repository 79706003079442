import styled from "@emotion/styled";
import {Card, Col, Row, Space} from "antd";
import StatisticsGroup from "components/Molecules/statistics-group";
import {Role} from "configs/rules";
import {useAuth} from "context/auth-context";
import {each} from "lodash";
import {getComplianceManagementStats, getReviewStats,} from "pages/Reviews/constants";
import React, {useEffect, useState} from "react";
import {Treemap} from "@ant-design/plots";
import {TreemapConfig} from "@ant-design/plots/es/components/treemap";
// import "@ant-design/plots/dist/index.css";

const StyledCard = styled(Card)`
    width: 100%;
    border-radius: 16px;
    background: #fff;

    /* Shadow-default/elevation-1 */
    box-shadow: 0px 1px 6px 0px rgba(165, 178, 200, 0.2),
    0px 2px 4px 1px rgba(165, 178, 200, 0.1);

    label {
        color: var(--text-text-400, #4e5a71);

        /* Body/Body 2 - Regular */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.028px;
    }

    .ant-badge-status {
        .ant-badge-status-dot {
            width: 27.14px;
            height: 27.14px;
            font-size: 28px;
        }
    }

    .card_title {
        color: #000;

        /* Header/H1 - Medium */
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
        letter-spacing: 0.24px;
    }
`;

const Statistics = ({overview, navigate}) => {
  const {user}: any = useAuth();
  const {userRole} = user || {};
  const [rejectType, setRejectType] = useState({})

  function processData(data) {
    let sumValue = 0;
    each(data, (d) => {
      sumValue += d.value;
    });

    return {name: 'root', value: sumValue, children: data};
  }

  useEffect(() => {
    if (overview) {
      const newData = processData(overview?.rejectTypeOverview)
      setRejectType(newData)
    }

  }, [overview]);


  const config:TreemapConfig  = {
    legend: false ,
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{marginTop: 10}}/>
            <ul style={{paddingLeft: 0}}>
              {items?.map((item, index) => {
                const {name, value, color, data} = item;
                return (
                  <>
                    <li
                      key={item.name}
                      className="g2-tooltip-list-item"
                      data-index={index}
                      style={{marginBottom: 4, display: 'flex', alignItems: 'center'}}
                    >
                      <span className="g2-tooltip-marker" style={{backgroundColor: color}}></span>
                      <span
                        style={{display: 'inline-flex', flex: 1, justifyContent: 'space-between'}}
                      >
                      <span style={{marginRight: 16}}>Name: {name}</span>
                        {/*<span className="g2-tooltip-list-item-value">{value}</span>*/}
                    </span>
                    </li>
                    <li
                      className="g2-tooltip-list-item"
                      style={{marginBottom: 4, display: 'flex', alignItems: 'center'}}
                    >
                      <span
                        className="g2-tooltip-list-item-value">Count of Rejected Type: {value} ({(value / data.parent.data.value * 100).toFixed(2)} %)</span>
                    </li>
                  </>
                );
              })}
            </ul>
          </>
        );
      },
    },
    data: rejectType,
    colorField: 'value',
    style: {
      height: "380px"
    },
    color: "rgba(47,130,255, 0.8)"
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={14} lg={24} md={24} xl={14}>
        <Row>
          <Space direction="vertical" size={[0, 3]}>
            <h2>Overview</h2>
            <StatisticsGroup
              containerProps={{
                size: 32,
                style: {
                  marginBlock: 24,
                },
              }}
              stats={getReviewStats(navigate, overview || {}) as any}
            ></StatisticsGroup>
          </Space>
        </Row>
        {userRole === Role.BUYER && (
          <Row>
            <Space direction="vertical" size={[0, 3]}>
              <h2>Compliance Management Statistics</h2>
              <StatisticsGroup
                containerProps={{
                  size: 32,
                }}
                stats={
                  getComplianceManagementStats(
                    navigate,
                    overview?.complianceManagementStatistics || {}
                  ) as any
                }
              ></StatisticsGroup>
            </Space>
          </Row>
        )}
      </Col>
      {userRole === Role.BUYER && (
        <Col span={10} lg={24} md={24} xl={10}>
          <Space style={{width: "100%"}} direction="vertical" size={[0, 3]}>
            <h2> Rejection Types Overview</h2>
            {
              overview?.rejectTypeOverview?.length > 0 &&
              (<Treemap {...config} />)
            }
          </Space>
        </Col>
      )}
    </Row>
  );
};

export default Statistics;
