import React from 'react';
import {Table} from 'antd';
import {TableProps} from 'antd/es/table';

const NormalTable = (props: TableProps<any>) => {

  return (
    <>
      <Table {...props}/>
    </>
  )
}

export default NormalTable
