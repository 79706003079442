import React from "react";
import { Layout } from "../components/Layout";
import { StyledTitle } from "../components/Title";
import styled from "@emotion/styled";
import {
  Button,
  Result,
  Space,
  Form,
  Typography,
  Divider,
  notification,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { ValidatorOptions } from "pages/Authorization/constant";
import SiderBG from "assets/backgrounds/authorization-sider-sign-up-bg.png";
import ECSPR from "assets/logo/e-cpsr-logo.svg";
import ICWPoweredBy from "assets/logo/icw-powered-by-logo.svg";
import "./ResetPassword.less";
import InputPassword from "components/Atoms/input/InputPassword";
import { useAuth } from "context/auth-context";

const Container = styled.div`
  display: flex;
  justify-content: center;
  algin-items: center;
  flex-direction: column;
  padding-top: 80px;
  form {
    align-self: center;
    min-width: 410px;
  }
`;

const Sider = styled.div`
  padding: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: #ebedef;
      font-size: 40px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: 0.01em;
      text-align: center;
    }
    div.ant-typography {
      max-width: 315px;
      color: #ebedef;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: center;
    }
    button {
      margin-top: 60px;
      width: 140px;
    }
  }
`;
const StyledFooter = styled(Space)`
  justify-content: flex-end;
`;

const StyledDivider = styled(Divider)`
  &.ant-divider-with-text {
    border-top-color: transparent;
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 400;
  }
`;
const StyledResult = styled(Result)`
  button {
    max-width: 410px;
  }
  .ant-result-subtitle {
    button {
      padding: 0;
      h3 {
        color: inherit;
      }
    }
  }
`;
const ForgotPassword = ({ t, i18n }: Partial<WithTranslation>) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const { resetPassword } = useAuth();
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const goToSignIn = React.useCallback(() => {
    navigate("/authorization/sign-in");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translate = (key: string) => {
    return t && t(key);
  };

  const handleSubmit = async (values: any) => {
    try {
      const searchParams = new URLSearchParams(search);
      const token = searchParams.get("token") || "";

      setIsLoading(true);
      await resetPassword({ ...values, token });
      setSubmitSuccess(true);
    } catch (error) {
      notification.error({ message: (error as any).message });
    } finally {
      setIsLoading(false);
    }
  };

  const renderSider = React.useCallback(() => {
    return (
      <Sider>
        <Space align="start" size={30}>
          <img src={ECSPR} alt="e-cspr"></img>
        </Space>
        <div className="content">
          <Space direction="vertical" align="center" size={[10, 10]}>
            <Typography.Title>Welcome Back!</Typography.Title>
            <Typography.Paragraph>
              If you’re one of us, login with your email or LinkedIn account.
            </Typography.Paragraph>
          </Space>
          <Button onClick={goToSignIn} ghost size="large">
            Login
          </Button>
        </div>
        <StyledFooter align="end">
          <img src={ICWPoweredBy} alt="e-cspr"></img>
        </StyledFooter>
      </Sider>
    );
  }, [goToSignIn]);

  React.useEffect(() => {}, []);

  return (
    <Layout
      revert
      className={"container"}
      sider={{ siderBg: SiderBG, renderSider: renderSider }}
    >
      <Container>
        <StyledTitle className={"title"}>Reset Password</StyledTitle>
        {submitSuccess ? (
          <>
            <StyledResult
              status="success"
              subTitle={
                <span>
                  <h3>Reset password completed.</h3>
                </span>
              }
              extra={[
                <Button
                  size="large"
                  onClick={goToSignIn}
                  block
                  type="primary"
                  key="console"
                >
                  Sign In
                </Button>,
              ]}
            />
          </>
        ) : (
          <Form
            form={form}
            name="forgot-password"
            layout="vertical"
            onFinish={handleSubmit}
          >
            <StyledDivider>Enter your new password</StyledDivider>
            <InputPassword.WithFormItem
              formItemProps={{
                name: "password",
                label: translate("register:Form.field.password.label"),
              }}
              inputProps={{
                placeholder: translate(
                  "register:Form.field.password.placeholder"
                ),
              }}
              validationOptions={ValidatorOptions}
            ></InputPassword.WithFormItem>
            <Button
              loading={isLoading}
              size="large"
              block
              htmlType="submit"
              type="primary"
            >
              Confirm
            </Button>
          </Form>
        )}
      </Container>
    </Layout>
  );
};

export default withTranslation(["common", "register"])(ForgotPassword);
