import {useHttp} from "../../../utils/http";
import {useAsync} from "../../../utils/use-async";

export const useUploadFile = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();

  const mutate = (params: FormData) => {
    return run(
      client(`files`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
