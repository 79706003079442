import React from "react";
import { Button, Table } from "antd";
import styled from "@emotion/styled";
import { getConformityColDefs } from "constants/columnDef";

const StyledTable = styled(Table)`
  .ant-table-footer {
    background: #fff;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
`;
const TestLineTable = ({
  onAddTestLine,
  conformityType,
  value = [],
  disabled,
  onEditTestLine,
  onRemoveTestLine,
  canCreate = true,
  canEdit = true,
}) => {
  const colDefs = React.useMemo(() => {
    const _colDefs = getConformityColDefs(conformityType);
    return _colDefs;
  }, [conformityType]);
  return (
    <StyledTable
      dataSource={value || []}
      rowKey={(record: any, index) => record?.id || index}
      footer={() => {
        if (!canCreate) return null;
        return (
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => {
              onAddTestLine(conformityType || "DOC");
            }}
          >
            Add test line
          </Button>
        );
      }}
      columns={(colDefs as any).concat(
        canEdit
          ? [
              {
                title: "Action",
                dataIndex: "",
                key: "action",
                render: (_: any, record: any, index: number) => {
                  if (disabled) {
                    return <></>;
                  }

                  return (
                    <>
                      <Button
                        type="link"
                        onClick={() => {
                          onEditTestLine &&
                            onEditTestLine(record, index, conformityType);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        type="text"
                        danger
                        onClick={() => {
                          onRemoveTestLine &&
                            onRemoveTestLine(record.id, conformityType);
                        }}
                      >
                        Remove
                      </Button>
                    </>
                  );
                },
              },
            ]
          : []
      )}
    ></StyledTable>
  );
};

export default TestLineTable;
