import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { notification } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useHttp } from "utils/http";

export const useFetchPaymentIntent = (queryUrl: string, params?: any) => {
  let queryString = new URLSearchParams(params);
  const client = useHttp();
  const query = useQuery(
    ["payment_intent", queryUrl],
    async () => {
      const response = await client(queryUrl + `?${queryString.toString()}`, {});
      return response;
    },
    {
      enabled: false,
    }
  );

  return query;
};

export const useStripePayment = (
  onChangeStatusPaymentIntent: (
    status: string,
    paymentIntent: PaymentIntent
  ) => void,
  clientSecret?: string,
) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  React.useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //   onChangeStatusPaymentIntent(paymentIntent.status, paymentIntent);
    //   switch (paymentIntent.status) {
    //     case "succeeded":
    //       notification.success({ message: "Payment succeeded!" });
    //       break;
    //     case "processing":
    //       notification.info({ message: "Your payment is processing." });
    //       break;
    //     case "requires_payment_method":
    //       notification.error({
    //         message: "Your payment was not successful, please try again.",
    //       });
    //       break;
    //     default:
    //       notification.error({
    //         message: "Something went wrong.",
    //       });
    //       break;
    //   }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
        // "http://localhost:3000/project-page/87ba20d5-3e6f-4694-a758-6c6d5648aeb0",
        // receipt_email: email,
      },
      redirect: "if_required",
    });

    // const {error, paymentIntent} = await stripe.confirmWechatPayPayment(clientSecret, {
    //   payment_method_options: {
    //     wechat_pay: {
    //       client: 'web'
    //     }
    //   }
    // });

    if (paymentIntent) {
      onChangeStatusPaymentIntent(paymentIntent.status, paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          notification.success({ message: "Payment succeeded!" });
          break;
        case "processing":
          notification.info({ message: "Your payment is processing." });
          break;
        case "requires_payment_method":
          notification.error({
            message: "Your payment was not successful, please try again.",
          });
          break;
        default:
          notification.error({
            message: "Something went wrong.",
          });
          break;
      }
    }
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === "card_error" || error.type === "validation_error") {
    // setMessage(error.message);
    // } else {
    // setMessage("An unexpected error occurred.");
    // }
    // onChangeStatusPaymentIntent(paymentIntent.status, paymentIntent);
    setIsLoading(false);
  };

  return { stripe, isLoading, elements, handleSubmit };
};
