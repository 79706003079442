import React, { useEffect, useState } from "react";
import {Avatar, Button, Card, Spin, Timeline, Space, Tooltip, Modal, List, Image, notification} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {useNavigate, useSearchParams} from "react-router-dom";
import { Task } from "../../../constants/interface/task";
import {
  useTaskPaymentWithPromoteCode,
  useTaskTraction,
} from "../../../pages/Merchant/ProjectPage/api";
import {
  useTaskCompliance,
  useTaskComplianceSummary,
} from "../../../pages/Merchant/UploadDocument/api";
import { useDebounce } from "../../../utils";

import { SyncOutlined } from "@ant-design/icons";
import { StripePaymentModal } from "../../../pages/Merchant/ProjectPage/payment-modal";
import { useAcceptQuotation } from "hooks";
import { useReviseQuotation } from "hooks/RejectAdditionalTest/useReviseQuotation";
import { useFetchFile } from "hooks/File/useFetchFileDetail";
import { UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS } from "configs/Mappings";
import { parser } from "configs/parser";
import { first } from "lodash";
import expiredImage from "../../Atoms/images/Expired.png";
// import { Elements } from "@stripe/react-stripe-js";
// import { useFetchPaymentIntent } from "hooks";
// import { loadStripe } from "@stripe/stripe-js";
const InfoIcon = styled(InfoCircleOutlined)`
  color: #005eff;
`;

const TargetReviewCompletionDate = styled.div`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

const StyledListItem = styled(List.Item)`
  display: flex;
  margin-top: 30px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutrals-grey-100, #ccc) !important;
  margin-bottom: 16px;
`;

interface TaskDetailCardProps {
  task: Task;
  reload: () => void;
  rejectReasonSection?: React.ElementType | React.ReactNode;
}

export function TaskDetailCard(props: TaskDetailCardProps) {
  const { task, reload, rejectReasonSection } = props;

  const {
    acceptQuotation,
    loading: isLoadingAcceptQuotation,
  } = useAcceptQuotation(task?.id);
  const { reviseQuotation, loading: isRevising } = useReviseQuotation(task?.id);
  const fetchFile = useFetchFile();

  let navigate = useNavigate();
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [componentControl, setComponentControl] = useState({
    payment: "",
    uploadDoc: "",
    eSign: "",
  });
  const [rejectReasons, setRejectReasons] = React.useState([]);

  const [param] = useState({ taskId: task.id });
  const [isRequestPaymentModalOpen, setIsRequestPaymentModalOpen] = useState(false);
  const [defaultPaymentDetail, setDefaultPaymentDetail] = useState({
    amount: task.isReUploadPayment ? 1800: 3500,
    paymentMethod: 'card',
    currency: 'usd',
  })

  const {
    data: complianceDetail,
    retry: refetchComplianceDetail,
  } = useTaskCompliance(useDebounce(param, 250));

  const { data: complianceData } = useTaskComplianceSummary(
    useDebounce(param, 200)
  );

  const handleAcceptAdditionTest = async (additionalTestQuotationId) => {
    await acceptQuotation(additionalTestQuotationId);
    refetchComplianceDetail();
    reload();
  };
  const handleReviseAdditionalTest = async (
    additionalTestQuotationId,
    report
  ) => {
    await reviseQuotation(additionalTestQuotationId, report);
    refetchComplianceDetail();
    reload();
  };

  useEffect(() => {
    switch (task.status) {
      case "pending_payment":
        setComponentControl({
          payment: "in_progress",
          uploadDoc: "pending",
          eSign: "pending",
        });
        break;
      case "missing_documents":
        if (task.rejectReasons.length > 0) {
          setComponentControl({
            payment: "success",
            uploadDoc: "re-upload",
            eSign: "pending",
          });
        } else {
          setComponentControl({
            payment: "success",
            uploadDoc: "in_progress",
            eSign: "pending",
          });
        }

        break;
      case "waiting_for_review":

        if (task.rejectReasons.length > 0) {
          setComponentControl({
            payment: "success",
            uploadDoc: "re-upload_success",
            eSign: "pending",
          });
        } else {
          setComponentControl({
            payment: "success",
            uploadDoc: "success",
            eSign: "pending",
          });
        }

        break;
      case "pending_e_signature":
        setComponentControl({
          payment: "success",
          uploadDoc: "success",
          eSign: "in_progress",
        });
        break;
      case "conformity_certificate_expired":
        setComponentControl({
          payment: "success",
          uploadDoc: "success",
          eSign: "success",
        });
        break;
      case "completed":
        setComponentControl({
          payment: "success",
          uploadDoc: "success",
          eSign: "success",
        });
        break;
      case "rejected":
        setComponentControl({
          payment: "success",
          uploadDoc: [
            "rejected_quote_accepted",
            "rejected_request_additional_test",
          ].includes(task.rejectedStatus)
            ? "success"
            : "error",
          eSign: "pending",
        });
        break;
    }
  }, [task?.status, rejectReasons]);

  const { mutate, isLoading } = useTaskTraction();
  const {
    mutate: mutateTask,
    isLoading: isPaying,
  } = useTaskPaymentWithPromoteCode();
  const handlePayment = async (params: any) => {
    const response = await mutate({
      taskId: task.id,
      params: {
        ...params,
      },
    });
    reload();
    return response;
  };

  const [searchParams, setSearchParam] = useSearchParams();

  // const paramSourceType = searchParams.get("source_type")
  const paramRedirectStatus = searchParams.get("redirect_status")
  const paramPaymentIntent = searchParams.get("payment_intent")

  useEffect(() => {
    if (paramPaymentIntent && paramPaymentIntent !== '')  {
      if (paramRedirectStatus === 'failed') {
        notification.error({ message: "Your payment was not successful, please try again." });
      } else {
        handlePayment({
          amount: defaultPaymentDetail.amount,
          transactionNumber: paramPaymentIntent,
          paymentStatus: "successful", //paymentIntent.status,
          paymentMethod: defaultPaymentDetail.paymentMethod,
          currency: defaultPaymentDetail.currency,
        });

        setSearchParam("")
      }
    }

  }, [paramPaymentIntent])


  const handlePaymentWithCode = async (params: any) => {
    const response = await mutateTask({
      taskId: task.id,
      params: {
        ...params,
      },
    });
    reload();
    return response;
  };

  const parsedRejectReasons = async () => {
    const extra = {
      fetchDocument: fetchFile,
    };
    const mappings = UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS.filter(
      ([fieldName]) => fieldName === "rejectReasons"
    );

    const parsed = await parser(complianceDetail, mappings, extra);
    setRejectReasons([...parsed?.rejectReasons]);
  };

  React.useEffect(() => {
    if (complianceDetail) {
      parsedRejectReasons();
    }
  }, [complianceDetail])

  return (
    <CusCard
      title={<CusCardTitle>Compliance Check</CusCardTitle>}
      extra={
        <TargetReviewCompletionDate>
          <div>
            <Tooltip title="“na” means it is pending supplier’s submission. Target Review Completion Date will be calculated based on submission date.">
              <Space>
                Target Review Completion Date
                <InfoIcon />
              </Space>
            </Tooltip>
          </div>
          <div>{task.estimatedCompletionDate || "N/A"}</div>
        </TargetReviewCompletionDate>
        // <div style={{ fontWeight: "bold", fontSize: 14 }}>
        //   <div>Expected Completion Date</div>
        //   <div>
        //     {task.estimatedCompletionDate
        //       ? task.estimatedCompletionDate
        //       : "N/A"}
        //   </div>
        // </div>
      }
    >
      <h2>Job Progress</h2>
      <Info>Please complete all the tasks below</Info>

      <Timeline style={{ marginTop: 30, marginLeft: 10 }}>
        <Timeline.Item
          color="green"
          dot={
            <Avatar
              style={{
                backgroundColor:
                  componentControl.payment === "success"
                    ? "#B0D400"
                    : "#DADADA",
              }}
              size={28}
              icon={1}
            />
          }
        >
          <div style={{ marginBottom: 20, marginLeft: 18 }}>
            Complete payment for the application
            {componentControl.payment === "in_progress" && (
              <div style={{ marginLeft: 18 }}>
                <Button
                  type="primary"
                  size={`large`}
                  style={{ margin: "20px 0px" }}
                  onClick={() => setPaymentVisible(true)}
                >
                  Proceed with payment
                </Button>
              </div>
            )}
          </div>
        </Timeline.Item>
        <Timeline.Item
          color="green"
          dot={
            <Avatar
              style={{
                backgroundColor:
                  (componentControl.uploadDoc === "success" || componentControl.uploadDoc === 're-upload_success')
                    ? "#B0D400"
                    : (componentControl.uploadDoc === "error" || componentControl.uploadDoc === "re-upload")
                    ? "red"
                    : "#DADADA",
              }}
              size={28}
              icon={2}
            />
          }
        >
          <div style={{ marginBottom: 20, marginLeft: 18 }}>
            Upload required documents
            <div>
              {componentControl.uploadDoc === "error" && (
                <>
                  <div>
                    <Button
                      type="default"
                      size={`large`}
                      style={{ margin: "20px 10px 20px 0px" }}
                      onClick={() => {
                        navigate(`upload-document/${task.id}`);
                      }}
                    >
                      View documents
                    </Button>
                    {
                      task.isReUploadPayment &&
                      <Button
                        type="primary"
                        size={`large`}
                        style={{ margin: "20px 0px" }}
                        onClick={() => setIsRequestPaymentModalOpen(true)}
                        // onClick={() => {
                        //   navigate(`upload-document/${task.id}`);
                        // }}
                      >
                        Re-upload documents
                      </Button>
                    }

                  </div>

                  <Info>
                    Note: Please check reviewer’s remark and re-upload the
                    required documents
                  </Info>
                </>
              )}
              {componentControl.uploadDoc === "re-upload" && (
                <div>

                  <Button
                    type="primary"
                    size={`large`}
                    style={{margin: "20px 0px"}}
                    // onClick={() => setIsRequestPaymentModalOpen(true)}
                    onClick={() => {
                      navigate(`upload-document/${task.id}`);
                    }}
                  >
                    Re-upload documents
                  </Button>

                </div>
              )}
              {(componentControl.uploadDoc === "in_progress" ||
                componentControl.uploadDoc === "rejected") && (
                <>
                  <Button
                    type="primary"
                    size={`large`}
                    style={{margin: "20px 0px"}}
                    onClick={() => {
                      navigate(`upload-document/${task.id}`);
                    }}
                  >
                    Upload documents
                  </Button>
                  <Info>
                    {componentControl.uploadDoc === "rejected"
                      ? `Note: Please check reviewer’s remark and re-upload the required documents`
                      : `Note: please click into the documents and re-upload the
                    required documents`}
                  </Info>
                </>
              )}

              {componentControl.uploadDoc === "success" && (
                <>
                  <Button
                    type="primary"
                    ghost
                    size={`large`}
                    style={{ margin: "20px 0px" }}
                    onClick={() => {
                      navigate(`upload-document/${task.id}`);
                    }}
                  >
                    View documents
                  </Button>
                </>
              )}

              {componentControl.uploadDoc === "re-upload_success" && (
                <>
                  <Button
                    type="primary"
                    ghost
                    size={`large`}
                    style={{ margin: "20px 0px" }}
                    onClick={() => {
                      navigate(`upload-document/${task.id}`);
                    }}
                  >
                    View re-upload documents
                  </Button>
                </>
              )}
            </div>
          </div>
        </Timeline.Item>

        {task.status === "waiting_for_review" && (
          <Timeline.Item
            color="green"
            dot={
              <Spin
                style={{ fontSize: 24 }}
                indicator={<SyncOutlined style={{ fontSize: 24 }} spin />}
              />
            }
          >
            <div style={{ marginBottom: 20, marginLeft: 18 }}>
              Waiting for Review
            </div>
          </Timeline.Item>
        )}

        {/*<Timeline.Item*/}
        {/*  color="green"*/}
        {/*  dot={*/}
        {/*    <Avatar*/}
        {/*      style={{*/}
        {/*        backgroundColor:*/}
        {/*          componentControl.eSign === "success" ? "#B0D400" : "#DADADA",*/}
        {/*      }}*/}
        {/*      size={28}*/}
        {/*      icon={3}*/}
        {/*    />*/}
        {/*  }*/}
        {/*>*/}
        {/*  <div style={{ marginBottom: 20, marginLeft: 18 }}>*/}
        {/*    Sign conformity certificate*/}
        {/*    {componentControl.eSign === "in_progress" && (*/}
        {/*      <div>*/}
        {/*        <Button*/}
        {/*          type="primary"*/}
        {/*          size={`large`}*/}
        {/*          style={{ margin: "20px 0px", padding: "0px 30px" }}*/}
        {/*          onClick={() => {*/}
        {/*            navigate(`${task.id}/doc/${complianceData.id}`);*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          Sign*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*    {componentControl.eSign === "success" && (*/}
        {/*      <div>*/}
        {/*        <Button*/}
        {/*          type="primary"*/}
        {/*          ghost*/}
        {/*          size={`large`}*/}
        {/*          style={{ margin: "20px 0px", padding: "0px 30px" }}*/}
        {/*          onClick={() => {*/}
        {/*            navigate(`${task.id}/doc/${complianceData.id}`);*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          View DOC*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</Timeline.Item>*/}
      </Timeline>

      <h2 style={{ marginTop: 30 }}>Expiry Date</h2>
      <div>{task.expiryDate ? task.expiryDate : "N/A"}</div>
      <h2 style={{ marginTop: 30 }}>Promote Code</h2>
      <div>{task.promoteCode ? task?.promoteCode : "N/A"}</div>
      {
        complianceDetail && complianceDetail.reviewer &&
        <StyledListItem>
          <h2>
            <Image
              preview={false}
              style={{
                margin: "-10px 10px 0px 0px",
                width: "36px",
                height: "100%",
                float: 'left'
              }}
              src={expiredImage}
            />
            Review remark</h2>
          <Space>
            {
              task.taskType === 'cpcGccReview' ?
                <div
                  style={{overflowWrap: 'anywhere', whiteSpace: 'break-spaces'}}>{complianceDetail.reviewer.reviewerConformityCertificateRemark ? complianceDetail.reviewer.reviewerConformityCertificateRemark : "N/A"}</div>
                :
                <div
                  style={{overflowWrap: 'anywhere',  whiteSpace: 'break-spaces'}}>{complianceDetail.reviewer.reviewerTestReportRemark ? complianceDetail.reviewer.reviewerTestReportRemark : "N/A"}</div>
            }
          </Space>
        </StyledListItem>
      }
      {(componentControl.payment === "in_progress" || task.isReUploadPayment) && (
        <StripePaymentModal
          taskId={task.id}
          task={task}
          paymentDetail={defaultPaymentDetail}
          setPaymentDetail={setDefaultPaymentDetail}
          // clientSecret={paymentIntent.client_secret}
          // paymentDetails={paymentIntent.metadata}
          paymentVisible={paymentVisible}
          setPaymentVisible={setPaymentVisible}
          isLoading={isLoading || isPaying}
          handlePayment={handlePayment}
          handlePaymentPromoteCode={handlePaymentWithCode}
          onPaymentSuccess={() => {
            props.reload();
          }}
        />
      )}
      {React.isValidElement(rejectReasonSection) ? (
        <div style={{ marginTop: 30 }}>
          {React.cloneElement(rejectReasonSection, {
            compliance: {
              ...complianceDetail,
              rejectReasons,
            },
            task: task,
            acceptQuotation: handleAcceptAdditionTest,
            isAccepting: isLoadingAcceptQuotation,
            reviseQuotation: handleReviseAdditionalTest,
            isRevising,
          } as any)}
        </div>
      ) : undefined}


      <Modal title="Settle payment"
             visible={isRequestPaymentModalOpen}
             footer={null}
             onCancel={() => setIsRequestPaymentModalOpen(false)}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <p>Please settle payment for resubmission.</p>
        <p>Cost for resubmission is <span style={{textDecoration: "line-through"}}>35 USD</span> 18 USD</p>
        <Button
          size={`large`}
          type={`primary`}
          htmlType="submit"
          onClick={() => {
            setPaymentVisible(true)
            setIsRequestPaymentModalOpen(false)
          }}
          // loading={isUpdateLoading}
        >
          Re-upload documents
        </Button>
      </Modal>
    </CusCard>
  );
}

const CusCard = styled(Card)`
  width: 100%;
  padding: 6px 24px 0px 24px;
  border-radius: 15px;
  position: relative;

  .ant-card-body {
    padding: 20px 0px;
  }

  .ant-card-head {
    padding: 0px 0px;
  }
`;

const Info = styled.div`
  color: #777777;
`;

const CusCardTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
`;
