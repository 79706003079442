import React from "react";
import { PRODUCT_STATUS } from "pages/Reviews/constants";
import {
  useTransformSelection,
  useStaticProductCategories,
  useStaticExportMarkets,
  useSubCategories,
} from "context/static-data-context";
export const withFiltersOptions = (
  WrappedComponent: React.ReactNode | any
) => () => {
  const WithFilters = (props: any, ref: any) => {
    const transformSelection = useTransformSelection();
    const productCategories = useStaticProductCategories();
    const exportMarkets = useStaticExportMarkets();
    const subCategories = useSubCategories();
    const Filters = {
      productCategories: transformSelection(productCategories),
      status: PRODUCT_STATUS,
      exportMarkets: transformSelection(exportMarkets),
      subCategories: transformSelection(subCategories).sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
      // testPlanStatus: TEST_PLAN_STATUS,
    };

    return (
      <>
        <WrappedComponent filters={Filters} />
      </>
    );
  };

  WithFilters.displayName = `withFiltersOptions(${WrappedComponent})`;
  return <WithFilters />;
};

export const useFiltersOptions = () => {
  const transformSelection = useTransformSelection();
  const productCategories = useStaticProductCategories();
  const exportMarkets = useStaticExportMarkets();
  const subCategories = useSubCategories();
  const Filters = {
    productCategories: transformSelection(productCategories),
    status: PRODUCT_STATUS,
    exportMarkets: transformSelection(exportMarkets),
    subCategories: transformSelection(subCategories).sort((a, b) =>
      a.label.localeCompare(b.label)
    ),
    // testPlanStatus: TEST_PLAN_STATUS,
  };
  return Filters;
};
