import React, { SyntheticEvent } from "react";
import { IBaseField, ICheckbox } from "../FormRenderer/type";
import { Checkbox as AntdCheckbox, CheckboxProps, Form } from "antd";
import { isNil } from "lodash";
import styled from "@emotion/styled";

type ICheckboxProps = {
  field: ICheckbox<IBaseField>;
  value: any;
  onChange: (event: SyntheticEvent) => void;
  disabled?: boolean;
};

const StyledCheckboxGroup = styled(AntdCheckbox.Group, {
  shouldForwardProp: (prop) => !["direction"].includes(prop),
})<
  Partial<
    CheckboxProps & {
      direction?: "horizontal" | "vertical";
    }
  >
>`
  display: flex;
  flex-direction: ${(prop) =>
    prop.direction === "vertical" ? "column" : "row"};
  gap: 16px;
`;

const Checkbox: React.FC<ICheckboxProps> = ({
  field,
  value,
  disabled,
  onChange,
}) => {
  const isReadonly = React.useMemo(() => {
    const { readonly } = field;
    if (readonly === null || readonly === undefined) return false;
    return readonly;
  }, [field.readonly]);

  const form = Form.useFormInstance();
  // const disabled = React.useMemo(() => {
  //   if (field.shouldDisabled)
  //     return field.shouldDisabled(
  //       field as IBaseField,
  //       value,
  //       form.getFieldsValue()
  //     );
  //   return false;
  // }, [field, value]);

  const options = React.useMemo(() => {
    return field.options.reduce((acc, option) => {
      if (
        option.showWhen &&
        option.showWhen(
          field as IBaseField,
          option,
          value,
          form.getFieldsValue()
        )
      ) {
        acc.push({ ...option, disabled });
      }
      if (isNil(option.showWhen)) {
        acc.push(option);
      }
      return acc;
    }, []);
  }, [field, value, disabled]);

  return (
    <>
      <StyledCheckboxGroup
        disabled={disabled}
        direction={field.direction}
        options={options}
        value={value}
        onChange={(checkedValue) => {
          if (isReadonly) return;
          onChange({
            target: {
              name: field.name,
              value: checkedValue,
            },
          } as any);
        }}
      ></StyledCheckboxGroup>
    </>
  );
};

export default Checkbox;
