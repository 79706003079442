import React from "react";
import { EnumFieldType, IBaseField, IField } from "./type";
import { Col, Form, Row } from "antd";
import { get, pick } from "lodash";
import Control from "./Control";
import { useFormConfigContext } from "./context";

type FormRenderProps = {
  fields: IField[];
};

const FormRender: React.FC<FormRenderProps> = ({ fields }) => {
  const { FACTORY } = useFormConfigContext();
  const form = Form.useFormInstance();
  const values = form.getFieldsValue();

  const shownFields = React.useMemo(() => {
    return fields.filter((field) => {
      if (field.showWhen) {
        return field.showWhen(
          field as IBaseField,
          get(values, field.name),
          values
        );
      }
      return true;
    });
  }, [values, form]);

  const renderElement = (field) => {
    const formItemsProps = pick(field, [
      "name",
      "label",
      "rules",
      "required",
      "dependencies",
    ]);
    if ([EnumFieldType.DIVIDER].includes(field.type)) {
      return <Control field={field} Component={FACTORY[field.type]}></Control>;
    }
    if ([EnumFieldType.CUSTOMIZED, EnumFieldType.LIST].includes(field.type)) {
      return (
        <Form.List name={field.name} rules={field.rules}>
          {(formListField, operation, meta) => {
            return (
              <Control
                field={field}
                value={form.getFieldValue(field.name)}
                operation={operation}
                meta={meta}
                Component={
                  FACTORY[field.dynamic ? field.factoryType : field.type]
                }
              ></Control>
            );
          }}
        </Form.List>
      );
    }

    return (
      <Form.Item
        preserve
        {...formItemsProps}
        label={formItemsProps.label || undefined}
        name={field.name || undefined}
      >
        <Control field={field} Component={FACTORY[field.type]}></Control>
      </Form.Item>
    );
  };

  return (
    <div>
      {shownFields.map((field) => {
        const colProps = pick(field, ["col"]);
        return (
          <Row key={field.fieldId}>
            <Col style={{ width: "100%" }} {...colProps.col}>
              {renderElement(field)}
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default FormRender;
