import React from "react";
import {
  Tabs as AntdTabs,
  TabsProps as AntdTabProps,
  TabPaneProps,
} from "antd";

const { TabPane } = AntdTabs;

interface TabsProps extends AntdTabProps {
  tabs: Array<
    TabPaneProps & {
      content: React.ReactNode;
      key?: string;
    }
  >;
}
const Tabs = ({ tabs, ...props }: TabsProps) => {
  return (
    <AntdTabs {...props}>
      {tabs.map(({ content, key, ...tab }, index) => {
        return (
          <TabPane key={key || index} {...tab}>
            {content}
          </TabPane>
        );
      })}
    </AntdTabs>
  );
};

export default Tabs;
