import React from "react";
import { Space, SpaceProps, Statistic, StatisticProps } from "antd";

type Props = {
  stats: Array<
    StatisticProps & {
      icon?: React.ReactChild;
      component?: React.ReactNode;
      total?: string | number;
      onClick: () => void;
    }
  >;
  containerProps?: SpaceProps;
};
const StatisticsGroup = ({ containerProps, stats = [] }: Props) => {
  return (
    <Space {...containerProps}>
      {stats.map(({ component, ...stat }, index) => {
        const Component: any = component || Statistic;
        return <Component key={index} {...stat}></Component>;
      })}
    </Space>
  );
};

export default StatisticsGroup;
