export enum Role {
  "BUYER" = "buyer_user",
  "MERCHANT" = "merchant_user",
  "REVIEWER" = "reviewer_user",
}

export enum Action {
  "LIST" = "List",
  "VIEW" = "view",
  "CREATE" = "create",
  "EDIT" = "edit",
  "DELETE" = "delete",
  "UPLOAD" = "upload",
}

export enum Resource {
  "JOBS" = "jobs",
  "PRODUCTS" = "products",
}

export const RULES = {
  [Role.BUYER]: [
    {
      action: [Action.VIEW],
      resource: Resource.JOBS,
    },
    {
      action: [Action.VIEW],
      resource: `${Resource.JOBS}.expiryDate`,
    },
    {
      action: [Action.VIEW],
      resource: `${Resource.JOBS}.form.product`,
    },
    {
      action: [Action.VIEW],
      resource: `${Resource.JOBS}.form.testPlan`,
    },
    {
      action: [Action.VIEW],
      resource: `${Resource.JOBS}.form`,
      fields: [
        "productImage.reviewer.remarks",
        "productImage.reviewer.documents",
        "productImage.reviewer.isReuploadProductImage",
        "testReport.documents",
        "testReport.reviewer.documents",
        "testReport.reviewer.remarks",
        "testReport.reviewer.isReuploadTestReport",
        "productSpecificationLabellingArtworkPackagingArtwork.documents",
        "productSpecificationLabellingArtworkPackagingArtwork.reviewer.documents",
        "productSpecificationLabellingArtworkPackagingArtwork.reviewer.remarks",
        "productSpecificationLabellingArtworkPackagingArtwork.reviewer.isReuploadProductSpecificationLabellingArtworkPackagingArtwork",
        "conformityCertificate.documents",
        "conformityCertificate.remarks",
        "conformityCertificate.reviewer.remarks",
        "conformityCertificate.reviewer.documents",
        "conformityCertificate.reviewer.reportData",
      ],
    },
  ],
  [Role.REVIEWER]: [
    {
      action: [Action.EDIT, Action.CREATE, Action.UPLOAD],
      resource: Resource.JOBS,
    },
    {
      action: [Action.EDIT, Action.CREATE, Action.UPLOAD],
      resource: `${Resource.JOBS}.expiryDate`,
    },
    {
      action: [Action.EDIT, Action.CREATE, Action.UPLOAD],
      resource: `${Resource.JOBS}.form.product`,
    },
    {
      action: [Action.EDIT, Action.CREATE, Action.UPLOAD],
      resource: `${Resource.JOBS}.form.testPlan`,
    },
    {
      action: [Action.CREATE, Action.EDIT],
      resource: `${Resource.JOBS}.form`,
      fields: [
        "productImage.reviewer.remarks",
        "productImage.reviewer.documents",
        "productImage.reviewer.isReuploadProductImage",
        "testReport.documents",
        "testReport.reviewer.documents",
        "testReport.reviewer.remarks",
        "testReport.reviewer.isReuploadTestReport",     "" +
        "productSpecificationLabellingArtworkPackagingArtwork.documents",
        "productSpecificationLabellingArtworkPackagingArtwork.reviewer.documents",
        "productSpecificationLabellingArtworkPackagingArtwork.reviewer.remarks",
        "productSpecificationLabellingArtworkPackagingArtwork.reviewer.isReuploadProductSpecificationLabellingArtworkPackagingArtwork",
        "conformityCertificate.documents",
        "conformityCertificate.remarks",
        "conformityCertificate.reviewer.remarks",
        "conformityCertificate.reviewer.documents",
        "conformityCertificate.reviewer.reportData",
      ],
    },
  ],
  [Role.MERCHANT]: [
    {
      action: [Action.VIEW, Action.EDIT],
      resource: Resource.JOBS,
    },
  ],
};
