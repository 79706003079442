import React, { ReactNode, useCallback } from "react";
import * as auth from "auth-provider";
import { User } from "auth-provider";
import { http } from "utils/http";
import { useMount } from "utils";
import { useAsync } from "utils/use-async";
import { FullPageErrorFallback, FullPageLoading } from "components/lib";
import * as authStore from "store/auth.slice";
import { bootstrap, selectUser } from "store/auth.slice";
import { useDispatch, useSelector } from "react-redux";

export interface AuthForm {
  email: string;
  password: string;
}

export const bootstrapUser = async () => {
  let user = null;
  const token = auth.getToken();
  if (token) {
    const data = await http("users/me", { token });
    user = data;
    user.token = token;
  }

  return user;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { error, isLoading, isIdle, isError, run } = useAsync<User | null>();
  const dispatch: (...args: unknown[]) => Promise<User> = useDispatch();

  useMount(() => {
    run(dispatch(bootstrap()));
  });

  if (isIdle || isLoading) {
    return <FullPageLoading />;
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }

  return <div>{children}</div>;
};

export const useAuth = () => {
  const dispatch: (...args: unknown[]) => Promise<User> = useDispatch();
  const user = useSelector(selectUser);
  const login = useCallback(
    (form: AuthForm) => dispatch(authStore.login(form)),
    [dispatch]
  );

  const logout = useCallback(() => dispatch(authStore.logout()), [dispatch]);

  const registerWithMerchants = useCallback((data: any) => {
    return auth.registerWithMerchant(data);
  }, []);

  const verifyWithToken = useCallback((data) => {
    return auth.verifyUserWithToken(data);
  }, []);

  const forgotPassword = useCallback((email: string) => {
    return auth.forgotPassword(email);
  }, []);

  const resetPassword = useCallback(async (data) => {
    return auth.resetPassword(data);
  }, []);
  return {
    user,
    login,
    logout,
    registerWithMerchants,
    verifyWithToken,
    forgotPassword,
    resetPassword
  };
};
