import { useState, useCallback, useEffect } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useHttp } from "./http";
import {filter, isEmpty} from "lodash";

export const useFetchList = ({
  queryKey = "",
  endpoint = "",
  keywords,
  defaultPagination = { page: 1, pageSize: 10 },
  defaultSorted = {},
  defaultFiltered = {},
  options = {},
}: {
  queryKey: string;
  endpoint: string;
  keywords?: string;
  defaultPagination?: { page: number; pageSize: number };
  defaultSorted?: any;
  defaultFiltered?: any;
  options?: UseQueryOptions;
}) => {
  const client = useHttp();
  // const [search, setSearch] = useState({ searchBy: "", keyword: "" });
  const [pagination, setPagination] = useState(defaultPagination);
  const [sorted, setSorted] = useState(defaultSorted);
  const [filtered, setFiltered] = useState(defaultFiltered);

  const data = useQuery(
    [`${queryKey}`, pagination, filtered, sorted],
    async () => {
      const params: Record<any, any> = {
        ...filtered,
        ...sorted,
        keywords
      };
      const transform = Object.keys(params).reduce((acc, key) => {
        if (isEmpty(params[key])) {
          return acc;
        }
        return { ...acc, [key]: params[key] };
      }, {});

      const response = await client(`${endpoint}`, {
        data: {
          ...pagination,
          row: pagination.pageSize,
          ...transform,
        },
      });
      return response;
    },
    options as any
  );

  const onChange = useCallback(
    (nextPagination, nextFiltered, nextSorted, extra) => {
      const { action = "" } = extra;
      if (action === "paginate") {
        const { current, pageSize } = nextPagination;
        setPagination({
          page: current,
          pageSize,
        });
      }
      if (action === "sort") {
        const { field, order } = nextSorted;
        setSorted({ [field]: order });
      }
      setFiltered(nextFiltered);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPagination, setSorted, setFiltered]
  );

  useEffect(() => {
    data.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filtered, sorted, keywords]);

  return [
    data as UseQueryResult<any, any>,
    onChange,
    pagination,
    filtered,
    sorted,
  ];
};
