import React from "react";
import styled from "@emotion/styled";
import { Space, Card } from "antd";
import FormRender from "components/Molecules/form/FormRenderer";
import { Action, Resource } from "configs/rules";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid rgba(170, 170, 170, 0.3);
  border-radius: 16px;
  position: relative;
  h2,
  .title {
    margin-top: 0px;
    margin-bottom: 4px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;

const InfoCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const StyledCardFullWidth = styled(Card)`
  width: 100%;
  padding: 0;
  .ant-card-body {
    padding: 0;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.002em;
    text-align: left;
    color: #232b3a;
  }
  .caption {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #777777;
  }
`;

type TestPlanProps = {
  isAutoAssigned?: boolean;
  section: any;
  canActivate?: (resource: string, action: string) => boolean;
};

const TestPlan = ({ section, canActivate }: TestPlanProps) => {
  const canUpload = canActivate
    ? canActivate(`${Resource.JOBS}.form.testPlan`, Action.UPLOAD)
    : true;
  const fields = React.useMemo(() => {
    return section.fields.map((field) => {
      return {
        ...field,
        readonly: !canUpload,
      };
    });
  }, [canUpload]);

  return (
    <section>
      <Container>
        <InfoCardHeader>
          <Space>
            <h2>Test Plan</h2>
          </Space>
        </InfoCardHeader>
        <StyledCardFullWidth bordered={false}>
          <FormRender fields={fields}></FormRender>
        </StyledCardFullWidth>
      </Container>
    </section>
  );
};

export default TestPlan;
