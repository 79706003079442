import styled from "@emotion/styled";
import { Space, Typography } from "antd";
import Tag from "components/Atoms/tag";
import Uploader from "components/Molecules/form/Inputs/Uploader";
import moment from "moment";
import React from "react";
import RejectQuotation from "../Quotation";
import { QUOTATION_STATUS } from "pages/Reviews/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const DangerTag = styled(Tag)`
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  border-radius: 13px;
  background: var(--error-error-100, #ffebeb);
  color: var(--error-error-bg, #f55);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.06px;
`;

const Caption = styled(Typography.Text)`
  color: var(--text-text-300, #8991a0);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.028px;
  margin-bottom: 0;
`;

const DerivedText = styled(Typography.Text)`
  color: var(--text-text-400, #4e5a71);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.06px;
  margin-bottom: 0;
  white-space: break-spaces;
`;

const Title = styled(Typography.Title)`
  color: var(--text-text-600, #232b3a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.028px;
  margin-bottom: 0 !important;
`;

type IRejectReasonItem = {
  createdAt?: Date | string;
  updatedAt?: Date | string;
  // rejectReason: { value: string; label: string };
  rejectReason?: string;
  additionalDocuments?: Array<any>;
  requestTestItems?: Array<string>;
  remark?: string;
  onAcceptQuotation?: () => void;
  isAccepting?: boolean;
  status: "ACCEPTED" | "REVISED" | null;
  onReviseQuotation?: (report) => void;
  isRevising?: boolean;
  additionalTestQuotation?: any;
  hideQuotation?: boolean;
};

const RejectReasonItem: React.FC<IRejectReasonItem> = ({
  rejectReason,
  remark,
  createdAt,
  additionalDocuments,
  requestTestItems,
  onAcceptQuotation,
  onReviseQuotation,
  isRevising,
  isAccepting,
  status,
  additionalTestQuotation,
  hideQuotation,
}) => {
  const [testQuotations, rejectReasons] = React.useMemo(() => {
    const fileWithTypeTestQuotations = (additionalDocuments || []).filter(
      (file) => file.docType === "test_quotation"
    );
    const fileWithTypeRejectReasons = (additionalDocuments || []).filter(
      (file) => file.docType === "reject_reason"
    );
    return [fileWithTypeTestQuotations, fileWithTypeRejectReasons];
  }, [additionalDocuments]);

  return (
    <Container>
      <Space size={12}>
        Reject Type:
        {
          rejectReason && rejectReason?.split('|||').map(reason =>
            <DangerTag color="red">{reason}</DangerTag>)
        }
        <Space size={8}>
          <Caption>{moment(createdAt).format("DD-MM-YYYY")}</Caption>
          {/* <Caption>Amy Wong</Caption> */}
        </Space>
      </Space>
        <Space direction="vertical" size={12}>
          <Title level={5}>Reject Reason Remark</Title>
          <DerivedText>{remark || "N/A"}</DerivedText>
        </Space>
      {/*{["others", "revise"].includes(rejectReason.value) ? (*/}
      {/*  <Space direction="vertical" size={12}>*/}
      {/*    <Title level={5}>Remark</Title>*/}
      {/*    <DerivedText>{remark || "N/A"}</DerivedText>*/}
      {/*  </Space>*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    <Space direction="vertical" size={12}>*/}
      {/*      <Title level={5}>Request Test Items:</Title>*/}
      {/*      {requestTestItems.map((testItem) => {*/}
      {/*        return <DerivedText key={testItem}>{testItem}</DerivedText>;*/}
      {/*      })}*/}
      {/*    </Space>*/}
      {/*    <Space direction="vertical" size={12}>*/}
      {/*      <Title level={5}>Additional Test Quotation:</Title>*/}
      {/*      <Uploader*/}
      {/*        onChange={() => {}}*/}
      {/*        field={{*/}
      {/*          uploadButton: undefined,*/}
      {/*          readonly: true,*/}
      {/*          showUploadList: {*/}
      {/*            showRemoveIcon: false,*/}
      {/*          },*/}
      {/*          listType: "picture",*/}
      {/*        }}*/}
      {/*        value={testQuotations}*/}
      {/*      ></Uploader>*/}
      {/*    </Space>*/}
      {/*    <RejectQuotation*/}
      {/*      hideQuotation={hideQuotation}*/}
      {/*      onRevise={onReviseQuotation}*/}
      {/*      onAccept={onAcceptQuotation}*/}
      {/*      isRevising={isRevising}*/}
      {/*      isAccepting={isAccepting}*/}
      {/*      status={status as QUOTATION_STATUS}*/}
      {/*      additionalTestQuotation={additionalTestQuotation}*/}
      {/*    ></RejectQuotation>*/}
      {/*  </>*/}
      {/*)}*/}
      <>
        {/*<Space direction="vertical" size={12}>*/}
        {/*  <Title level={5}>Request Test Items:</Title>*/}
        {/*  {requestTestItems.map((testItem) => {*/}
        {/*    return <DerivedText key={testItem}>{testItem}</DerivedText>;*/}
        {/*  })}*/}
        {/*</Space>*/}
        {/*<Space direction="vertical" size={12}>*/}
        {/*  <Title level={5}>Additional Test Quotation:</Title>*/}
        {/*  <Uploader*/}
        {/*    onChange={() => {}}*/}
        {/*    field={{*/}
        {/*      uploadButton: undefined,*/}
        {/*      readonly: true,*/}
        {/*      showUploadList: {*/}
        {/*        showRemoveIcon: false,*/}
        {/*      },*/}
        {/*      listType: "picture",*/}
        {/*    }}*/}
        {/*    value={testQuotations}*/}
        {/*  ></Uploader>*/}
        {/*</Space>*/}
        {/*<RejectQuotation*/}
        {/*  hideQuotation={hideQuotation}*/}
        {/*  onRevise={onReviseQuotation}*/}
        {/*  onAccept={onAcceptQuotation}*/}
        {/*  isRevising={isRevising}*/}
        {/*  isAccepting={isAccepting}*/}
        {/*  status={status as QUOTATION_STATUS}*/}
        {/*  additionalTestQuotation={additionalTestQuotation}*/}
        {/*></RejectQuotation>*/}
      </>
      <Space direction="vertical" size={12}>
        <Title level={5}>Additional Document:</Title>
        <Uploader
          onChange={() => {}}
          field={{
            uploadButton: undefined,
            readonly: true,
            showUploadList: {
              showRemoveIcon: false,
            },
            listType: "picture",
          }}
          value={rejectReasons}
        ></Uploader>
      </Space>
    </Container>
  );
};

export default RejectReasonItem;
