import { useHttp } from "utils/http";
import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils";

export const useTaskCompliance = (param: Partial<any>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();

  const fetchTaskCompliance = useCallback(
    () => client(`tasks/${param.taskId}/compliance`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchTaskCompliance(), {
      retry: fetchTaskCompliance,
    });
  }, [param, run, fetchTaskCompliance]);

  return result;
};

export const useTaskComplianceSummary = (param: Partial<any>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();

  const fetchTaskComplianceSummary = useCallback(
    () => client(`tasks/${param.taskId}/compliance_summary`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchTaskComplianceSummary(), {
      retry: fetchTaskComplianceSummary,
    });
  }, [param, run, fetchTaskComplianceSummary]);

  return result;
};

export const useUpdateTaskCompliance = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = ({
    params,
    taskId,
  }: {
    params?: Partial<any>;
    taskId: string;
  }) => {
    return run(
      client(`tasks/${taskId}/compliance`, {
        data: cleanObject(params || {}),
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
