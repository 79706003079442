export const downloadFile = (fileData: any, fileName: string) => {
  // other browsers
  const blob: Blob = new File([fileData], fileName);

  const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
  const blobUrl: string = window.URL.createObjectURL(blob);

  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  }, 100);
};
