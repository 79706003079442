import React, { ReactNode } from "react";
import { Content } from "antd/es/layout/layout";

const Page = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: any;
}) => {
  return (
    <Content className={className} style={{ padding: "20px 20px 0px 20px" }}>
      {children}
    </Content>
  );
};

export default Page;
