import React, { SyntheticEvent } from "react";
import { IField } from "./type";
import { isString, omit } from "lodash";
import { Form, FormListOperation, Space } from "antd";
import styled from "@emotion/styled";
import FootNote from "../Inputs/FootNote";

const Controller = styled(Space)`
  width: 100%;
  > .ant-space-item {
    width: 100%;
  }
`;

type ControlProps = {
  field: IField;
  Component: React.ElementType;
  value?: any;
  onChange?: ReturnType<(event: SyntheticEvent) => void>;
  operation?: FormListOperation;
  meta?: {
    errors: React.ReactNode[];
    warnings: React.ReactNode[];
  };
};

const Control: React.FC<ControlProps> = ({
  field,
  Component,
  value,
  onChange,
  operation,
  meta,
}) => {
  const form = Form.useFormInstance();
  const values = form.getFieldsValue();
  const inputProps = React.useMemo(() => {
    const props = omit(field, [
      "name",
      "label",
      "rules",
      "fieldId",
      "showWhen",
      "shouldDisabled",
      "shouldHiddenRemove",
      "footNote",
      "footNoteShowWhen",
      "col",
    ]);
    return props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value]);

  const shouldDisabled = React.useMemo(() => {
    if (field.shouldDisabled) {
      return field.shouldDisabled(field as any, value, form.getFieldsValue());
    }
    return false;
  }, [values, value, field]);

  const shouldHiddenRemove = React.useMemo(() => {
    if (field.shouldHiddenRemove) {
      return field.shouldHiddenRemove(field as any, value, form.getFieldsValue());
    }
    return false;
  }, [values, value, field]);

  return (
    <Controller direction="vertical">
      {isString(field.footNote) ? (
        <FootNote value={field.footNote}></FootNote>
      ) : (
        field.footNote
      )}
      <Component
        disabled={shouldDisabled}
        hiddenRemove={shouldHiddenRemove}
        value={value}
        onChange={onChange}
        field={field}
        operation={operation}
        meta={meta}
        {...inputProps}
      ></Component>
    </Controller>
  );
};

export default Control;
