import {
  Form,
  Input,
  DatePicker,
  InputNumber,
  Modal,
  ModalProps,
  FormInstance,
} from "antd";
import PhoneInput from "components/Atoms/input/PhoneInput";
import { Rule } from "rc-field-form/lib/interface";
import React from "react";

enum FieldType {
  String = "string",
  Number = "number",
  Phone = "phone",
  Email = "email",
  Date = "date",
  DateTime = "datetime",
  TextArea = "textarea",
}

type Field = {
  name: string;
  label: string;
  type: FieldType;
  rules: Rule[];
  fieldProps?: Record<string, any>;
  element?: React.ReactNode;
};

type DynamicFormProps = {
  fields: Field[];
  initialValues: Record<string, any>;
  rawData: Record<string, any>;
  formName: string;
};

const getElementFieldByType = (field: Field): any => {
  switch (field.type) {
    case FieldType.Phone:
      return PhoneInput;
    case FieldType.Number:
      return InputNumber;
    case FieldType.Date:
    case FieldType.DateTime:
      return DatePicker;
    case FieldType.TextArea:
      return Input.TextArea;
    default:
      return Input;
  }
};
export const DynamicFormModal = (
  props: Partial<
    DynamicFormProps &
      Omit<ModalProps, "onOk"> & {
        onOk: (form: FormInstance) => void;
      }
  >
) => {
  const {
    fields,
    initialValues,
    rawData,
    formName,
    onOk,
    onCancel,
    ...modalProps
  } = props;
  const [form] = Form.useForm();
  const renderField = React.useCallback((field: Field) => {
    const Field = field.element || getElementFieldByType(field);
    return (
      <Form.Item
        key={field.name}
        name={field.name}
        label={field.label}
        rules={field.rules || []}
      >
        <Field style={{ width: "100%" }} {...field.fieldProps}></Field>
      </Form.Item>
    );
  }, []);
  const renderForm = React.useCallback(() => {
    return (
      <Form
        layout="vertical"
        name={formName}
        form={form}
        initialValues={{ ...initialValues }}
      >
        {fields?.map(renderField)}
      </Form>
    );
  }, [fields, form, formName, initialValues, renderField]);

  React.useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({ ...initialValues });
    }
  }, [form, initialValues, props.visible]);

  return (
    <Modal
      {...modalProps}
      destroyOnClose
      onOk={() => {
        onOk && onOk(form);
      }}
      onCancel={(e) => {
        onCancel && onCancel(e);
      }}
    >
      {renderForm()}
    </Modal>
  );
};
