import React, {useState} from "react";
import {Button, Card, Col, Divider, Form, Modal, Row} from "antd";
import { useNavigate } from "react-router-dom";

import CusPageHeader from "components/Molecules/header";
import { MainLayout } from "components/Organisms/layouts";
import ProductDescription from "../../../components/Molecules/form/Sections/ProductDescription";
import TestPlan from "../../../components/Molecules/form/Sections/TestPlan";
import {DOCUMENT_REVIEW_UPLOAD_DOCUMENTS_CONFIGS, UPLOAD_DOCUMENTS_CONFIGS} from "configs/features";
import { Section } from "../../../components/Molecules/form/Sections";
import Page from "components/Molecules/page";
import ComplianceProvider, { useComplianceContext } from "context/Compliance";
import FormConfigProvider from "components/Molecules/form/FormRenderer/context";
import RejectReasonSection from "pages/Reviews/components/RejectReason";
import { useAcceptQuotation } from "hooks";
import { useReviseQuotation } from "hooks/RejectAdditionalTest/useReviseQuotation";
import ReportData from "pages/Reviews/views/ComplianceCheck/components/ReportData";

const FACTORY_SECTION = {
  product_description: ProductDescription,
  test_plan: TestPlan,
  reject_reason: RejectReasonSection,
};

const WalmartUploadDocuments = () => {
  const {
    product,
    compliance,
    isFetched,
    isUpdateLoading,
    saveChange,
    taskId,
    refetchCompliance,
  } = useComplianceContext();

  const taskType = compliance?.task?.taskType;

  const { acceptQuotation, loading: isAccepting } = useAcceptQuotation(taskId);
  const { reviseQuotation, loading: isRevising } = useReviseQuotation(taskId);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [, setFieldsChanged] = React.useState([]);
  const [isRequestPaymentModalOpen, setIsRequestPaymentModalOpen] = useState(false);
  const onSubmit = async (values, isDraft: boolean) => {
    await saveChange(values, isDraft);
    navigate(-1);
  };

  const handleProcessPayment = (e) => {
    e.preventDefault()
    setIsRequestPaymentModalOpen(true)

  }

  const handleAcceptAdditionTest = async (additionalTestQuotationId) => {
    await acceptQuotation(additionalTestQuotationId);
    refetchCompliance();
    navigate(-1);
  };
  const handleReviseAdditionalTest = async (
    additionalTestQuotationId,
    report
  ) => {
    await reviseQuotation(additionalTestQuotationId, report);
    refetchCompliance();
    navigate(-1);
  };

  const shouldDisable = React.useMemo(() => {
    return !["missing_documents", "rejected"].includes(product?.status);
  }, [product]);

  React.useEffect(() => {
    form.setFieldsValue({
      product: {
        isReUploadPayment: compliance?.task?.isReUploadPayment,
        ...product,
      },
      ...compliance,
    });
  }, [isFetched]);

  React.useEffect(() => {
    if (isFetched) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        product: {
          ...product,

        },
      });
    }
  }, [product]);

  return (
    <MainLayout>
      <CusPageHeader
        title={
          <div>
            Compliance Check <Divider type={`vertical`} />{" "}
            <span style={{ color: "#777777" }}>Requirement Detail</span>
          </div>
        }
        onBack={() => navigate(-1)}
      ></CusPageHeader>
      <Page>
        {isFetched && (
          <Form
            onFieldsChange={(fieldChanged, allFields) => {
              //Trigger Form Changed Value.
              //Force FormRender to re-render with data changed.
              setFieldsChanged([...allFields]);
            }}
            name="upload-document"
            onFinish={(values) => {
              onSubmit(values, false);
            }}
            preserve
            layout="vertical"
            form={form}
            initialValues={{
              product: {
                ...product,
              },
              isReUploadPayment: false,
              ...compliance,
            }}
          >
            <FormConfigProvider
              form={form}
              factory={{
                REPORT_DATA: ReportData,
              }}
            >
              <Card>
                {
                  taskType === 'cpcGccReview' ?
                    UPLOAD_DOCUMENTS_CONFIGS.walmart.sections.map(
                      (section, index) => {
                        const Element = FACTORY_SECTION[section.sectionType];
                        return (
                          <Form.Item key={index} name={section.name || undefined}>
                            {Element ? (
                              <Element
                                acceptQuotation={handleAcceptAdditionTest}
                                reviseQuotation={handleReviseAdditionalTest}
                                isAccepting={isAccepting}
                                isRevising={isRevising}
                                compliance={compliance}
                                task={product}
                                section={section}
                              ></Element>
                            ) : (
                              <Section section={section as any}></Section>
                            )}
                          </Form.Item>
                        );
                      }
                    ) :
                    DOCUMENT_REVIEW_UPLOAD_DOCUMENTS_CONFIGS.walmart.sections.map(
                      (section, index) => {
                        const Element = FACTORY_SECTION[section.sectionType];
                        return (
                          <Form.Item key={index} name={section.name || undefined}>
                            {Element ? (
                              <Element
                                acceptQuotation={handleAcceptAdditionTest}
                                reviseQuotation={handleReviseAdditionalTest}
                                isAccepting={isAccepting}
                                isRevising={isRevising}
                                compliance={compliance}
                                task={product}
                                section={section}
                              ></Element>
                            ) : (
                              <Section section={section as any}></Section>
                            )}
                          </Form.Item>
                        );
                      }
                    )
                }
              </Card>
            </FormConfigProvider>
            <Row
              justify="space-between"
              align="middle"
              style={{ margin: "40px 0" }}
            >
              {
                compliance.task.isReUploadPayment ?
                  <Col flex={"none"}>
                    {/*<Button*/}
                    {/*  // disabled={shouldDisable}*/}
                    {/*  size={`large`}*/}
                    {/*  type={`primary`}*/}
                    {/*  htmlType="submit"*/}
                    {/*  loading={isUpdateLoading}*/}
                    {/*  onClick={handleProcessPayment}*/}
                    {/*>*/}
                    {/*  Re-upload documents*/}
                    {/*</Button>*/}
                  </Col>
                  :
                  <>
                    <Col flex={"none"}>
                      <Button
                        disabled={shouldDisable}
                        size={`large`}
                        type={`primary`}
                        htmlType="submit"
                        loading={isUpdateLoading}
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col flex={"none"}>
                      <Button
                        disabled={shouldDisable}
                        size={`large`}
                        loading={isUpdateLoading}
                        onClick={() => onSubmit(form.getFieldsValue(), true)}
                      >
                        Save draft
                      </Button>
                    </Col>
                  </>
              }
            </Row>
          </Form>
        )}
      </Page>

      <Modal title="Settle payment"
             visible={isRequestPaymentModalOpen}
             footer={null}
             // onOk={handleOk}
             // onCancel={handleCancel}
      >
        <p>Please settle payment for resubmission.</p>
        <p>Cost for resubmission is <span style={{textDecoration: "line-through"}}>35 USD</span> 18 USD</p>
        <Button
          size={`large`}
          type={`primary`}
          htmlType="submit"
          onClick={() => {
            navigate(-1);
          }}
          // loading={isUpdateLoading}
        >
          Re-upload documents
        </Button>
      </Modal>
    </MainLayout>
  );
};

const WrapContext = (props) => {
  return (
    <ComplianceProvider>
      <WalmartUploadDocuments {...props}></WalmartUploadDocuments>
    </ComplianceProvider>
  );
};
export default WrapContext;
