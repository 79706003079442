import React from "react";
import styled from "@emotion/styled";

const Content = styled.p`
  color: var(--text-text-400, #4e5a71);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.06px;
`;

const FootNote = ({ value }) => {
  return <Content>{value}</Content>;
};

export default FootNote;
