// 在真实环境中，如果使用firebase这种第三方auth服务的话，本文件不需要开发者开发
export interface User {
  id: number;
  name: string;
  username: string;
  email: string;
  title: string;
  organization: string;
  token: string;
  userRole: string;
  isFirstLogin: number; // 1 is true, 0 is false
}

const apiUrl = process.env.REACT_APP_API_URL;

const localStorageKey = "__auth_provider_token__";

export const getToken = () => window.localStorage.getItem(localStorageKey);

export const handleUserResponse = ({ data }: { data: User }) => {
  window.localStorage.setItem(localStorageKey, data.token || "");
  return data;
};

export const login = (data: { email: string; password: string }) => {
  return fetch(`${apiUrl}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.ok) {
      return handleUserResponse(await response.json());
    } else {
      return Promise.reject(await response.json());
    }
  });
};

export const register = (data: { email: string; password: string }) => {
  return fetch(`${apiUrl}/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(await response.json());
    }
  });
};

export const registerWithMerchant = (data: any) => {
  return fetch(`${apiUrl}/merchants`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(await response.json());
    }
  });
};
export const verifyUserWithToken = (data: any) => {
  return fetch(`${apiUrl}/users/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(await response.json());
    }
  });
};

export const forgotPassword = (email: string) => {
  return fetch(`${apiUrl}/users/forgot-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(await response.json());
    }
  });
};

export const resetPassword = (data: { token: string; password: string }) => {
  return fetch(`${apiUrl}/users/password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(await response.json());
    }
  });
};

export const logout = async () =>
  window.localStorage.removeItem(localStorageKey);
