import React from "react";
import { Card } from "antd";
import { productStatus } from "../../Atoms/status/common-status";
import styled from "@emotion/styled";
import { Task } from "../../../constants/interface/task";

interface TaskIndexCardProps {
  task: Task;
}

export function TaskIndexCard(props: TaskIndexCardProps) {
  const { task } = props;
  return (
    <CusCard
      title={task?.taskNumber}
      extra={productStatus(task?.status, task?.rejectedStatus)}
    >
      <div className={"index-card-body"}>
        <div>Assign Reviewer : {task?.assignReviewer || "N/A"}</div>
        {/*<div>ECD: {task?.estimatedCompletionDate || "N/A"}</div>*/}
        <div>Created: {task?.createdAt}</div>
      </div>
    </CusCard>
  );
}

const CusCard = styled(Card)`
  width: 460px;
  padding: 6px 24px 0px 24px;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;

  .ant-card-body {
    padding: 20px 0px;
  }

  .ant-card-head {
    padding: 0px;
  }

  .index-card-body {
    color: #777777;
  }
`;
