import { parserDocument, parserRejectReason } from "configs/parsers";
import { isString } from "lodash";
import get from "lodash/get";

const baseParser = (name) => (data) => {
  const value = get(data, name);
  return value;
};

const baseParserSelection = (name) => (data) => {
  const value = baseParser(name)(data);

  if (Array.isArray(value) && !!value.length) return value[0];
  if (isString(value)) return value;
  return "conformity_certificate_required";
};

export const UPLOAD_DOCUMENTS_PRODUCT_MAPPINGS = [
  ["id", "id", baseParser, null],
  ["productName", "productName", baseParser, null],
  ["productNumber", "productNumber", baseParser, null],
  [
    "ageGrade",
    "ageGrade",
    (name) => (data, { ageGrade: ageGrades }) => {
      const value = get(data, name);
      return ageGrades.find(({ value: ageGrade }) => ageGrade === value);
    },
    null,
  ],
  ["exportMarket", "exportMarket", baseParser, null],
  ["merchant", "merchant", baseParser, null],
  ["labelledAgeGrade", "labelledAgeGrade", baseParser, null],
  ["testedAgeGrade", "testedAgeGrade", baseParser, null],
  ["nrtlCertNumber", "nrtlCertNumber", baseParser, null],
  ["productKeywords", "productKeywords", baseParser, null],
  ["category", "category", baseParser, null],
  ["subcategory", "subcategory", baseParser, null],
  ["mainCategory", "mainCategory", baseParser, null],
  ["serviceRequired", "serviceRequired", baseParser, null],
  ["estimatedCompletionDate", "estimatedCompletionDate", baseParser, null],
  ["productDescription", "productDescription", baseParser, null],
  ["status", "status", baseParser, null],
  ["rejectedStatus", "rejectedStatus", baseParser, null],
  ["supplierTags", "supplierTags", baseParser, null],
  ["productGroup", "productGroup", baseParser, null],
  ["upc", "upc", baseParser, baseParser],
  ["countryOfOrigin", "countryOfOrigin", baseParser, baseParser],
];

export const UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS = [
  ["productImage.remarks", "productImageRemark", baseParser, baseParser],
  ["productImage.documents", "productImages", parserDocument, baseParser],
  ["task", "task", parserDocument, baseParser],
  [
    "productImage.reviewer.isReuploadProductImage",
    "reviewer.isReuploadProductImage",
    (name) => (data) => {
      const value = get(data, name);
      if (!!value) return ["required"];
      return false;
    },
    null,
  ],
  [
    "productImage.reviewer.remarks",
    "reviewer.reviewerProductImageRemark",
    baseParser,
    null,
  ],
  // ["packaging.remarks", "packagingImageRemark", baseParser, baseParser],

  ["testReport.remarks", "testReportRemark", baseParser, baseParser],
  ["testReport.documents", "testReport", parserDocument, baseParser],
  [
    "testReport.reviewer.remarks",
    "reviewer.reviewerTestReportRemark",
    baseParser,
    null,
  ],
  [
    "testReport.reviewer.isReuploadTestReport",
    "reviewer.isReuploadTestReport",
    (name) => (data) => {
      const value = get(data, name);
      if (!!value) return ["required"];
      return false;
    },
    null,
  ],

  ["productSpecificationLabellingArtworkPackagingArtwork.remarks", "productSpecificationLabellingArtworkPackagingArtworkRemark", baseParser, baseParser],
  ["productSpecificationLabellingArtworkPackagingArtwork.documents", "productSpecificationLabellingArtworkPackagingArtwork", parserDocument, baseParser],
  [
    "productSpecificationLabellingArtworkPackagingArtwork.reviewer.remarks",
    "reviewer.reviewerProductSpecificationLabellingArtworkPackagingArtworkRemark",
    baseParser,
    null,
  ],
  [
    "productSpecificationLabellingArtworkPackagingArtwork.reviewer.isReuploadProductSpecificationLabellingArtworkPackagingArtwork",
    "reviewer.isReuploadProductSpecificationLabellingArtworkPackagingArtwork",
    (name) => (data) => {
      const value = get(data, name);
      if (!!value) return ["required"];
      return false;
    },
    null,
  ],
  // ["brief.remarks", "briefDescription", baseParser, baseParser],
  // ["materialsUsed.remarks", "materialsUsed", baseParser, baseParser],
  // ["circuitDiagram.remarks", "circuitDiagramRemark", baseParser, baseParser],
  // [
  //   "instructionManual.remarks",
  //   "instructionManualRemark",
  //   baseParser,
  //   baseParser,
  // ],
  // ["safetyDataSheet.remarks", "safetyDataSheetRemark", baseParser, baseParser],
  // ["ingredientList.remarks", "ingredientListRemark", baseParser, baseParser],
  // [
  //   "declarationLetter.remarks",
  //   "declarationLetterRemark",
  //   baseParser,
  //   baseParser,
  // ],
  [
    "conformityCertificate.remarks",
    "conformityCertificateRemark",
    baseParser,
    baseParser,
  ],
  [
    "conformityCertificate.documents",
    "conformityCertificate",
    parserDocument,
    baseParser,
  ],
  ["rejectReasons", "rejectReasons", parserRejectReason, null],
  [
    "conformityCertificate.conformityCertificateSelection",
    "conformityCertificateSelection",
    baseParser,
    baseParserSelection,
  ],
  [
    "conformityCertificate.reviewer.conformityCertificateType",
    "conformityCertificateType",
    baseParser,
    (name) => (data) => {
      const value = get(data, name);
      return value?.value || value;
    },
  ],
  ["cpcTestLines", "testLines.cpcTestLines", baseParser, null],
  ["docTestLines", "testLines.docTestLines", baseParser, null],
  [
    "conformityCertificate.reviewer.reportData",
    "testLines.reportDataTestLines",
    baseParser,
    null,
  ],
  [
    "conformityCertificate.reviewer.documents",
    "reviewerConformityDocuments",
    parserDocument,
    null,
  ],
  [
    "conformityCertificate.reviewer.remarks",
    "reviewer.reviewerConformityCertificateRemark",
    parserDocument,
    null,
  ],
];
