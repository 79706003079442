import React from "react";
import { Form, Space, StepProps, StepsProps } from "antd";
import Steps from "components/Atoms/Steps";
import styled from "@emotion/styled";
import { FormProps } from "antd/es/form/Form";

const Container = styled(Space)`
  width: 100%;
  margin-bottom: 20px;
`;

type FormWithStepperPropType = {
  steps: Array<Partial<StepProps & { content: React.ReactNode }>>;
  stepsProps: StepsProps;
  formProps: FormProps;
};
const FormWithStepper = React.forwardRef(
  (props: FormWithStepperPropType, ref) => {
    const { steps, stepsProps = {}, formProps } = props;
    const [form] = Form.useForm();

    const renderActiveStep = React.useCallback(() => {
      const active = steps[stepsProps.current || 0];
      return active?.content ?? <>N/A</>;
    }, [steps, stepsProps]);

    React.useImperativeHandle(
      ref,
      () => {
        return {
          instanceForm: form,
        };
      },
      [form]
    );

    return (
      <Form {...formProps} form={form}>
        <Container direction="vertical" size={25}>
          <Steps labelPlacement="vertical" {...stepsProps}>
            {steps.map(({ content, ...step }, index) => (
              <Steps.Step key={index} {...step}></Steps.Step>
            ))}
          </Steps>
        </Container>
        {renderActiveStep()}
      </Form>
    );
  }
);

export default FormWithStepper;
