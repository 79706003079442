import { IdSelect } from "./id-select";
import { useProductGroups } from "./api";
import React from "react";
import { SelectProps } from "antd";
import { useDebounce } from "../../../utils";

interface SubcategorySelectProps extends SelectProps {
  subCategoryId: string;
}

export const ProductGroupSelect = ({
  subCategoryId,
  ...props
}: SubcategorySelectProps) => {
  const { data: productGroup } = useProductGroups(
    useDebounce(subCategoryId, 500)
  );
  return (
    <IdSelect disabled={!subCategoryId} data={productGroup || []} {...props} />
  );
};
