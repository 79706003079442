import React from "react";
import { Steps as AntdSteps, StepsProps, Typography } from "antd";
import "./index.less";

const Steps = (props: StepsProps) => {
  return <AntdSteps className={"steps"} {...props}></AntdSteps>;
};

Steps.Step = AntdSteps.Step;

Steps.Title = ({ title }: { title: string }) => {
  return <Typography.Text>{title}</Typography.Text>;
};

export default Steps;
