import React, { useState } from "react";
import {Card, Space, Button, Tooltip, Image, List} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Task } from "../../../constants/interface/task";
import { useTaskCompliance } from "../../../pages/Merchant/UploadDocument/api";
import { useDebounce } from "../../../utils";
import {
  productStatus,
  testPlanStatus,
} from "../../Atoms/status/common-status";
import { UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS } from "configs/Mappings";
import { parser } from "configs/parser";
import { useFetchFile } from "hooks/File/useFetchFileDetail";
import { RbacContext } from "components/RBAC/context";
import { Action, Resource } from "configs/rules";
import expiredImage from "../../Atoms/images/Expired.png";

const TargetReviewCompletionDate = styled.div`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  color: #005eff;
`;

const StyledListItem = styled(List.Item)`
  display: flex;
  margin-top: 30px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutrals-grey-100, #ccc) !important;
  margin-bottom: 16px;
`;

interface TaskDetailCardProps {
  task: Task;
  reload: () => void;
  onClickView: () => void;
  onClickConformityView?: (compliance: any) => void;
  onEditExpiryDate?: () => void;
  showTestPlan?: boolean;
  onClickUploadTestPlan?: (compliance: any) => void;
  rejectReasonSection?: React.ElementType | React.ReactNode;
}
export const TaskReviewDetailCard = React.forwardRef(
  (props: TaskDetailCardProps, ref) => {
    const canActivate = React.useContext(RbacContext);

    const {
      task,
      showTestPlan = false,
      onClickUploadTestPlan,
      rejectReasonSection,
    } = props;
    const [param] = useState({ taskId: task.id });
    const [rejectReasons, setRejectReasons] = React.useState([]);
    const { data: complianceData, isLoading, retry } = useTaskCompliance(
      useDebounce(param, 200)
    );

    const fetchFile = useFetchFile();

    const renderConformityStatus = () => {
      if (task?.status === "rejected")
        return productStatus("rejected", task?.rejectedStatus);
      if (task?.status === "pending_e_signature")
        return productStatus(task?.status);
      if (task?.status === "completed") {
        if (
          complianceData?.conformityCertificateSelection === "merchant_uploaded"
        )
          return productStatus(task?.status);
        return productStatus(complianceData?.conformityCertificateSelection);
      }

      return productStatus(task?.status);
    };

    React.useImperativeHandle(ref, () => {
      return {
        reload: () => {
          retry();
        },
      };
    });

    const parsedRejectReasons = async () => {
      const extra = {
        fetchDocument: fetchFile,
      };
      const mappings = UPLOAD_DOCUMENTS_COMPLIANCE_MAPPINGS.filter(
        ([fieldName]) => fieldName === "rejectReasons"
      );

      const parsed = await parser(complianceData, mappings, extra);
      setRejectReasons([...parsed?.rejectReasons]);
    };

    React.useEffect(() => {
      if (complianceData) {
        parsedRejectReasons();
      }
    }, [complianceData]);

    return (
      <CusCard
        title={<CusCardTitle>Compliance Check</CusCardTitle>}
        extra={
          <TargetReviewCompletionDate>
            <div>
              <Tooltip
                title="“na” means it is pending supplier’s submission. Target Review Completion Date will be calculated based on submission date.">
                <Space>
                  Target Review Completion Date
                  <InfoIcon/>
                </Space>
              </Tooltip>
            </div>
            <div>{task.estimatedCompletionDate || "N/A"}</div>
          </TargetReviewCompletionDate>
        }
      >
        <Space direction="vertical" size={44}>
          <Space direction="vertical" size={2}>
            <h2>Document Validation</h2>
            <Space size={8}>
              {productStatus(
                task?.status === "completed" ||
                task?.status === "pending_e_signature"
                  ? "passed"
                  : task?.status,
                task?.rejectedStatus
              )}
              <Button type="link" onClick={props.onClickView}>
                View
              </Button>
            </Space>
          </Space>

          {/*{showTestPlan && (*/}
          {/*  <Space direction="vertical" size={2}>*/}
          {/*    <h2>Test Plan</h2>*/}
          {/*    {!isLoading && (*/}
          {/*      <Space>*/}
          {/*        {testPlanStatus(*/}
          {/*          task?.testPlanStatus === "pending"*/}
          {/*            ? "missing_test_plan"*/}
          {/*            : task?.testPlanStatus,*/}
          {/*          true*/}
          {/*        )}*/}
          {/*        <Button*/}
          {/*          type="link"*/}
          {/*          onClick={() => {*/}
          {/*            onClickUploadTestPlan &&*/}
          {/*              onClickUploadTestPlan(complianceData);*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {task?.testPlanStatus === "auto_assigned"*/}
          {/*            ? "View"*/}
          {/*            : "Upload"}*/}
          {/*        </Button>*/}
          {/*      </Space>*/}
          {/*    )}*/}
          {/*  </Space>*/}
          {/*)}*/}

          {/* <Space direction="vertical" size={2}>
            <h2>Conformity Certificate</h2>
            {!isLoading && (
              <Space>
                {renderConformityStatus()}
                <Button
                  type="link"
                  onClick={() => {
                    props.onClickConformityView(complianceData);
                  }}
                >
                  View
                </Button>
              </Space>
            )}
          </Space> */}
        </Space>
        <h2 style={{marginTop: 30}}>Expiry Date</h2>
        <Space>
          <div>{task.expiryDate ? task.expiryDate : "N/A"}</div>
          {props.onEditExpiryDate &&
            task?.status !== "rejected" &&
            canActivate(`${Resource.JOBS}.expiryDate`, Action.UPLOAD) && (
              <Button onClick={props.onEditExpiryDate} type="link">
                Edit
              </Button>
            )}
        </Space>
        <h2 style={{marginTop: 30}}>Promote Code</h2>
        <Space>
          <div>{task.promoteCode ? task.promoteCode : "N/A"}</div>
        </Space>

        {
          complianceData && complianceData.reviewer &&
          <StyledListItem>
            <h2>
              <Image
                preview={false}
                style={{
                  margin: "-10px 10px 0px 0px",
                  width: "36px",
                  height: "100%",
                  float: 'left'
                }}
                src={expiredImage}
              />
              Review remark</h2>
            <Space>
              {
                task.taskType === 'cpcGccReview' ?
                  <div
                    style={{overflowWrap: 'anywhere', whiteSpace: 'break-spaces'}}>{complianceData.reviewer.reviewerConformityCertificateRemark ? complianceData.reviewer.reviewerConformityCertificateRemark : "N/A"}</div>
                  :
                  <div
                    style={{overflowWrap: 'anywhere',  whiteSpace: 'break-spaces'}}>{complianceData.reviewer.reviewerTestReportRemark ? complianceData.reviewer.reviewerTestReportRemark : "N/A"}</div>
              }
            </Space>
          </StyledListItem>
        }
        {React.isValidElement(rejectReasonSection) ? (
          <div style={{marginTop: 30}}>
            {React.cloneElement(rejectReasonSection, {
              compliance: {
                ...complianceData,
                rejectReasons,
              },
              task: task,
              role: "reviewer_user",
            } as any)}
          </div>
        ) : undefined}
      </CusCard>
    );
  }
);

const CusCard = styled(Card)`
    width: 100%;
    padding: 6px 24px 0px 24px;
    border-radius: 15px;
    position: relative;
    margin-bottom: 24px;

    .ant-card-body {
        padding: 20px 0px;
    }

    .ant-card-head {
        padding: 0px 0px;
    }
`;

const CusCardTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: left;
`;
