import { get } from "lodash";

export const parserDocument = (name) => async (data, extra) => {
  const { fetchDocument } = extra;
  const value = get(data, name);
  if (fetchDocument) {
    if (Array.isArray(value)) {
      const tasks = value.map(
        async (file: {
          uuid: string;
          type?: string;
          docType?: string;
          createdAt?: string;
          fileName: string;
        }) => {
          const fileDetail = await fetchDocument(file.uuid);

          return {
            uid: file.uuid,
            docType: file.type || file.docType,
            name: file.fileName,
            uuid: file.uuid,
            url: fileDetail.url,
            status: "done",
            downloadUrl: fileDetail?.downloadUrl,
            createdAt: fileDetail?.createdAt,

          };
        }
      );
      const resolved = await Promise.all(tasks);

      return resolved;
    }
  }

  return value;
};

export const parserRejectReason = (name) => async (data, extra) => {
  const value = get(data, name);
  if (!Array.isArray(value)) return [];
  return await value.reduce(async (acc, current) => {
    const result = await acc;

    if (current.reason === "additionalTest") {
      const additionalQuotationFiles =
        get(current, "additionalTestQuotation.files", []) || [];
      let reasonFiles = [...additionalQuotationFiles].concat(
        current.files || []
      );
      const fetchedReasonFiles = await parserDocument("files")(
        {
          files: [...reasonFiles],
        },
        extra
      );
      const fetchedAdditionalFiles = await parserDocument("files")(
        {
          files: [...additionalQuotationFiles],
        },
        extra
      );
      result.push({
        ...current,
        additionalTestQuotation: {
          ...current.additionalTestQuotation,
          files: fetchedAdditionalFiles,
        },
        files: fetchedReasonFiles,
      });
    } else {
      const fetchedAdditionalFiles = await parserDocument("files")(
        {
          files: [...(current.files || [])],
        },
        extra
      );
      result.push({
        ...current,
        additionalTestQuotation: {
          ...current.additionalTestQuotation,
          files: fetchedAdditionalFiles,
        },
        files: fetchedAdditionalFiles,
      });
    }
    return Promise.resolve(result);
  }, Promise.resolve([]));
};
