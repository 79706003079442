import React from "react";
import {Modal, ModalProps} from "antd";
import styled from "@emotion/styled";

const FullScreenModal = (props: ModalProps) => {
  return (
    <FullScreen>
      <Modal
        {...props}
        getContainer={false}
      >
        {props.children}
      </Modal>
    </FullScreen>

  )
}

const FullScreen = styled.div`
  .ant-modal, .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
    border-radius: 0px !important;
  }

  .ant-modal-body {
    height: calc(100vh - 110px);
  }
`;


export default FullScreenModal;
