import { CPCItems, DOCItems } from "constants/DoC";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { useHttp } from "utils/http";
import { useFetchList } from "utils/use-fetch-list";
import merge from "lodash/merge";

export type StatusType =
  | "waiting_for_review"
  | "rejected"
  | "completed"
  | "pending_e_signature"
  | "conformity_certificate_expired"
  | "";

export const useListReview = (status: StatusType | Array<StatusType> = "", keywords?: string) => {
  const [data, onChange, ...rest] = useFetchList({
    endpoint: `products/tasks`,
    queryKey: `review/${status || "all"}`,
    defaultFiltered: {
      status
    },
    keywords,
    options: { enabled: false, keepPreviousData: true },
  });

  useEffect(() => {
    data.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, onChange, ...rest];
};

export const useFetchReviewerOverview = () => {
  const client = useHttp();
  const overview = useQuery("review/overview", async () => {
    const response = await client("products/tasks/overview");
    return response;
  });
  return overview;
};

export const useGenerateCertPdf = () => {
  const [url, setUrl] = useState("");

  const transformParamsCPC = (
    data: Array<Record<string, any>>,
    additional: any
  ) => {
    const { testLines = [], additionalInformation = "", productPhotos } =
      additional || {};
    const testRecords = testLines.map((testLine: any) => {
      return {
        name: testLine.testItem,
        citation: testLine.citation,
        thirdParty: testLine.lab,
        reportNo: testLine.reportNumber,
        issueDate: testLine.reportIssueDate
          ? moment(testLine.reportIssueDate).format("MM/DD/YYYY")
          : "",
      };
    });
    const params = CPCItems.reduce((acc, current) => {
      const _data = data.find(({ title }) => title === current.title);
      if (current.name === "product") {
        return {
          ...acc,
          [current.name]: {
            ...current.transformGenerateParams(_data || {}),
            photo: productPhotos,
          },
        };
      }
      return {
        ...acc,
        [current.name]: current.transformGenerateParams(_data || {}),
      };
    }, {});
    return {
      ...params,
      cpcRemark: additionalInformation,
      testRecords,
    };
  };
  const transformParamsDOC = (
    data: Array<Record<string, any>>,
    additional: any
  ) => {
    const { testLines = [], additionalInformation = "", signature } =
      additional || {};

    const testItems = testLines.map(({ testItem }: any) => testItem);
    const harmonisationStandard = testLines.map(
      ({ citation, testItem }: any) => `${citation} ${testItem}`
    );
    const legislations = testLines.map(({ legislation }: any) => legislation);
    const params = DOCItems.reduce((acc, current) => {
      const _data = data.find(({ title }) => title === current.title);
      return {
        ...acc,
        [current.name]: current.transformGenerateParams(_data || {}),
      };
    }, {});
    return {
      ...params,
      additionalInformation: additionalInformation,
      testItems,
      harmonisationStandard,
      legislations,
      signature,
    };
  };
  const transformParams = (
    data: Array<Record<string, any>>,
    additional: any,
    type: "DOC" | "CPC"
  ) => {
    const generate = type === "DOC" ? transformParamsDOC : transformParamsCPC;
    const params = generate(data, additional);

    return params;
  };
  const getDocItem = (name: string) => {
    return [...DOCItems, ...CPCItems].find(
      ({ name: docName }) => docName === name
    );
  };

  const getDefaultValue = useCallback(
    (productInfo: any, complianceData: any) => {
      const items =
        (complianceData?.conformityCertificateType || "DOC") === "DOC"
          ? ["company", "product"]
          : ["product", "importedManfacturer", "manufacturingInformation"];
      if (!productInfo || !complianceData) return [];

      return items.map((docName) => {
        const item = getDocItem(docName);
        return {
          title: item.title,
          ...item.parsedDefaultValues(
            productInfo,
            complianceData?.merchant ?? {}
          ),
        };
      });
    },
    []
  );
  const mergedValue = (value: any, defaultValue: any) => {
    if (!value.length) {
      return merge(defaultValue, value || []);
    }
    return value.reduce((acc, current) => {
      const existed = acc.findIndex(({ title }) => {
        return title === current.title;
      });
      if (existed > -1) {
        acc[existed] = merge(acc[existed], current);
      } else {
        acc.push(current);
      }
      return acc;
    }, defaultValue);
  };
  const generate = async (
    data: Record<string, any>,
    type: "DOC" | "CPC",
    autoOpen = true
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_GENERATE_CERT_URL}/${type.toLowerCase()}`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const blob = await response.blob();
      const newBlob = new Blob([blob], { type: "application/pdf" });

      const blobUrl = window.URL.createObjectURL(newBlob);

      setUrl(blobUrl);

      if (autoOpen) {
        //Open the URL on new Window
        const pdfWindow: any = window.open();
        if (pdfWindow) {
          pdfWindow.location.href = blobUrl;
        }
        return;
      }
      return blobUrl;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return { url, generate, transformParams, getDefaultValue, mergedValue };
};
