export const TEST_LINE_FIELDS = {
  CPC: "cpcTestLines",
  DOC: "docTestLines",
  "report-data": "reportDataTestLines",
};

export const GENERATION_FIELDS = {
  CPC: "cpcGeneralInformations",
  DOC: "docGeneralInformations",
};

export const REJECT_REASON = [
  {
    value: "additionalTest",
    label: "Request additional test",
  },
  {
    value: "revise",
    label: "Request revise",
  },
  {
    value: "others",
    label: "Others",
  },
];
