import {getCountriesWithFlags} from "fixtures/countries";
import React from "react";
import {useQuery} from "react-query";
import {useHttp} from "utils/http";
import {useAuth} from "./auth-context";

type Context = {
  exportMarkets: Array<{ id: string; name: string }>;
  productCategories: Array<{ id: string; name: string }>;
  subCategories: Array<{ id: string; name: string }>;
  rejectTypes: Array<{ id: string; name: string }>;
  ageGrade: Array<{ value: string; label: string }>;
  serviceRequired: Array<{ value: string; label: string }>;
  supplierTags: Array<string>;
  rejectedReasons: Array<string>;
  countries: Array<any>;
  citations: Array<{ value: string; label: string }>;
  testItems: Array<{ value: string; label: string }>;
  legislations: Array<{ value: string; label: string }>;
  transformSelection: (
    data: Array<{ [key: string]: any }>
  ) => Array<
    Partial<{
      [key: string]: any;
      value: string;
      label: string;
    }>
  >;
};

const ServiceRequired = [
  {value: "regular", label: "Regular, USD 120"},
  {value: "express", label: "Express, 50% surcharge"},
  {value: "shuttle", label: "Shuttle, 100% surcharge"},
];

const AgeGrade = [
  {value: "all_ages", label: "All ages"},
  {value: "over_3_years", label: "over 3 years"},
  {value: "over_8_years", label: "over 8 years"},
  {value: "adult", label: "Adult"},
  {value: "n/a", label: "N/A"},
];

export const StaticDataContext = React.createContext<Context>({
  transformSelection: () => [],
  productCategories: [],
  rejectTypes: [],
  subCategories: [],
  exportMarkets: [],
  ageGrade: [],
  serviceRequired: [],
  supplierTags: [],
  rejectedReasons: [],
  countries: [],
  citations: [],
  testItems: [],
  legislations: [],
});

export const StaticDataProvider = ({
                                     children,
                                   }: {
  children: React.ReactNode;
}) => {
  const {user} = useAuth();
  const client = useHttp();
  const {data: productCategories} = useQuery(
    "productCategories",
    async () => {
      const response = await client("categories", {});
      return response;
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );
  const {data: subCategories} = useQuery(
    "productSubCategories",
    async () => {
      const response = await client("subcategories", {});
      return response;
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );
  const {data: exportMarkets} = useQuery(
    "exportMarkets",
    async () => {
      const response = await client("export-markets", {});
      return response;
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );
  const {data: supplierTags} = useQuery(
    "supplierTags",
    async () => {
      const response = await client("products/supplier-tags", {});
      return response;
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );
  const {data: rejectedReasons} = useQuery(
    "rejectedReasons",
    async () => {
      const response = await client("tasks/reject-reasons", {});
      return response;
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );
  const {data: testItems} = useQuery(
    "testItems",
    async () => {
      const response = await client("compliances/test-items", {});
      return (response || []).map((value) => ({
        value: value,
        label: value,
      }));
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );
  const {data: citations} = useQuery(
    "citations",
    async () => {
      const response = await client("compliances/citations", {});
      return (response || []).map((value) => ({
        value: value,
        label: value,
      }));
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );
  const {data: legislations} = useQuery(
    "legislations",
    async () => {
      const response = await client("compliances/legislations", {});
      return (response || []).map((value) => ({
        value: value,
        label: value,
      }));
    },
    {
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user,
    }
  );

  const {data: rejectTypes} = useQuery(
    "reject-type",
    async () => {
      const response = await client("tasks/reject-types", {});
      return (response || []).map((value) => ({
        value: value,
        label: value,
      }));
    },
    {
      refetchIntervalInBackground: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      enabled: !!user,
    }
  );
  const transformSelection = (
    data: Array<Partial<{ id: string; name: string }>> = []
  ) => {
    return data.map((item) => ({
      value: item.id,
      label: item.name,
      text: item.name,
      ...item,
    }));
  };
  return (
    <StaticDataContext.Provider
      value={{
        transformSelection,
        productCategories,
        exportMarkets,
        rejectTypes,
        ageGrade: AgeGrade,
        serviceRequired: ServiceRequired,
        supplierTags,
        rejectedReasons,
        countries: getCountriesWithFlags(),
        citations,
        testItems,
        legislations,
        subCategories,
      }}
    >
      {children}
    </StaticDataContext.Provider>
  );
};

export const useTransformSelection = () => {
  const context = React.useContext(StaticDataContext);
  return context.transformSelection;
};

export const useStaticProductCategories = () => {
  const context = React.useContext(StaticDataContext);
  return context.productCategories;
};

export const useStaticExportMarkets = () => {
  const context = React.useContext(StaticDataContext);
  return context.exportMarkets;
};

export const useStaticSupplierTags = (transformOptions: boolean = false) => {
  const context = React.useContext(StaticDataContext);

  if (transformOptions) {
    return (context.supplierTags || []).map((tag) => ({
      value: tag,
      label: tag,
    }));
  }
  return context.supplierTags || [];
};
export const useStaticRejectReasons = (transformOptions: boolean = false) => {
  const context = React.useContext(StaticDataContext);

  if (transformOptions) {
    return (context.rejectedReasons || []).map((tag) => ({
      value: tag,
      label: tag,
    }));
  }
  return context.rejectedReasons || [];
};

export const useCountries = () => {
  const context = React.useContext(StaticDataContext);
  if (!context.countries) return [];
  return context.countries.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const useSubCategories = () => {
  const context = React.useContext(StaticDataContext);
  return context.subCategories;
};

export const useRejectTypes = (transformOptions: boolean = false) => {
  const context = React.useContext(StaticDataContext);
  return context.rejectTypes;
};
