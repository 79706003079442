import { DatePicker, Form, ModalProps, Space, Typography } from "antd";
import SimpleModal from "components/Molecules/modal/simple-modal";
import React from "react";
import moment from "moment";
import styled from "@emotion/styled";

const StyledSpace = styled(Space)`
  width: 100%;
`;
const StyledDatePicker = styled(DatePicker)`
  min-width: 80%;
`;
export const ModalComplianceExpiryDate = ({
  content,
  defaultExpiryDate,
  hideText,
  onSubmit,
  ...props
}: ModalProps & {
  content?: string | React.ReactNode;
  defaultExpiryDate?: Date;
  hideText?: boolean;
  onSubmit?: (values: any) => void;
}) => {
  const [form] = Form.useForm();
  const defaultMessage =
    "Are the documents confirmed to be accepted and pass compliance";
  return (
    <SimpleModal
      title="Compliance Check Passed"
      onOk={() => {
        onSubmit && onSubmit(form.getFieldsValue());
      }}
      {...props}
    >
      <StyledSpace direction="vertical">
        {!hideText && (
          <Typography.Text>{content || defaultMessage}</Typography.Text>
        )}
        <Form
          form={form}
          name="compliance-expiry-date"
          layout="vertical"
          initialValues={{
            expiryDate: moment().add(1, "year"),
          }}
        >
          <Form.Item
            rules={[
              {
                type: "string",
                required: true,
                message: "This field is required",
              },
            ]}
            required
            name="expiryDate"
            label="Compliance Expiry Date"
            labelAlign="right"
          >
            <StyledDatePicker format="DD-MM-YYYY"></StyledDatePicker>
          </Form.Item>
        </Form>
      </StyledSpace>
    </SimpleModal>
  );
};
