import React, { SyntheticEvent } from "react";
import { Upload as AntdUpload, Button, Col, Row, Avatar } from "antd";
import { IBaseField, IUpload } from "../FormRenderer/type";
import {
  UploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { FileImageOutlined } from "@ant-design/icons";
import DocIcon from "components/Atoms/images/file-icon/doc_icon.png";
import PdfIcon from "components/Atoms/images/file-icon/pdf_icon.png";
import XlsIcon from "components/Atoms/images/file-icon/xls_icon.png";
import { isNil } from "lodash";
import moment from "moment";

type IUploadProps = {
  field: Partial<IUpload<IBaseField>>;
  value: any;
  onChange: (event: SyntheticEvent) => void;
  disabled?: boolean;
  hiddenRemove?: boolean;
};
const renderFile = (field: Partial<IUpload<IBaseField>>, disabled: boolean, hiddenRemove?: boolean) => (
  originNode: any,
  file: any,
  fileList: object[],
  actions: any
) => {
  const patternFileExtension = /\.([0-9a-z]+)(?:[#]|$)/i;
  const extension =
    (file.fileName
      ? file.fileName.match(patternFileExtension)
      : file.name.match(patternFileExtension)) || [];

  let icon = null;
  switch (extension[1]) {
    case "doc":
    case "docx":
      icon = DocIcon;
      break;
    case "pdf":
      icon = PdfIcon;
      break;
    case "xls":
    case "xlsx":
      icon = XlsIcon;
      break;
    default:
      icon = <FileImageOutlined style={{ color: "#F98001", fontSize: 22 }} />;
  }

  return (
    <Row align="middle" style={{ marginBottom: 8, marginTop: 8 }}>
      <Col flex={"none"}>
        <Avatar
          shape="square"
          size="small"
          style={{ margin: "0px 10px 5px 0px" }}
          src={icon}
        />
      </Col>
      <Col flex={`none`}>
        <div style={{cursor: "pointer", fontSize: 14}} onClick={() => actions.download()}>
          {file.name || file.fileName}
          <div
            style={{fontSize: 12, color: '#777'}}>{file.createdAt ? `${moment(file.createdAt).format("YYYY-MM-DD HH:mm:ss")}` : ''}</div>
        </div>
      </Col>
      <Col flex={`auto`}>
        <a
          hidden={!file?.downloadUrl}
          className="ant-btn ant-btn-circle ant-btn-link ant-btn-icon-only"
          href={file?.downloadUrl}
          download={file.name || file.fileName}
        >
          <DownloadOutlined />
        </a>
        <Button
          hidden={field.readonly || disabled || ( file?.downloadUrl && hiddenRemove)}
          // style={{ marginTop: 10 }}
          type={"link"}
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => actions.remove()}
        />
      </Col>
    </Row>
  );
};

const Uploader = ({ field, onChange, value, disabled, hiddenRemove }: IUploadProps) => {
  const element = React.useMemo(() => {
    if (!field.uploadButton) return undefined;
    return React.isValidElement(field.uploadButton as React.ReactElement) ? (
      React.cloneElement(field.uploadButton as React.ReactElement, {
        //TODO: Will Implement later
        disabled,
        field,
      })
    ) : (
      <Button
        disabled={disabled || (field.limit && (value || []).filter(v => !v.uuid).length >= field.limit)}
        hidden={field.readonly}
        icon={<UploadOutlined />}
        type="primary"
      >
        {field.uploadButton}
      </Button>
    );
  }, [field, value, disabled]);
  if (field.readonly && (isNil(value) || !value.length)) return <>N/A</>;

  return (
    <AntdUpload
      // disabled={disabled || (field.limit && value.length >= field.limit)}
      disabled={disabled || (field.limit && (value || []).filter(v => !v.uuid).length >= field.limit)}
      showUploadList={field.showUploadList}
      maxCount={field.maxCount}
      multiple={field.multiple}
      accept={field.accept}
      listType={field.listType}
      directory={field.directory}
      fileList={value}
      type={field.uploadType}
      itemRender={
        field.listType === "picture" ? renderFile(field, disabled, hiddenRemove) : undefined
      }
      beforeUpload={() => false}
      onChange={(info) => {
        onChange({
          target: {
            name: field.name,
            value: info.fileList,
          },
        } as any);
      }}
    >
      {element}
    </AntdUpload>
  );
};

Uploader.renderFile = renderFile;
export default Uploader;
