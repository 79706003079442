import React from "react";
import { Form, Input, FormProps, DatePicker } from "antd";
import Creatable from "react-select/creatable";
import styled from "@emotion/styled";
import { FormInstance, Rule } from "antd/lib/form";
import { uniqueId } from "lodash";

interface ICreateTestLine extends Partial<FormProps> {
  testingItemOptions?: Array<{ value: string; label: string }>;
  countryOptions?: Array<{ value: string; label: string }>;
  citationOptions?: Array<{ value: string; label: string }>;
  legislationOptions?: Array<{ value: string; label: string }>;
  formType: string;
  modalForm?: FormInstance;
}

const Container = styled.div``;

const CreateTestLine = React.forwardRef(
  (
    {
      testingItemOptions,
      countryOptions,
      citationOptions,
      legislationOptions,
      formType,
      onFinish,
      initialValues,
      ...props
    }: ICreateTestLine,
    ref
  ) => {
    const [form] = Form.useForm();
    const getTheme = (theme: any) => ({
      ...theme,
      colors: {
        ...theme.colors,
        // primary25: "#005eff",
        primary: "#005eff",
      },
    });
    React.useImperativeHandle(
      ref,
      () => {
        return {
          form,
        };
      },
      [form]
    );
    const requiredSelectionRules = [
      {
        type: formType === "report-data" ? "string" : "object",
        required: true,
      },
    ];
    const requiredStringRules = [
      {
        type: "string",
        required: true,
      },
    ];
    return (
      <Container>
        <Form
          form={props.modalForm}
          layout="vertical"
          preserve
          onFinish={(values) => {
            const { id } = initialValues;
            if (id) {
              onFinish({ id, ...values });
              return;
            }
            onFinish({
              id: uniqueId("new"),
              ...values,
            });
          }}
          {...props}
        >
          <Form.Item
            name="testItem"
            label="Testing item"
            rules={requiredSelectionRules as Rule[]}
          >
            {formType === "report-data" ? (
              <Input placeholder="Input testing item"></Input>
            ) : (
              <Creatable
                theme={getTheme}
                placeholder="Select testing item"
                options={testingItemOptions}
              ></Creatable>
            )}
          </Form.Item>

          <Form.Item
            name="citation"
            label="Citation"
            rules={requiredSelectionRules as Rule[]}
          >
            {formType === "report-data" ? (
              <Input placeholder="Enter testing item"></Input>
            ) : (
              <Creatable
                theme={getTheme}
                placeholder="Select Citation"
                options={citationOptions}
              ></Creatable>
            )}
          </Form.Item>
          {formType === "DOC" && (
            <Form.Item
              name="legislation"
              label="Legislation"
              rules={requiredSelectionRules as Rule[]}
            >
              <Creatable
                theme={getTheme}
                placeholder="Select legislation"
                options={legislationOptions}
              ></Creatable>
            </Form.Item>
          )}
          {formType === "report-data" && (
            <Form.Item
              name="testResult"
              label="Test Result"
              rules={requiredSelectionRules as Rule[]}
            >
              <Input placeholder="Enter test result"></Input>
            </Form.Item>
          )}

          <Form.Item
            name="lab"
            label="Lab"
            rules={requiredStringRules as Rule[]}
          >
            <Input placeholder="Enter lab name"></Input>
          </Form.Item>
          <Form.Item
            name="reportNumber"
            label="Report Number"
            rules={requiredStringRules as Rule[]}
          >
            <Input placeholder="Enter report number"></Input>
          </Form.Item>
          <Form.Item
            name="reportIssueDate"
            label="Report Issue Date"
            rules={
              [
                {
                  type: "object",
                  required: true,
                },
              ] as Rule[]
            }
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
            ></DatePicker>
          </Form.Item>
        </Form>
      </Container>
    );
  }
);

export default CreateTestLine;
