import styled from "@emotion/styled";
import { Steps } from "antd";
import React from "react";
const StyledSteps = styled(Steps)`
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
`;
export const PaymentSteps = ({ currentStep, onChange, status = [] }) => {
  return (
    <StyledSteps current={currentStep} onChange={onChange}>
      <Steps.Step status={status[0]} title="Payment Detail"></Steps.Step>
      <Steps.Step status={status[1]} title="Process Payment"></Steps.Step>
      <Steps.Step status={status[2]} title="Approved"></Steps.Step>
    </StyledSteps>
  );
};
