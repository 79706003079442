import React from "react";
import { Space } from "antd";
import { RightOutlined } from "@ant-design/icons";
import CusPageHeader from "../../../components/Molecules/header";
import Page from "../../../components/Molecules/page";
import { useLocation, useNavigate } from "react-router-dom";
import ProductTable from "./product-table";
import styled from "@emotion/styled";
import { MainLayout } from "components/Organisms/layouts";

const StyledSubTitle = styled(Space)`
  margin-left: 8px;
  span {
    font-size: 12px;
    color: #c4c8d0;
  }
  h3 {
    margin-bottom: 0;
    font-size: 18px;
  }
`;
const StyledTitle = styled.span`
  color: #c4c8d0;
`;
export function Home() {
  let navigate = useNavigate();
  const { search } = useLocation();

  const title = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    // const { value } = searchParams.keys().next();
    if (searchParams.get("progress") === "expiring") {
      return "Expiring Soon / Expired";
    }
    return "";
  }, [search]);

  return (
    <MainLayout>
      <CusPageHeader
        title={
          title ? (
            <StyledTitle>
              Home
              <StyledSubTitle>
                <RightOutlined />
                <h3>{title}</h3>
              </StyledSubTitle>
            </StyledTitle>
          ) : (
            "Home"
          )
        }
        onBack={() => navigate(-1)}
      />
      <Page>
        <ProductTable />
      </Page>
    </MainLayout>
  );
}
