import { useHttp } from "../../../utils/http";
import { useAsync } from "../../../utils/use-async";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { cleanObject, useDebounce } from "../../../utils";
import {
  useSubCategories,
  useProductGroups,
} from "components/Molecules/select/api";
import { StaticDataContext } from "context/static-data-context";

export const useTaskCompliance = (param: Partial<any>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();

  const fetchTaskCompliance = useCallback(
    () => client(`tasks/${param.taskId}/compliance`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchTaskCompliance(), {
      retry: fetchTaskCompliance,
    });
  }, [param, run, fetchTaskCompliance]);

  return result;
};

export const useTaskComplianceSummary = (param: Partial<any>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();
  const { taskId } = param;

  const fetchTaskComplianceSummary = useCallback(
    () => client(`tasks/${taskId}/compliance_summary`, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [param, client]
  );

  useEffect(() => {
    run(fetchTaskComplianceSummary(), {
      retry: fetchTaskComplianceSummary,
    });
  }, [param, run, fetchTaskComplianceSummary]);

  return result;
};

export const useUpdateTaskCompliance = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = ({
    params,
    taskId,
  }: {
    params?: Partial<any>;
    taskId: string;
  }) => {
    return run(
      client(`tasks/${taskId}/compliance`, {
        data: cleanObject(params || {}),
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useFetchSubCategoriesAndProductGroup = (
  category: string,
  subCategory: string
) => {
  const { productCategories, exportMarkets } = useContext(StaticDataContext);
  const categoryId = useMemo(() => {
    return (
      (productCategories || []).find(({ name }) => name === category)?.id ?? ""
    );
  }, [category, productCategories]);

  const { data: subCategories } = useSubCategories(useDebounce(categoryId, 0));

  const subCategoryId = useMemo(() => {
    return (
      (subCategories || []).find(({ name }) => name === subCategory)?.id ?? ""
    );
  }, [subCategory, subCategories]);

  const { data: productGroup } = useProductGroups(
    useDebounce(subCategoryId, 0)
  );
  return [productCategories, subCategories, productGroup, exportMarkets];
};
