import {useHttp} from "../../../utils/http";
import {useAsync} from "../../../utils/use-async";
import {useCallback, useEffect} from "react";
import {cleanObject} from "../../../utils";

export const useComplianceConformityCertificate = (param: Partial<any>) => {
  const client = useHttp();
  const {run, ...result} = useAsync<any>();

  const fetchComplianceConformityCertificate = useCallback(
    () =>
      client(`compliances/${param.complianceId}/conformity_certificate`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchComplianceConformityCertificate(), {
      retry: fetchComplianceConformityCertificate,
    });
  }, [param, run, fetchComplianceConformityCertificate]);

  return result;
};


export const useAddESign = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<any>, complianceId: string) => {
    return run(
      client(`compliances/${complianceId}/conformity_certificate`, {
        data: cleanObject(params || {}),
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

