import React from "react";
import { Tag as AntdTag, TagProps } from "antd";
import styled from "@emotion/styled";
import { CloseOutlined } from "@ant-design/icons";

const StyledTag = styled(AntdTag)`
  background: #4e5a71;
  padding: 4px 6px 4px 8px;
  color: #ffffff;
  border-radius: 13px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 24px;

  .ant-tag-close-icon {
    display: inline-flex;
    width: 1.5em;
    height: 1.5em;
    background: white;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 600;
  }
`;

const Tag = (props: TagProps & {}) => {
  return <StyledTag {...props} closeIcon={<CloseOutlined />}></StyledTag>;
};

export default Tag;
