import { HomeReview, ReviewAll, ReviewDetail } from "./Reviews";
import { Merchant } from "pages/Merchant";
import ProjectPage from "pages/Merchant/ProjectPage";
// import { UploadDocument } from "pages/Merchant/UploadDocument";
import UploadDocumentWalmart from "pages/Merchant/UploadDocument/index-walmart";

import { Doc } from "pages/Merchant/Doc";
import { Home } from "pages/Merchant/Home";
// import { ComplianceCheck } from "./Reviews/views/ComplianceCheck";
import Authorization from "./Authorization";
import WalmartComplianceCheck from "./Reviews/views/ComplianceCheck/index.walmart";

const MerchantRoutes = [
  {
    key: "project_page_doc",
    path: "/project-page/:id/:taskId/doc/:complianceId",
    element: Doc,
    canAccess: (role: string) => {
      return role === "merchant_user" || role === "reviewer_user";
    },
  },
  {
    key: "project_page_detail",
    path: "/project-page/:id",
    element: ProjectPage,
    canAccess: (role: string) => {
      return role === "merchant_user";
    },
  },
  {
    key: "project_upload_page",
    path: "/project-page/:id/upload-document/:taskId",
    // element: UploadDocument,
    element: UploadDocumentWalmart, //For Walmart
    canAccess: (role: string) => {
      return role === "merchant_user";
    },
  },
  {
    key: "home",
    path: "/home",
    index: true,
    element: Home,
    canAccess: (role: string) => {
      return role === "merchant_user";
    },
  },
  {
    key: "merchant",
    path: "/",
    index: true,
    element: Merchant,
    canAccess: (role: string) => {
      return role === "merchant_user";
    },
  },
];

const ReviewRoutes = [
  {
    key: "review",
    path: "/",
    index: true,
    element: HomeReview,
    canAccess: (role: string) => {
      return ["reviewer_user", "buyer_user"].includes(role);
    },
    // children: [
    //   {
    //     index: true,
    //     key: "Review Detail",
    //     path: ":id",
    //     element: HomeReview,
    //   },
    // ],
  },
  {
    key: "review-all",
    path: "/reviews/:status",
    element: ReviewAll,
    canAccess: (role: string) => {
      return ["reviewer_user", "buyer_user"].includes(role);
    },
  },
  {
    key: "review-task",
    path: "/review/:id",
    element: ReviewDetail,
    canAccess: (role: string) => {
      return ["reviewer_user", "buyer_user"].includes(role);
    },
  },
  {
    key: "review-upload-document",
    path: "/review/:id/upload-document/:taskId",
    // element: ComplianceCheck,
    element: WalmartComplianceCheck,
    canAccess: (role: string) => {
      return ["reviewer_user", "buyer_user"].includes(role);
    },
  },
];

export const AuthorizationRoutes = [
  {
    key: "authorization",
    path: "authorization",
    element: Authorization,
    children: [
      {
        key: "sign-in",
        path: "sign-in",
        index: true,
        element: Authorization.SignIn,
      },
      // {
      //   key: "sign-up",
      //   path: "sign-up",
      //   element: Authorization.SignUp,
      // },
      {
        key: "forgot-password",
        path: "forgot-password",
        element: Authorization.ForgotPassword,
      },
      {
        key: "reset-password",
        path: "reset-password",
        element: Authorization.ResetPassword,
      },
    ],
  },
];

export const Routes = [...MerchantRoutes, ...ReviewRoutes];
