import styled from "@emotion/styled";
import { Space, Image } from "antd";
import React from "react";

const StyledFooter = styled.footer`
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
  h5 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    font-variant: all-small-caps;
    line-height: normal;
    letter-spacing: 0.72px;
  }
`;

const Footer = ({ poweredByLogo }) => {
  return (
    <StyledFooter hidden={!poweredByLogo}>
      <Space size={45}>
        <h5>Powered by</h5>
        <Image preview={false} src={poweredByLogo}></Image>
      </Space>
    </StyledFooter>
  );
};

export default Footer;
