import React from 'react';
import {PageHeader, PageHeaderProps} from "antd";
import styled from "@emotion/styled";


const CusPageHeader = (props: PageHeaderProps) => {
  return (
    <Header
      {...props}
    />
  );
};

export default CusPageHeader;

const Header = styled(PageHeader)`
  border: 1px solid rgb(235, 237, 240);
  background-color: white;
`;

