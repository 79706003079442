import React from "react";
import {Modal, ModalProps} from "antd";

const SimpleModal = (props: ModalProps) => {
  return (
    <Modal
      {...props}
      centered
    >
      {props.children}
    </Modal>
  )
}

export default SimpleModal;
