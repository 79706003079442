import React, {useState} from "react";
import {Button, Space} from "antd";
import Page from "components/Molecules/page";
import Tabs from "components/Organisms/tabs";
import { Treemap } from '@ant-design/plots';
import {useNavigate} from "react-router-dom";
import {ReviewTable} from "../../components/ReviewTable";
import styled from "@emotion/styled";
import {useFetchReviewerOverview, useListReview} from "pages/Reviews/api";
import {withFiltersOptions} from "HOCs/withFiltersOptions";
import omit from "lodash/omit";
import {MainLayout} from "components/Organisms/layouts";
import Statistics from "./components/Statistics";
import {useAuth} from "context/auth-context";
import {Role} from "configs/rules";
import {useHttp, useHttpDownload} from "../../../../utils/http";
import {downloadFile} from "../../../../utils/file";

const FullWidth = styled(Space)`
    width: 100%;
`;

const StyledLayout = styled(Page)`
    margin: auto;
    padding-top: 24px;
`;

const Home = withFiltersOptions(({filters, role}: any) => {
  const navigate = useNavigate();
  const overview = useFetchReviewerOverview();
  const {user} = useAuth();
  const client = useHttpDownload();
  const [keywords, setKeywords] = useState<string>()
  const [waitingForReviews, onChangeTask, ...data] = useListReview([
    "waiting_for_review",
    "rejected_quote_accepted",
  ] as any, keywords);

  const [allTask, onChangeAllTask] = useListReview([
    "waiting_for_review",
    "completed",
    "rejected_quote_accepted",
    "rejected_request_additional_test",
    "rejected_request_revise",
    "rejected_others",
  ] as any, keywords);

  const onChange = (
    pagination: { current: number; pageSize: number },
    filtered: Record<string, any>,
    sorted: Record<string, any>,
    extra: { action: string; currentDataSource: Array<any> }
  ) => {
    onChangeTask(
      pagination,
      {
        ...filtered,
        status: ["waiting_for_review", "rejected_quote_accepted"],
      },
      sorted,
      extra
    );
  };
  const onChangeJob = (
    pagination: { current: number; pageSize: number },
    filtered: Record<string, any>,
    sorted: Record<string, any>,
    extra: { action: string; currentDataSource: Array<any> }
  ) => {

    onChangeAllTask(
      pagination,
      {
        ...filtered,
        status:
          Array.isArray(filtered.status) && filtered.status.length
            ? filtered.status
            : null,
      },
      sorted,
      extra
    );
  };

  const handleOnSearch = async (value) => {
    setKeywords(value)
  };

  const onDownload = async () => {
    const data = await client(
      `products/export`
    );

    downloadFile(data, 'wm_export.xlsx')
  };

  return (
    <>
      <MainLayout>
        {/* <CusPageHeader title="Home"></CusPageHeader> */}
        <StyledLayout>
          <FullWidth direction="vertical" size={32}>
            <Statistics
              navigate={navigate}
              overview={overview?.data || {}}
            ></Statistics>
            <Space direction="vertical" size={[0, 12]}>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                  <h2>My Jobs</h2>
                </div>
                <div>
                  <Button onClick={onDownload} style={{marginLeft: "20px"}}>Download</Button>
                </div>
              </div>


            </Space>
            <Tabs
              tabs={[
                {
                  key: "todo",
                  tab: "Todo",
                  content: (
                    <ReviewTable
                      scroll={{x: true}}
                      loading={waitingForReviews.isLoading}
                      onSearch={handleOnSearch}
                      dataSource={(waitingForReviews as any)?.data?.list ?? []}
                      defaultFilteredValue={{
                        status: [
                          "waiting_for_review",
                          "rejected_quote_accepted",
                        ],
                      }}
                      filters={omit(filters, "status")}
                      onChange={onChange}
                      pagination={{
                        total: (waitingForReviews as any)?.data?.total ?? 0,
                      }}
                    ></ReviewTable>
                  ),
                },
                {
                  key: "myJobs",
                  tab: "All jobs",
                  content: (
                    <ReviewTable
                      scroll={{x: true}}
                      dataSource={allTask?.data?.list ?? []}
                      filters={filters}
                      loading={allTask.isLoading}
                      onSearch={handleOnSearch}
                      onChange={onChangeJob}
                      defaultFilteredValue={{
                        status: [
                          "waiting_for_review",
                          "completed",
                          "rejected_quote_accepted",
                          "rejected_request_additional_test",
                          "rejected_request_revise",
                          "rejected_others",
                        ].concat(user.userRole === Role.BUYER ? [
                          "missing_documents",
                          "pending_payment"
                        ] : []),
                      }}
                      pagination={{
                        // current: 1,
                        current: (allTask as any)?.data?.page ?? 1,
                        total: (allTask as any)?.data?.total ?? 0,
                      }}
                    ></ReviewTable>
                  ),
                },
              ].filter(({key}) => {
                if (user.userRole === Role.BUYER && key === "todo")
                  return false;
                return true;
              })}
            ></Tabs>
          </FullWidth>
        </StyledLayout>
      </MainLayout>
    </>
  );
});

export default Home;
