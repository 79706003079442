import React from "react";
import { ModalProps, Typography } from "antd";
import SimpleModal from "../../Molecules/modal/simple-modal";
import styled from "@emotion/styled";
const Title = styled.h2`
  margin-bottom: 0;
`;
const Content = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.002em;
  text-align: left;
`;
const HighLight = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.002em;
  text-align: left;
  color: red;
`;
const ModalSavingBeforeLeaving = (props: ModalProps) => {
  return (
    <SimpleModal
      title={<Title>Save Before Leaving ?</Title>}
      okText="Stay"
      cancelText="Leave"
      closable={false}
      maskClosable={false}
      {...props}
    >
      <span>
        <Content>
          If you leave, any changes you have made{" "}
          <HighLight>will not be saved.</HighLight>
        </Content>
      </span>
    </SimpleModal>
  );
};

export default ModalSavingBeforeLeaving;
